import React, { useContext, useEffect, useState, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import RadioBox from '../../../../atoms/RadioBox/index'
import { GreenRadio } from '../../../../atoms/RadioBox'
import ProductContext from '../../../../../context/product/productContext'
import CustomCommonContext from '../../../../../../custom/context/common/commonContext'
import ProductCommonContext from '../../../../../context/common/commonContext'
import CheckBox from '../../../../../components/atoms/CheckBox/index'
import { mapData } from '../../../../../common/components'
import { capitalize } from '../../../../../common/components'
import DynamicContext from '../../../../../context/dynamic/dynamicContext'
import { useFormik } from 'formik'
import { Button } from '@material-ui/core'
import Slider from '@material-ui/core/Slider'
import { FlashOffOutlined, FlashOnOutlined, SettingsPhoneTwoTone } from '@material-ui/icons'
import { dateFormatMonthDate } from '../../../../../common/components'
import { useTranslation } from 'react-i18next'

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#fff',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 50,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails)

const FilterPanel = (props) => {
    const customCommonContext = useContext(CustomCommonContext)
    const productCommonContext = useContext(ProductCommonContext)
    const { t } = useTranslation()

    const productContext = useContext(ProductContext)
    const { active_sorts, getAllActiveSorts } = productContext
    const { allCountries, allStates, allCities } = productCommonContext
    const { allCategory, allCondition, allLocations, alldata_all_dropdown } = customCommonContext

    const [expanded, setExpanded] = React.useState(
        props.defaultPanel ? props.defaultPanel : 'panel0',
    )
    const [show, setShow] = React.useState(false)
    const [arrayValue, setArrayValue] = React.useState([])
    const [mapDataValue, setMapDataValue] = React.useState([])
    const [reload, setReload] = React.useState(false)
    const [valueLoaded, setValueLoaded] = useState(false)
    const [showBtn, setShowBtn] = useState(false)

    const sortValues = props.sorts
    let formik = props.formik

    const [priceRange, setPriceRange] = useState([0, 10000])
    const [kilometerRange, setKilometerRange] = useState([0, 500000])
    const [powerRange, setPowerRange] = useState([0, 1000])
    const [yearRange, setYearRange] = useState([1900, 2100])
    useEffect(() => {
        if (props.activeSortCall) {
            props.activeSortCall()
        } else {
            getAllActiveSorts({})
        }
    }, [])

    useEffect(() => {
        if (props.show) {
            formik = props.update
            setReload(true)
            setShow(true)
            console.log('lllll')
        }
    }, [props.show])

    // useEffect(() => {
    //     if (show) {
    //         let searchValue = formik.values.filters
    //         console.log(formik.values, 'gggggg')
    //         if (searchValue?.category?.value.length > 0) {
    //             formik.values.filters.category.value = searchValue.category.value.map((cat) => {
    //                 return cat.toString()
    //             })
    //         } else {
    //             formik.values.filters.category.value = []
    //         }
    //         if (searchValue?.condition?.value.length > 0) {
    //             formik.values.filters.condition.value = searchValue.condition.value.map((cat) => {
    //                 return cat.toString()
    //             })
    //         } else {
    //             formik.values.filters.condition.value = []
    //         }
    //         if (searchValue?.location?.value.length > 0) {
    //             formik.values.filters.location.value = searchValue.location.value.map((cat) => {
    //                 return cat.toString()
    //             })
    //         } else {
    //             formik.values.filters.location.value = []
    //         }
    //         if (searchValue?.min_price?.value > 0) {
    //             formik.values.filters.min_price.value = Number(searchValue.min_price.value)
    //         } else {
    //             formik.values.filters.min_price.value = ''
    //         }
    //         if (searchValue?.max_price?.value > 0) {
    //             formik.values.filters.max_price.value = Number(searchValue.max_price.value)
    //         } else {
    //             formik.values.filters.max_price.value = ''
    //         }
    //     }
    // }, [show])

    useEffect(() => {
        if (props.arrayValue) {
            setArrayValue(props.arrayValue)
        }
    }, [props.arrayValue])

    useEffect(() => {
        if (show) {
            let searchValue = formik.values.filters
            let dataValue = arrayValue
            console.log(formik.values, 'insid', arrayValue)
            dataValue.map((data, index) => {
                let dataName = data.name
                if (searchValue?.dataName?.value.length > 0) {
                    formik.values.filters[dataName].value = searchValue[dataName].value.map(
                        (cat) => {
                            return cat.toString()
                        },
                    )
                } else if (data.name === 'wprice' && !props.noPrice) {
                    formik.values.filters[data.name].value = 0
                } else {
                    if (formik.values.filters[dataName]) {
                        formik.values.filters[dataName].value = []
                    }
                }
                console.log(formik.values.filters, 'inside')
            })
            // if (searchValue?.min_price?.value > 0) {
            //     formik.values.filters.min_price.value = Number(searchValue.min_price.value)
            // } else {
            //     formik.values.filters.min_price.value = ''
            // }
            // if (searchValue?.max_price?.value > 0) {
            //     formik.values.filters.max_price.value = Number(searchValue.max_price.value)
            // } else {
            //     formik.values.filters.max_price.value = ''
            // }
        }
    }, [show])

    let sortedCategories = []
    let sortedConditions = []
    let sortedCountries = []
    if (sortValues) {
        if (sortValues.categorysort) {
            sortedCategories = allCategory.filter((inner) =>
                sortValues.categorysort.includes(inner.id.toString()),
            )
        } else {
            sortedCategories = allCategory
        }
        if (sortValues.conditionsort) {
            sortedConditions = allCondition.filter((inner) =>
                sortValues.conditionsort.includes(inner.id.toString()),
            )
        } else {
            sortedConditions = allCondition
        }

        if (sortValues.locationsort) {
            sortedCountries = allLocations.filter((inner) =>
                sortValues.locationsort.includes(inner.id.toString()),
            )
        } else {
            sortedCountries = allLocations
        }
    } else {
        sortedCategories = allCategory
        sortedConditions = allCondition
        sortedCountries = allLocations
    }

    const priceRangeHandler = () => {
        formik.setFieldValue('filters.price', {
            value: priceRange,
            type: 'range',
            field: 'p.wprice',
        })
    }

    const subStateOptions = (data) => {
        let valueDrop = allStates
            .filter(
                (state) =>
                    active_sorts?.sorts &&
                    active_sorts?.sorts?.sortdata_all_dropdown.sortstate &&
                    active_sorts.sorts.sortdata_all_dropdown.sortstate.includes(state.isoCode) &&
                    data.country == state.countryCode,
            )
            .map((buscat) => {
                let busCategoryChanged = {}
                busCategoryChanged.description = buscat.name
                busCategoryChanged.id = buscat.isoCode
                return busCategoryChanged
            })
        return valueDrop
    }

    const subCityOptions = (data) => {
        console.log(allCities, 'allCity')
        let valueDrop = allCities
            .filter((state) =>
                formik.values.filters.state.value.length &&
                active_sorts?.sorts &&
                active_sorts?.sorts?.sortdata_all_dropdown.sortcity &&
                active_sorts?.sorts?.sortdata_all_dropdown.sortcity.length
                    ? active_sorts.sorts.sortdata_all_dropdown.sortcity.includes(state.name) &&
                      state.countryCode == global.defaultCountry
                    : false,
            )
            .map((buscat) => {
                let busCategoryChanged = {}
                busCategoryChanged.description = buscat.name
                busCategoryChanged.id = buscat.name
                return busCategoryChanged
            })
        console.log(valueDrop, 'drop')
        return valueDrop
    }

    useEffect(() => {
        setValueLoaded(false)
        setMapDataValue([])
        if (arrayValue.length) {
            let sortProducts = props.sortProducts
            let dataValue = arrayValue
            let tempMapData = []
            dataValue
                .filter((data) => (data.active ? data.active === 1 : true))
                .map((data, index) => {
                    let mapValue = {}
                    if (data.type !== 'default') {
                        mapValue.name = data.name
                        mapValue.type = data.type
                        mapValue.title = data.label
                        mapValue.search_type = data.search_type
                        mapValue.filter_type = data.filter_type
                        mapValue.filter = true
                        mapValue.city = data.city ? data.city : 0
                        mapValue.state = data.state ? data.state : 0
                    } else {
                        mapValue = null
                    }
                    if (data.static === 1) {
                        mapValue.item = data.item
                        mapValue.having = data.having
                    }

                    if (data.search) {
                        if (
                            data.search_type === 'checkbox' &&
                            parseInt(data.sub_select, 10) === 1
                        ) {
                            mapValue.sub_select = data.sub_select
                            mapValue.sub_level = data.sub_level
                            mapValue.sub_field = data.sub_field
                            if (data.active_sorts) {
                                mapValue.item = allCategory.filter((subcat) => {
                                    if (
                                        subcat.level === 1 &&
                                        (active_sorts &&
                                        active_sorts.sorts &&
                                        active_sorts.sorts.sortsubCategoryTypes
                                            ? active_sorts.sorts.sortsubCategoryTypes.includes(
                                                  subcat.id.toString(),
                                              )
                                            : true) &&
                                        formik &&
                                        formik.values.filters &&
                                        formik.values.filters.category.value &&
                                        formik.values.filters.category.value.length
                                            ? formik.values.filters.category.value.includes(
                                                  subcat.level_id.toString(),
                                              )
                                            : false
                                    ) {
                                        return true
                                    }
                                })
                            } else {
                                if (data.item && data.saved_search) {
                                    mapValue.item = data.item
                                } else {
                                    mapValue.item = subLevelOptions(data)
                                }
                            }
                        }

                        if (data.search_type === 'checkbox' && parseInt(data.location, 10) === 1) {
                            let fieldName = `sort${data.name}`
                            console.log(active_sorts?.sorts?.sortlocations, 'avggygy')
                            if (active_sorts?.sorts?.sortlocations) {
                                mapValue.item = allLocations
                                    .filter(
                                        (value) =>
                                            value.active === 1 &&
                                            active_sorts?.sorts?.sortlocations &&
                                            active_sorts.sorts.sortlocations.includes(
                                                value.id.toString(),
                                            ),
                                    )
                                    .map((location) => {
                                        let locationChanged = {}
                                        locationChanged.description = data.showState
                                            ? `${location.name},${location.state}`
                                            : location.description
                                        locationChanged.id = location.id
                                        return locationChanged
                                    })
                            } else {
                                mapValue.item = allLocations
                                    .filter(
                                        (value) =>
                                            value.active === 1 &&
                                            active_sorts?.sorts?.sortlocations &&
                                            active_sorts?.sorts?.sortdata_all_dropdown[fieldName] &&
                                            active_sorts.sorts.sortdata_all_dropdown[
                                                fieldName
                                            ].includes(value.id.toString()),
                                    )
                                    .map((location) => {
                                        let locationChanged = {}
                                        locationChanged.description = data.showState
                                            ? `${location.name},${location.state}`
                                            : location.description
                                        locationChanged.id = location.id
                                        return locationChanged
                                    })
                            }
                        }

                        if (
                            data.search_type === 'checkbox' &&
                            data.type !== 'checkbox' &&
                            parseInt(data.location, 10) !== 1 &&
                            !data.static
                        ) {
                            console.log(data.name, 'ddd')
                            let fieldName = `sort${data.name}`
                            if (data.type === 'date') {
                                if (
                                    active_sorts?.sorts &&
                                    active_sorts?.sorts?.sortdata_all_dropdown
                                ) {
                                    if (active_sorts?.sorts?.sortdata_all_dropdown[fieldName]) {
                                        let sortData =
                                            active_sorts?.sorts?.sortdata_all_dropdown[fieldName]
                                        mapValue.item = sortData.map((item) => {
                                            let mapData = {}
                                            if (data.type === 'date') {
                                                let format = dateFormatMonthDate(item)
                                                mapData.id = item
                                                mapData.description = format
                                            }
                                            return mapData
                                        })
                                    }
                                }
                            } else if (props.search_sort) {
                                console.log(data, 'after')
                                if (data.item && data.item.length) {
                                    mapValue.item = data.item
                                } else {
                                    if (data.state) {
                                        console.log('statee', data)
                                        mapValue.item = subStateOptions(data)
                                    } else if (data.city) {
                                        mapValue.item = subCityOptions(data)
                                        // mapValue.item = []
                                    } else {
                                        mapValue.item = alldata_all_dropdown
                                            .filter(
                                                (value) =>
                                                    value.variable === data.name &&
                                                    value.level_id === 0 &&
                                                    props.search_sort?.sorts?.sortdata_all_dropdown[
                                                        fieldName
                                                    ] &&
                                                    props.search_sort.sorts.sortdata_all_dropdown[
                                                        fieldName
                                                    ].includes(value.id.toString()),
                                            )
                                            .map((item) => {
                                                let valueChanged = {}
                                                valueChanged.description = item.description
                                                valueChanged.id = item.id
                                                return valueChanged
                                            })
                                    }
                                }
                            } else {
                                if (data.state) {
                                    console.log('statee', data)
                                    mapValue.item = subStateOptions(data)
                                } else if (data.city) {
                                    mapValue.item = subCityOptions(data)
                                } else {
                                    mapValue.item = alldata_all_dropdown
                                        .filter(
                                            (value) =>
                                                value.variable === data.name &&
                                                value.level_id === 0 &&
                                                active_sorts?.sorts?.sortdata_all_dropdown[
                                                    fieldName
                                                ] &&
                                                active_sorts.sorts.sortdata_all_dropdown[
                                                    fieldName
                                                ].includes(value.id.toString()),
                                        )
                                        .map((item) => {
                                            let valueChanged = {}
                                            valueChanged.description =
                                                localStorage.i18nextLng === 'for_arabic' &&
                                                props.languageChange &&
                                                item[localStorage.i18nextLng]
                                                    ? item[localStorage.i18nextLng]
                                                    : item.description
                                            valueChanged.id = item.id
                                            return valueChanged
                                        })
                                }
                            }
                        }

                        if (data.search_type === 'checkbox' && data.type === 'checkbox') {
                            if (data.name === 'buynow' && props.customBuyingType) {
                                mapValue.title = 'Buying Type'
                                mapValue.item = [
                                    { id: 1, description: 'Auction' },
                                    { id: 2, description: 'Buynow' },
                                ]
                            } else {
                                mapValue.item = [{ id: 1, description: data.label }]
                            }
                        }

                        if (data.search_type === 'radio' && !data.static) {
                            mapValue.int = 1
                            let fieldName = `sort${data.name}`
                            mapValue.item = alldata_all_dropdown
                                .filter(
                                    (value) =>
                                        value.variable === data.name &&
                                        value.level_id === 0 &&
                                        active_sorts?.sorts?.sortdata_all_dropdown[fieldName] &&
                                        active_sorts.sorts.sortdata_all_dropdown[
                                            fieldName
                                        ].includes(value.id.toString()),
                                )
                                .map((item) => {
                                    let valueChanged = {}
                                    valueChanged.description = item.description
                                    valueChanged.id = item.id
                                    return valueChanged
                                })
                        }
                        if (data.search_type === 'radio' && parseInt(data.location, 10) === 1) {
                            mapValue.int = 1
                            mapValue.item = allLocations
                                .filter(
                                    (value) =>
                                        value.active === 1 &&
                                        active_sorts?.sorts?.sortlocations &&
                                        active_sorts.sorts.sortlocations.includes(
                                            value.id.toString(),
                                        ),
                                )
                                .map((location) => {
                                    let locationChanged = {}
                                    locationChanged.description = location.description
                                    locationChanged.id = location.id
                                    return locationChanged
                                })
                        }

                        if (data.search_type === 'radio' && parseInt(data.sub_select, 10) === 1) {
                            mapValue.sub_select = data.sub_select
                            mapValue.sub_level = data.sub_level
                            mapValue.sub_field = data.sub_field
                            if (data.active_sorts) {
                                mapValue.item = allCategory.filter((subcat) => {
                                    if (
                                        subcat.level === 1 &&
                                        (active_sorts &&
                                        active_sorts.sorts &&
                                        active_sorts.sorts.sortsubCategoryTypes
                                            ? active_sorts.sorts.sortsubCategoryTypes.includes(
                                                  subcat.id.toString(),
                                              )
                                            : true) &&
                                        formik &&
                                        formik.values.filters &&
                                        formik.values.filters.category.value &&
                                        formik.values.filters.category.value.length
                                            ? formik.values.filters.category.value.includes(
                                                  subcat.level_id.toString(),
                                              )
                                            : false
                                    ) {
                                        return true
                                    }
                                })
                            } else {
                                mapValue.item = subLevelOptions(data)
                            }
                        }
                    } else {
                        if (data.search_type === 'checkbox') {
                            console.log(data, sortProducts, 'sojoo')
                            if (sortProducts && sortProducts.sortdata_all_dropdown) {
                                let fieldName = `sort${data.name}`
                                if (
                                    sortProducts.sortdata_all_dropdown[fieldName] &&
                                    sortProducts.sortdata_all_dropdown[fieldName].length
                                ) {
                                    let sortData = sortProducts.sortdata_all_dropdown[fieldName]
                                    mapValue.item = sortData.map((item) => {
                                        let mapData = {}
                                        if (data.type === 'date') {
                                            let format = dateFormatMonthDate(item)
                                            mapData.id = item
                                            mapData.description = format
                                        }
                                        return mapData
                                    })
                                    console.log(mapValue.item, 'mapVloo')
                                }
                            }
                        }
                    }
                    if (mapValue) {
                        tempMapData.push(mapValue)
                    }
                })
            console.log(tempMapData, 'mapoo')
            setMapDataValue(tempMapData)
            setValueLoaded(true)
            setReload(!reload)
        }
    }, [arrayValue, active_sorts, props.sortProducts, localStorage?.i18nextLng])

    const mapDataValueRef = useRef(mapDataValue)
    useEffect(() => {
        mapDataValueRef.current = mapDataValue
    })

    useEffect(() => {
        if (mapDataValueRef.current.length) {
            console.log('coming')
            formik.values.page = 1
            let tempMapData = mapDataValueRef.current
            tempMapData.map((data) => {
                if (formik.values.filters[data.sub_field]) {
                    if (data.search_type === 'checkbox' && parseInt(data.sub_select, 10) === 1) {
                        if (parseInt(data.sub_level, 10) > 0) {
                            data.item = subLevelOptions(data)
                        }
                    }
                    if (data.search_type === 'radio' && parseInt(data.sub_select, 10) === 1) {
                        if (parseInt(data.sub_level, 10) > 0) {
                            data.item = subLevelOptions(data)
                        }
                    }
                } else if (data.search_type === 'checkbox' && data.city) {
                    console.log(data, 'ghhh')
                    data.item = subCityOptions(data)
                }
            })
        }
        console.log('outside')
        if (props.hideClearBtn) {
            console.log('comingg', formik.values.filters)
            let arrayfill = false
            let numberfill = false
            let stringfill = false
            let filterValues = formik.values.filters

            Object.keys(filterValues).map((buscat) => {
                console.log(filterValues[buscat].type, 'busss')
                if (
                    filterValues[buscat].type === 'array' ||
                    filterValues[buscat].type === 'arraylike'
                ) {
                    if (filterValues[buscat].value.length) {
                        arrayfill = true
                    }
                } else if (
                    filterValues[buscat].type === 'smallerequal' ||
                    filterValues[buscat].type === 'greaterequal' ||
                    filterValues[buscat].type === 'range'
                ) {
                    if (filterValues[buscat].value > 0) {
                        numberfill = true
                    }
                } else if (
                    filterValues[buscat].type === 'like' ||
                    filterValues[buscat].type === 'in'
                ) {
                    if (filterValues[buscat].value !== '') {
                        stringfill = true
                    }
                }
            })
            if (arrayfill || numberfill || stringfill) {
                console.log('showing button')
                setShowBtn(true)
            } else {
                console.log('chhhnn')
                setShowBtn(false)
            }
        }
    }, [formik.values.filters])

    useEffect(() => {
        if (props.getFilterValues) {
            props.getFilterValues(mapDataValue)
        }
    }, [mapDataValue])

    const subLevelOptions = (data) => {
        if (formik.values.filters[data.sub_field]) {
            let dropValue = alldata_all_dropdown
                .filter((subcat) => {
                    if (
                        subcat.level === 1 &&
                        formik &&
                        formik.values.filters &&
                        formik.values.filters[data.sub_field].value &&
                        formik.values.filters[data.sub_field].value.length
                            ? formik.values.filters[data.sub_field].value.includes(
                                  subcat.level_id.toString(),
                              )
                            : false
                    ) {
                        return true
                    }
                })
                .map((subcat) => {
                    let subCategoryChanged = {}
                    subCategoryChanged.description = subcat.description
                    subCategoryChanged.id = subcat.id
                    return subCategoryChanged
                })
            return dropValue
        }
    }

    // const filterValues = [
    //     {
    //         title: 'Category',
    //         type: 'check',
    //         name: 'category',
    //         item: allCategory
    //             .filter((main) => {
    //                 if (
    //                     (console.log(main, 'mainmain'),
    //                     main.level === 0 &&
    //                         (active_sorts &&
    //                         active_sorts.sorts &&
    //                         active_sorts.sorts.sortcategorytypes
    //                             ? active_sorts.sorts.sortcategorytypes.includes(main.id.toString())
    //                             : true))
    //                 ) {
    //                     return true
    //                 }
    //             })
    //             .sort((a, b) =>
    //                 a.description?.toUpperCase() < b.description?.toUpperCase() ? -1 : 1,
    //             ),
    //     },
    //     {
    //         title: 'Sub Category',
    //         type: 'check',
    //         name: 'subCategory',
    //         item: allCategory.filter((subcat) => {
    //             if (
    //                 subcat.level === 1 &&
    //                 (active_sorts && active_sorts.sorts && active_sorts.sorts.sortsubCategoryTypes
    //                     ? active_sorts.sorts.sortsubCategoryTypes.includes(subcat.id.toString())
    //                     : true) &&
    //                 formik &&
    //                 formik.values.filters &&
    //                 formik.values.filters.category.value &&
    //                 formik.values.filters.category.value.length
    //                     ? formik.values.filters.category.value.includes(subcat.level_id.toString())
    //                     : false
    //             ) {
    //                 return true
    //             }
    //         }),
    //     },
    //     {
    //         title: 'Location',
    //         type: 'check',
    //         name: 'location',
    //         item: allLocations.filter((main) => {
    //             if (
    //                 active_sorts && active_sorts.sorts && active_sorts.sorts.sortlocations
    //                     ? active_sorts.sorts.sortlocations.includes(main.id.toString())
    //                     : true
    //             ) {
    //                 return true
    //             }
    //         }),
    //     },
    //     // {
    //     //     title: 'Price',
    //     //     type: 'slider',
    //     //     name: 'price',
    //     //     item: priceRange,
    //     // },
    //     {
    //         title: 'Conditions',
    //         type: 'check',
    //         name: 'condition',
    //         item: allCondition,
    //     },
    //     {
    //         title: 'Auction',
    //         type: 'check',
    //         name: 'auctionid',
    //         item: [{ id: 1, description: 'Auction' }],
    //     },
    //     {
    //         title: 'Buynow',
    //         type: 'check',
    //         name: 'buynowid',
    //         item: [{ id: 1, description: 'Buynow' }],
    //     },
    // ]
    function sliderText(value) {
        return `$ ${value}`
    }
    const handleChange1 = (event, newValue) => {
        setPriceRange(newValue)
        props.handlePrice(newValue)
    }

    const handleChange2 = (event, newValue) => {
        setKilometerRange(newValue)
        props.handleKelometer(newValue)
    }
    const handleChange3 = (event, newValue) => {
        setPowerRange(newValue)
        props.handlePower(newValue)
    }
    const handleChangeYear = (event, newValue) => {
        setYearRange(newValue)
        props.handleYear(newValue)
    }

    // const handleChange1 = (event: Event, newValue: number | number[], activeThumb: number) => {
    //     if (!Array.isArray(newValue)) {
    //         return
    //     }

    //     if (activeThumb === 0) {
    //         setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]])
    //     } else {
    //         setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)])
    //     }
    // }

    const handleChange = (panel) => (event, newExpanded) => {
        console.log(panel, 'ppp--->')
        if (panel === expanded) {
            setExpanded(false)
        } else setExpanded(panel)
    }

    useEffect(() => {
        if (props.formik.values && props.slider) {
            let min_price = props.formik.values.filters?.min_price?.value
            let max_price = props.formik.values.filters?.max_price?.value
            setPriceRange([min_price, max_price])
        }
        if (props.formik.values && props.kilometerFilter) {
            let min_km = props.formik.values.filters?.min_km?.value
            let max_km = props.formik.values.filters?.max_km?.value
            setKilometerRange([min_km, max_km])
        }
        if (props.formik.values && props.powerFilter) {
            let min_power = props.formik.values.filters?.min_power?.value
            let max_power = props.formik.values.filters?.max_power?.value
            setPowerRange([min_power, max_power])
        }
        if (props.formik.values && props.yearFilter) {
            let min_year = props.formik.values.filters?.min_year?.value
            let max_year = props.formik.values.filters?.max_year?.value
            setYearRange([min_year, max_year])
        }
    }, [props.formik.values, props.slider])
    return (
        <>
            {valueLoaded ? (
                <div className="filterPanel">
                    <div className="d-flex justify-content-between align-items-center filterTitleCnt">
                        <h4 className="fpTitle">
                            {props.filterName ? props.filterName : 'Filters'}
                        </h4>
                        {props.clearSearch &&
                            (props.hideClearBtn ? (
                                showBtn ? (
                                    <Button
                                        id="filters_clear"
                                        onClick={() => props.clearSearch()}
                                        className="filterClear ml-auto"
                                    >
                                        {!props.closeFilter && (
                                            <span className="material-icons">cancel</span>
                                        )}
                                        {props.clearAll ? props.clearAll : 'Clear all'}
                                    </Button>
                                ) : null
                            ) : (
                                <Button
                                    id="filters_clear"
                                    onClick={() => props.clearSearch()}
                                    className="filterClear ml-auto"
                                >
                                    {!props.closeFilter && (
                                        <span className="material-icons">cancel</span>
                                    )}
                                    {props.clearAll ? props.clearAll : 'Clear all'}
                                </Button>
                            ))}
                        {props.closeFilter && (
                            <Button
                                id="filters_close"
                                onClick={() => props.closeFilter()}
                                className="filterClose"
                            >
                                <span className="material-icons">cancel</span>
                            </Button>
                        )}
                    </div>
                    <div className="filterAcc">
                        {mapDataValue.map((data, index) => (
                            <Accordion
                                square
                                key={index}
                                onChange={handleChange(`panel${index}`)}
                                expanded={
                                    expanded === `panel${index}` ||
                                    formik.values?.filters[data?.name]?.value?.length > 0
                                }
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1d-content"
                                    id={`panel${index}d-header`}
                                >
                                    <h6 className="accTitle" id={data.title}>
                                        {data.title}{' '}
                                        {props.filterCount &&
                                        formik.values?.filters[data?.name]?.value?.length ? (
                                            <span className="filterCount">
                                                {formik.values?.filters[data?.name]?.value?.length}
                                            </span>
                                        ) : null}
                                    </h6>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {data.item && data.item.length > 0 ? (
                                        <div className="filterCheck d-flex align-items-center flex-wrap">
                                            {data.search_type === 'checkbox' &&
                                            data.type !== 'checkbox' &&
                                            data.name !== 'retrieval' ? (
                                                data.item.map((d, i) => (
                                                    <>
                                                        <CheckBox
                                                            name={`filters.${data.name}.value`}
                                                            label={
                                                                data.name === 'location'
                                                                    ? t(`${d.name}`)
                                                                    : t(d.description)
                                                            }
                                                            checked={
                                                                formik.values.filters[
                                                                    data.name
                                                                ]?.value?.indexOf(
                                                                    d.id.toString(),
                                                                ) !== -1
                                                                    ? true
                                                                    : false
                                                            }
                                                            value={d.id.toString()}
                                                            onChange={formik.handleChange}
                                                            // name={`filters.${data.name}.value`}
                                                            // label={
                                                            //     data.name === 'location'
                                                            //         ? `${d.name}`
                                                            //         : capitalize(d.description)
                                                            // }
                                                            // value={d.id.toString()}
                                                            // onChange={formik.handleChange}
                                                            // checked={
                                                            //     formik.values.filters[
                                                            //         data.name
                                                            //     ].value.indexOf(d.id.toString()) !== -1
                                                            //         ? true
                                                            //         : false
                                                            // }
                                                        />
                                                    </>
                                                ))
                                            ) : data.search_type === 'checkbox' &&
                                              data.type !== 'checkbox' &&
                                              data.name === 'retrieval' ? (
                                                data.item.map((d, i) => (
                                                    <>
                                                        <CheckBox
                                                            name={`filters.${d.show}.value`}
                                                            label={
                                                                data.name === 'location'
                                                                    ? t(`${d.name}`)
                                                                    : t(capitalize(d.description))
                                                            }
                                                            checked={
                                                                formik.values.filters[
                                                                    d.show
                                                                ].value.indexOf(d.id.toString()) !==
                                                                -1
                                                                    ? true
                                                                    : false
                                                            }
                                                            value={d.id.toString()}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </>
                                                ))
                                            ) : data.search_type === 'checkbox' &&
                                              data.type === 'checkbox' ? (
                                                data.item.map((d, i) => (
                                                    <>
                                                        <CheckBox
                                                            name={`filters.${data.name}.value`}
                                                            label={
                                                                data.name === 'location'
                                                                    ? t(`${d.name}`)
                                                                    : t(capitalize(d.description))
                                                            }
                                                            checked={
                                                                formik.values.filters[
                                                                    data.name
                                                                ].value.indexOf(d.id.toString()) !==
                                                                -1
                                                                    ? true
                                                                    : false
                                                            }
                                                            value={d.id.toString()}
                                                            onChange={formik.handleChange}
                                                            // name={`filters.${data.name}.value`}
                                                            // label={
                                                            //     data.name === 'location'
                                                            //         ? `${d.name}`
                                                            //         : capitalize(d.description)
                                                            // }
                                                            // value={d.id.toString()}
                                                            // onChange={formik.handleChange}
                                                            // checked={
                                                            //     formik.values.filters[
                                                            //         data.name
                                                            //     ].value.indexOf(d.id.toString()) !== -1
                                                            //         ? true
                                                            //         : false
                                                            // }
                                                        />
                                                    </>
                                                ))
                                            ) : data.type === 'radio' && data.having !== 1 ? (
                                                <>
                                                    <RadioBox
                                                        items={data.item}
                                                        value={
                                                            formik.values.filters[data.name].value
                                                        }
                                                        onChange={formik.handleChange}
                                                        name={`filters.${data.name}.value`}
                                                        id={formik.values.filters[data.name].value}
                                                        int={1}
                                                    />
                                                </>
                                            ) : data.type === 'radio' && data.having === 1 ? (
                                                <>
                                                    <RadioBox
                                                        items={data.item}
                                                        value={
                                                            formik.values.having[data.name].value
                                                        }
                                                        id={formik.values.having[data.name].value}
                                                        onChange={formik.handleChange}
                                                        name={`having.${data.name}.value`}
                                                        int={1}
                                                    />
                                                </>
                                            ) : null}
                                        </div>
                                    ) : data.type === 'time' && props.timeFilter ? (
                                        <div className="row">{mapData(props.timeFilter)}</div>
                                    ) : data.type === 'number' && props.slider ? (
                                        <div className="priceInput">
                                            {props.priceLabel ? (
                                                <h5>
                                                    {props.sliderText(
                                                        formik.values.filters.min_price.value
                                                            ? formik.values.filters.min_price.value
                                                            : '0',
                                                    )}
                                                    <span> to </span>
                                                    {props.sliderText(
                                                        formik.values.filters.max_price.value
                                                            ? formik.values.filters.max_price.value
                                                            : '0',
                                                    )}
                                                </h5>
                                            ) : null}

                                            {props.priceFilter && data.filter_type == 'price' ? (
                                                <>
                                                    <Slider
                                                        getAriaLabel={() => 'Temperature range'}
                                                        value={priceRange}
                                                        onChange={handleChange1}
                                                        valueLabelDisplay="auto"
                                                        getAriaValueText={
                                                            props.sliderText
                                                                ? props.sliderText
                                                                : sliderText
                                                        }
                                                        max={
                                                            props.max_price
                                                                ? props.max_price
                                                                : 10000
                                                        }
                                                        id="slider_price"
                                                    />
                                                    <div className="row">
                                                        {mapData(props.priceFilter)}
                                                    </div>
                                                </>
                                            ) : null}
                                            {props.kilometerFilter &&
                                            data.filter_type == 'kilometer' ? (
                                                <>
                                                    <Slider
                                                        getAriaLabel={() => 'Temperature range'}
                                                        value={kilometerRange}
                                                        onChange={handleChange2}
                                                        valueLabelDisplay="auto"
                                                        getAriaValueText={sliderText}
                                                        max={500000}
                                                        id="slider_kilometer"
                                                    />
                                                    <div className="row">
                                                        {mapData(props.kilometerFilter)}
                                                    </div>
                                                </>
                                            ) : null}
                                            {props.powerFilter && data.filter_type == 'power' ? (
                                                <>
                                                    <Slider
                                                        getAriaLabel={() => 'Temperature range'}
                                                        value={powerRange}
                                                        onChange={handleChange3}
                                                        valueLabelDisplay="auto"
                                                        getAriaValueText={sliderText}
                                                        max={500}
                                                        id="slider_power"
                                                    />
                                                    <div className="row">
                                                        {mapData(props.powerFilter)}
                                                    </div>
                                                </>
                                            ) : null}
                                            {props.yearFilter && data.filter_type == 'year' ? (
                                                <>
                                                    <Slider
                                                        getAriaLabel={() => 'Temperature range'}
                                                        value={yearRange}
                                                        onChange={handleChangeYear}
                                                        valueLabelDisplay="auto"
                                                        getAriaValueText={sliderText}
                                                        max={2100}
                                                        id="slider_year"
                                                    />
                                                    <div className="row">
                                                        {mapData(props.yearFilter)}
                                                    </div>
                                                </>
                                            ) : null}
                                        </div>
                                    ) : (
                                        <small>{`${t('No')} ${data.title} ${t('Found')}`}</small>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                </div>
            ) : null}
        </>
    )
}
export default FilterPanel
