import React, { useReducer } from 'react'
import ProductContext from './productContext'
import ProductReducer from './productReducer'
import { apiCallSeller } from '../../../common/api'
import { response } from '../../common'

import {
    GET_ALL_PRODUCTS,
    GET_ALL_ACTIVE_AUCTIONS,
    GET_SINGLE_PROJECT,
    GET_ALL_BID_HISTORY,
    GET_ALL_TTWBID_HISTORY,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_SELLER_DASHBOARD_DATA,
    GET_ALL_PROJECT_ACCESS,
} from './productTypes'

const ProductState = (props) => {
    const initialState = {
        all_products: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            from: '',
        },
        all_active_auctions: {
            activelots: [],
        },
        single_project: {
            record: {},
        },
        search_allbidhistory: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        all_project_access_req: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            from: '',
        },
        search_allttwbidhistory: [],
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(ProductReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllProducts = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCallSeller('post', 'listings', formData, '', 'product'),
            ])
            const from = fromVariable ? fromVariable : 'Communicationtemplates'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_PRODUCTS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: from,
                    },
                })
                dispatch({
                    type: GET_ALL_ACTIVE_AUCTIONS,
                    payload: res.data.data.responseData.activelots.length
                        ? res.data.data.responseData.activelots
                        : [],
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const changeProductStatus = async (formData) => {
        try {
            const from = 'productchange'
            const [res] = await Promise.all([
                apiCallSeller('post', 'changeStatus', formData, '', 'product'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const projectAction = async (formData, fromVariable, noAlert) => {
        try {
            const from = 'projectAction'
            const [res] = await Promise.all([
                apiCallSeller('post', 'projectAction', formData, '', 'product'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const bulkProjectAction = async (formData) => {
        try {
            const from = 'bulkProjectAction'
            const [res] = await Promise.all([
                apiCallSeller('post', 'bulkProjectAction', formData, '', 'product'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllBidHistory = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCallSeller('post', 'bidhistory', formData, '', 'product'),
            ])
            const from = 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_BID_HISTORY,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSingleProject = async (formData, fromVariable) => {
        try {
            const from = 'getSingleProject'
            const [res] = await Promise.all([
                apiCallSeller('post', 'getSingleProject', formData, '', 'product'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_PROJECT,
                    payload: {
                        record: res.data.data.responseData.record
                            ? res.data.data.responseData.record
                            : {},
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllTtwBidHistory = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCallSeller('post', 'ttwbidhistory', formData, '', 'product'),
            ])
            const from = 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_TTWBID_HISTORY,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        sorts: res.data.data.responseData.sorts,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            console.log('err', err)
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSellerDashboardData = async (formData = {}) => {
        try {
            const [res] = await Promise.all([
                apiCallSeller('post', 'getSellerDashboardData', formData, '', 'product'),
            ])
            if (res.data.status === 'success') {
                console.log(`res.data `, res.data.data.responseData)
                dispatch({
                    type: GET_SELLER_DASHBOARD_DATA,
                    payload: {
                        data: res.data.data.responseData,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getProjectAccessRequest = async (formData, fromVariable) => {
        try {
            console.log('coming here')
            const [res] = await Promise.all([
                apiCallSeller('post', 'getProjectAccessRequest', formData, '', 'product'),
            ])
            const from = fromVariable ? fromVariable : 'getProjectAccessRequest'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_PROJECT_ACCESS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const projectAccessReqStatus = async (formData) => {
        try {
            const from = 'projectAccessReqStatus'
            const [res] = await Promise.all([
                apiCallSeller('post', 'projectAccessReqStatus', formData, '', 'product'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    const bidHistoryUpdate = async (formData) => {
        try {
            const from = 'bidHistoryUpdate'
            const [res] = await Promise.all([
                apiCallSeller('post', 'ttwbidhistoryUpdate', formData, '', 'product'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    return (
        <ProductContext.Provider
            value={{
                // buyer_allproducts: state.buyer_allproducts,
                all_products: state.all_products,
                responseStatus: state.responseStatus,
                activelots: state.activelots,
                all_project_access_req: state.all_project_access_req,
                single_project: state.single_project,
                search_allbidhistory: state.search_allbidhistory,
                search_allttwbidhistory: state.search_allttwbidhistory,
                sellerDashboardData: state.sellerDashboardData,
                getSellerDashboardData,
                clearResponse,
                getAllProducts,
                changeProductStatus,
                getProjectAccessRequest,
                projectAccessReqStatus,
                projectAction,
                getSingleProject,
                bulkProjectAction,
                getAllBidHistory,
                getAllTtwBidHistory,
                bidHistoryUpdate,
            }}
        >
            {props.children}
        </ProductContext.Provider>
    )
}

export default ProductState
