import React from 'react'
import './Header.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LanguageSwitch from '../LanguageSwitch'

const TopHeader = () => {
    const { t } = useTranslation()
    return (
        <div className="topHeader d-flex justify-content-end align-items-center">
            <ul className="customContainer d-flex justify-content-end align-items-center">
                <li>
                    <LanguageSwitch />
                </li>
                {/* <li>
                    <Link to="#">
                        <span className="material-icons">email</span>
                        {t('email')}
                    </Link>
                </li> */}
            </ul>
        </div>
    )
}
export default TopHeader
