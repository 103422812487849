import React, { useReducer } from 'react'
import PayContext from './payContext'
import PayReducer from './payReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'
import { DirectAPICAll } from './../../common/components'

import { GET_VERSAPAY_LINK, RESPONSE_STATUS, CLEAR_RESPONSE } from './payTypes'

const PayState = (props) => {
    const initialState = {
        responseStatus: null,
        get_versapay_link: null,
    }

    const [state, dispatch] = useReducer(PayReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const payCart = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'authorize', formData, '', 'payment')])
            resp.commonResponse(res.data, 'payment')
        } catch (err) {
            resp.commonErrorResponse('payment')
        }
    }

    const payCartRosoom = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'rosoom_payment', formData, '', 'payment'),
            ])
            resp.commonResponse(res.data, 'payment')
        } catch (err) {
            resp.commonErrorResponse('payment')
        }
    }

    const responseCartRosoom = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'rosoom_response', formData, '', 'payment'),
            ])
            resp.commonResponse(res.data, 'rosoomResponse')
        } catch (err) {
            resp.commonErrorResponse('rosoomResponse')
        }
    }
    const payResponsePaypal = async (formData) => {
        try {
            console.log('payResponsePaypal', formData)
            const [res] = await Promise.all([
                apiCall('post', 'paypal_response', formData, '', 'payment'),
            ])
            resp.commonResponse(res.data, 'payment')
        } catch (err) {
            resp.commonErrorResponse('payment')
        }
    }

    const payCartStripeElement = async (formData, custom) => {
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'stripe_payment_element',
                    formData,
                    '',
                    'payment',
                    custom ? true : null,
                ),
            ])
            resp.commonResponse(res.data, 'paymentElement')
        } catch (err) {
            resp.commonErrorResponse('paymentElement')
        }
    }

    const payCartStripe = async (formData, custom) => {
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'stripe_payment',
                    formData,
                    '',
                    custom ? 'custom/payment' : 'payment',
                ),
            ])
            resp.commonResponse(res.data, 'payment')
        } catch (err) {
            resp.commonErrorResponse('payment')
        }
    }
    const payCartStripeResponse = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'stripe_payment_response', formData, '', 'payment'),
            ])
            resp.commonResponse(res.data, 'payment_response')
        } catch (err) {
            resp.commonErrorResponse('payment_response')
        }
    }

    const payCustomCartStripe = async (formData) => {
        const from = 'CUSTOM_PAYMENT'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'stripe_payment', formData, '', 'paymentThird/stripe'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const payCartVersaPay = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'versapay_validatepayment', formData, '', 'payment'),
            ])
            console.log('es.data.data.responseData', res.data.data.responseData)
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_VERSAPAY_LINK,
                    payload: res.data.data.responseData.link
                        ? res.data.data.responseData.link
                        : null,
                })
            } else {
                resp.commonResponse(res, 'payment')
            }
        } catch (err) {
            resp.commonErrorResponse('payment')
        }
    }

    const confirmPaymentVersaPay = async (link, formData) => {
        const from = 'payment'
        console.log('formData', link, formData)

        try {
            const [res] = await Promise.all([DirectAPICAll('post', link, formData, 'formdata')])
            console.log('res.data', res.data)
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const payCartCyberSource = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'cybersource_payment', formData, '', 'payment'),
            ])
            resp.commonResponse(res.data, 'payment')
        } catch (err) {
            resp.commonErrorResponse('payment')
        }
    }

    const payCartNorthCapital = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'northcapital_payment', formData, '', 'payment'),
            ])
            resp.commonResponse(res.data, 'payment')
        } catch (err) {
            resp.commonErrorResponse('payment')
        }
    }

    const payTMMembershipPayment = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'membership-payment', formData, '', 'paymentThird/paytm'),
            ])
            resp.commonResponse(res.data, 'payment_paytm_membership')
        } catch (err) {
            resp.commonErrorResponse('payment_paytm_membership')
        }
    }

    const responseCartPayTm = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'paytm_response', formData, '', 'paymentThird/paytm'),
            ])
            resp.commonResponse(res.data, 'paytmResponse')
        } catch (err) {
            resp.commonErrorResponse('paytmResponse')
        }
    }

    const cashFreeMembershipPayment = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'membership-payment', formData, '', 'paymentThird/cashfree'),
            ])
            resp.commonResponse(res.data, 'payment_cashfree_membership')
        } catch (err) {
            resp.commonErrorResponse('payment_cashfree_membership')
        }
    }

    const cashFreeResponseCart = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'cashfree_response', formData, '', 'paymentThird/cashfree'),
            ])
            resp.commonResponse(res.data, 'cashfreeResponse')
        } catch (err) {
            resp.commonErrorResponse('cashfreeResponse')
        }
    }

    const payCartPaytm = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'paytm_payment', formData, '', 'payment'),
            ])
            resp.commonResponse(res.data, 'paytm_payment')
        } catch (err) {
            resp.commonErrorResponse('paytm_payment')
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <PayContext.Provider
            value={{
                responseStatus: state.responseStatus,
                get_versapay_link: state.get_versapay_link,
                payCart,
                payCartRosoom,
                payCartStripe,
                payResponsePaypal,
                payCartNorthCapital,
                payCustomCartStripe,
                payTMMembershipPayment,
                cashFreeResponseCart,
                cashFreeMembershipPayment,
                responseCartRosoom,
                responseCartPayTm,
                clearResponse,
                payCartPaytm,
                payCartCyberSource,
                payCartVersaPay,
                confirmPaymentVersaPay,
                payCartStripeResponse,
                payCartStripeElement,
            }}
        >
            {props.children}
        </PayContext.Provider>
    )
}

export default PayState
