import React, { useState, useContext, useEffect, useRef } from 'react'
import './Dashboard.css'
import DashboardLayout from './DashboardLayout'
import CheckBox from '../../product/components/atoms/CheckBox'
import { Button } from '@material-ui/core'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import CustomDialog from '../../custom/components/organisms/Dialog'
import ProductContext from '../../product/context/product/productContext'
import { handleRedirectInternal, dateFormatFront } from '../../product/common/components'
import CommonContext from '../../custom/context/common/commonContext'
import { useHistory } from 'react-router-dom'
import { Pagination } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
function SavedSearch() {
    const productContext = useContext(ProductContext)
    const commonContext = useContext(CommonContext)
    const history = useHistory()
    const [toggleDialog, setToggleDialog] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const [limit, setLimit] = useState(15)
    const [record, setRecord] = useState([])

    const { responseStatus, getSavedSearch, removeSavedSearch, saved_searches } = productContext
    const { setSearchValue } = commonContext
    const { t } = useTranslation()
    const loadTable = (page = currentPage) => {
        getSavedSearch({ page: page, limit: limit }, true)
    }

    const removeRecord = (id) => {
        removeSavedSearch({ id })
    }

    useEffect(() => {
        loadTable()
    }, [])

    useEffect(() => {
        if (responseStatus) {
            if (
                responseStatus.from === 'saved_search_msg' &&
                responseStatus.data.status === 'removed'
            ) {
                loadTable()
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (saved_searches.records.length > 0) {
            setRecord(saved_searches.records)
            setTotalPage(saved_searches.totalRecords)
        } else {
            setRecord([])
            setTotalPage(0)
        }
    }, [saved_searches])

    // SEARCH
    const getSearchBarObject = (value) => {
        setSearchValue({
            filters: {
                searchbar: {
                    value: value,
                },
            },
        })
        handleRedirectInternal(history, 'search')
    }

    const onHandlePage = async (event, value) => {
        setCurrentPage(value)
        loadTable(value)
    }

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }
    return (
        <DashboardLayout title="My Saved Searches">
            <div className="dashboardInner mySavedSearches">
                <div className="table-responsive dpTable">
                    <h2 className="dashTitle">{t('SAVED SEARCHES')}</h2>
                    {record.length ? (
                        <>
                            <table className="table text-left mb-3">
                                <thead>
                                    <tr>
                                        <th scope="col">{t('title')}</th>
                                        {/* <th scope="col">{t('City')}</th>
                                        <th scope="col">{t('State')}</th> */}
                                        <th scope="col">{t('date_added')}</th>
                                        <th scope="col">{t('Action')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {record.map((data, index) => (
                                        <tr key={`saved_${index}`}>
                                            <td>{data.search_text}</td>
                                            <td>{dateFormatFront(data.created_at)}</td>
                                            <td>
                                                <div className="d-flex justify-content-center ssActBtn">
                                                    <Button
                                                        onClick={() =>
                                                            getSearchBarObject(data.search_text)
                                                        }
                                                    >
                                                        <span className="material-icons">
                                                            open_in_new
                                                        </span>
                                                    </Button>
                                                    <Button onClick={() => removeRecord(data.id)}>
                                                        <span className="material-icons">
                                                            delete
                                                        </span>
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Pagination
                                count={Math.ceil(totalPage / limit)}
                                page={currentPage}
                                onChange={onHandlePage}
                                siblingCount={3}
                                showFirstButton
                                showLastButton
                                boundaryCount={2}
                            />
                        </>
                    ) : (
                        <>
                            <img
                                src="./assets/svg/noResults.svg"
                                alt="No results image"
                                style={{ width: '180px' }}
                            />
                            <div className="mt-4">
                                <h6>{t('No Saved Seach Found!')}</h6>
                                <div className="w-25 mx-auto mt-4">
                                    <PrimaryButton
                                        label={t('Browse Products')}
                                        onClick={() => handleRedirectInternal(history, 'search')}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <CustomDialog
                title={t('confirm_delete')}
                open={toggleDialog}
                function={changeDialogStatus}
            >
                <h5>{t('you_are_about_to_delete_this_saved_search')}!</h5>
                <h5>{t('please_confirm_if_you_want_to_continue_with_this_action')}.</h5>
                <div className="actionWrapper">
                    <Button onClick={() => setToggleDialog(false)}>{t('no_cancel')}</Button>
                    <PrimaryButton type="submit" label={t('yes_delete')} />
                </div>
            </CustomDialog>
        </DashboardLayout>
    )
}

export default SavedSearch
