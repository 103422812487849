import React, { useContext, useState, useEffect } from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import { ListItem } from '@material-ui/core'
import AutopltContext from '../../../../product/context/autopilot/autopltContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { mapData } from '../../../common/components'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import UserContext from '@/product/context/user/userContext'
import authContext from '@/product/context/auth/authContext'
import { useTranslation } from 'react-i18next'
const Footer = () => {
    const autopltContext = useContext(AutopltContext)
    const { isAuthenticated } = useContext(authContext)
    const userContext = useContext(UserContext)
    const { t } = useTranslation()
    const { addCustomer, responseStatus: responseStatusAutoPlt } = autopltContext
    const { submitContactUs, responseStatus } = userContext

    const validationArray = Yup.object({
        email: Yup.string().email(t('invalid_email_format')).required(t('required')),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            // addCustomer({ Email: values.email })
            submitContactUs(values)
        },
    })

    const subscribe = [
        {
            label: t('email'),
            placeholder: t('enter_your_email_address'),
            class: 'subsInp',
            type: 'text',
            name: 'email',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'submitContactUs') {
                if (responseStatus.status === 'success') {
                    formik.values.email = ''
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (responseStatusAutoPlt) {
            if (responseStatusAutoPlt.from === 'addCustomer') {
                if (responseStatusAutoPlt.status === 'success') {
                    formik.values.email = ''
                }
            }
        }
    }, [responseStatusAutoPlt])

    return (
        <footer className="noPrint">
            <div className="footerCnt customContainer d-flex justify-content-between">
                <ul className="footLinks">
                    <li className="flHead">{t('Customer Service')}</li>
                    <li>
                        <Link to="/contact">
                            <ListItem button>{t('contact_us')}</ListItem>
                        </Link>
                    </li>
                    <li>
                        <Link to="/faq">
                            <ListItem button>{t('faqs')}</ListItem>
                        </Link>
                    </li>
                </ul>

                <ul className="footLinks">
                    <li className="flHead">{t('GENERAL')}</li>

                    <li>
                        <Link to="/privacy">
                            <ListItem button>{t('Privacy & Accessibility')}</ListItem>
                        </Link>
                    </li>
                    <li>
                        <Link to="/auction">
                            <ListItem button>{t('auctions')}</ListItem>
                        </Link>
                    </li>
                    {isAuthenticated ? (
                        <li>
                            <Link to="/postreview">
                                <ListItem button>{t('Leave Review')}</ListItem>
                            </Link>
                        </li>
                    ) : null}
                </ul>

                {/* <ul className="footLinks">
                    <li className="flHead">{t('CONNECT')}</li>
                    <li>
                        <a
                            href="https://www.facebook.com/naamauction?mibextid=ZbWKwL"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <ListItem button>{t('Facebook')}</ListItem>
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://twitter.com/NaamAuction"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <ListItem button>{t('x_twitter')}</ListItem>
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.instagram.com/naamauction2024?igsh=MTdreGVuOXI2cWNpMA=="
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <ListItem button>{t('Instagram')}</ListItem>
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.linkedin.com/company/naamauction/"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <ListItem button>{t('Linkedin')}</ListItem>
                        </a>
                    </li>
                </ul> */}

                <ul className="footLinks">
                    <li className="flHead">{t('CONTACT')}</li>
                    <li>
                        <a href="mailto:info@naam.com.sa">
                            <ListItem button>
                                info@naam
                                <wbr />
                                .com.sa
                            </ListItem>
                        </a>
                    </li>
                    <li>
                        <a href="tel:+966112888834" className="ru-con">
                            <ListItem button>
                                <span dir="ltr">+966112888834</span>
                            </ListItem>
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.google.com/maps/place/Brilliant+company/@24.6865343,46.6895281,17z/data=!3m1!4b1!4m6!3m5!1s0x3e2ee3db06782909:0xf8aee785ff7658c!8m2!3d24.6865343!4d46.6895281!16s%2Fg%2F11smcsjl95?entry=ttu&g_ep=EgoyMDI0MTAyMS4xIKXMDSoASAFQAw%3D%3D"
                            target="_blank"
                            rel="noreferrer"
                            className="ru-con"
                        >
                            <ListItem button>
                                {t('address_of_jafza_zone')} <br />
                                {t('address_of_jafza_zone_line_2')}
                            </ListItem>
                        </a>
                    </li>
                </ul>
                <ul className="footLinks">
                    <li className="flHead">{t('Join our weekly newsletter')}</li>
                    {/* <li className="noLink">{t('Join our weekly mailing list')}</li> */}
                    <li>
                        <div className="footSubscribe">
                            <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                                <div className="input-group mb-3 flex-nowrap">
                                    {Object.values(mapData(subscribe))}
                                    <PrimaryButton
                                        // label={t('SUBSCRIBE')}
                                        label={t('Join')}
                                        type="submit"
                                        id="subsButton"
                                    />
                                </div>
                            </form>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="footBottom customContainer d-flex justify-content-between align-items-center">
                {/* <ul>
                    <li>
                        <Link to="/terms">{t('Terms & Conditions')}</Link>
                    </li>
                    <li>
                        <Link to="/legal">{t('Legal')}</Link>
                    </li>
                </ul> */}
                <ul>
                    <li>
                        <p>
                            {`${t('copyright')} ${new Date().getFullYear()} - NAAM  ©  ${t(
                                'All rights reserved',
                            )}`}
                        </p>
                    </li>
                </ul>
                <ul className="footLinks">
                    <li>
                        <a
                            href="https://www.facebook.com/naamauction?mibextid=ZbWKwL"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {/* <ListItem button>{t('Facebook')}</ListItem> */}
                            <img
                                src="/assets/svg/facebook_footer.svg"
                                target="_blank"
                                placeholder="facebook"
                            />
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://twitter.com/NaamAuction"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {/* <ListItem button>{t('x_twitter')}</ListItem> */}
                            <img
                                src="/assets/svg/twitter_footer.svg"
                                target="_blank"
                                placeholder="twitter"
                            />
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.instagram.com/naamauction2024?igsh=MTdreGVuOXI2cWNpMA=="
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {/* <ListItem button>{t('Instagram')}</ListItem> */}
                            <img
                                src="/assets/svg/instagram_footer.svg"
                                target="_blank"
                                placeholder="instagram"
                            />
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.linkedin.com/company/naamauction/"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {/* <ListItem button>{t('Linkedin')}</ListItem> */}
                            <img
                                src="/assets/svg/linkedin_footer.svg"
                                target="_blank"
                                placeholder="linkedin"
                            />
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    )
}
export default Footer
