import React, { useContext, useState, useEffect } from 'react'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import '../Login/Login.css'
import { mapData, handleRedirectInternal } from '../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import CommonContext from '../../custom/context/common/commonContext'
import UserContext from '../../product/context/user/userContext'
import PhoneValidation from '../Registration/phoneValidation'

const UserMigration = (props) => {
    const userContext = useContext(UserContext)
    const { responseStatus, clearResponse, decryptUserDetails, details, updateMigratedUser } =
        userContext
    const [phoneVerify, setPhoneVerify] = React.useState(false)
    const { USStates } = useContext(CommonContext)
    const history = useHistory()

    const loaded = React.useRef(false)

    // Loading Google Place API Script
    function loadScript(src, position, id) {
        if (!position) {
            return
        }

        const script = document.createElement('script')
        script.setAttribute('async', '')
        script.setAttribute('id', id)
        script.src = src
        position.appendChild(script)
    }

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            )
        }

        loaded.current = true
    }

    const changePhoneVerify = () => {
        setPhoneVerify(!phoneVerify)
    }

    const validationArray = Yup.object({
        token: Yup.string().required('Required!'),
        password: Yup.string().min(8, 'Minimum 8 characters').required('Required!'),
        password_checker: Yup.number().moreThan(1, 'Password has to be Fair'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password')], "Password's not match")
            .required('Required!'),
        address1: Yup.string().required('Required!'),
        phone: Yup.string()
            .test('isValid', 'Invalid Phone Number', (value) => {
                return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(value)
            })
            .required('Required!'),
        city: Yup.string().required('Required!'),
        state: Yup.string().required('Required!'),
        zip: Yup.string()
            .min(4, 'Invalid Zip Code')
            .max(8, 'Invalid Zip Code')
            .test('isValidZip', 'Invalid Zip Code', (value) => {
                return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)
            })
            .required('Required!'),
    })

    const formik = useFormik({
        initialValues: {
            token: props.match.params.token,
            password: '',
            password_checker: 0,
            confirm_password: '',
            address1: '',
            phone: '',
            city: '',
            state: '',
            zip: '',
            phone_verified: 0,
            verified_phonenum: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            console.log('on submit: ', values)
            if (
                values.phone_verified === 1 &&
                formik.values.verified_phonenum === formik.values.phone
            ) {
                values.phone = values.phone.replace(/[^+0-9]/g, '')
                console.log('submitting new user request')
                updateMigratedUser(values)
            } else {
                setPhoneVerify(true)
            }
        },
    })

    const inputFields = [
        {
            label: 'New Password',
            name: 'password',
            type: 'password',
            placeholder: 'Enter your new password',
            class: 'col-md-12',
            formik: formik,
        },
        {
            name: 'password_checker',
            type: 'password_checker',
            formik: formik,
            class: 'col-md-12',
            hideMessage: true,
        },
        {
            label: 'Confirm password',
            name: 'confirm_password',
            type: 'password',
            placeholder: 'Re-enter your password',
            class: 'col-md-12',
            formik: formik,
        },
        {
            label: 'Phone number',
            placeholder: 'Enter your phone number',
            class: 'col-12',
            type: 'text',
            name: 'phone',
            formik: formik,
            countryCodeEditable: false,
        },
        {
            label: 'Address',
            placeholder: 'Enter you address',
            class: 'col-12',
            type: 'google_place_autocomplete',
            googlePlaceType: 'address',
            name: 'address1',
            formik: formik,
        },
        {
            label: 'City',
            placeholder: 'Enter your city',
            class: 'col-12',
            type: 'google_place_autocomplete',
            name: 'city',
            googlePlaceType: '(cities)',
            formik: formik,
        },
        {
            label: 'State',
            placeholder: 'Enter your state',
            class: 'col-12',
            type: 'select',
            options: USStates,
            name: 'state',
            formik: formik,
        },
        {
            label: 'ZIP',
            placeholder: 'Enter your ZIP code',
            class: 'col-6',
            type: 'google_place_autocomplete',
            googlePlaceType: '(regions)',
            name: 'zip',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (props.match.params.token) {
            decryptUserDetails(props.match.params.token)
        }
    }, [])
    useEffect(() => {
        if (Object.keys(details).length > 0) {
            formik.setFieldValue('phone', details.phone)
            formik.setFieldValue('address1', details.address1)
            formik.setFieldValue('state', details.state)
            formik.setFieldValue('city', details.city)
            formik.setFieldValue('zip', details.zip)
        }
    }, [details])
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'updateMigratedUser') {
                if (responseStatus.status === 'success') {
                    handleRedirectInternal(history, 'login')
                    clearResponse()
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (formik.errors['password_checker']) {
            if (formik.values.password && formik.values.password.length < 8) {
                formik.setFieldError('password', formik.errors['password'])
            } else {
                formik.setFieldError('password', formik.errors['password_checker'])
            }
        }
    }, [formik.errors['password'], formik.errors['password_checker']])

    return (
        <div className="login">
            <div className="container">
                <div className="d-flex justify-content-center align-items-start">
                    <div className="loginLt">
                        <h4>Welcome to Naam</h4>
                        <p>Please fill in below to get started!</p>
                        <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                            <div className="row">{Object.values(mapData(inputFields))}</div>
                            <PrimaryButton type="submit" label="Get Started" />
                        </form>
                        <PhoneValidation
                            phoneVerify={phoneVerify}
                            formik={formik}
                            setPhoneVerify={setPhoneVerify}
                            changePhoneVerify={changePhoneVerify}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UserMigration
