import React, { useState, useContext, useEffect, useRef } from 'react'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import CartItem from '@/custom/components/molecules/ProductCard/CartItem'
import { useHistory } from 'react-router-dom'
import CartContext from '@/product/context/cart/cartContext'
import AuthContext from '@/product/context/auth/authContext'
import AlertContext from '@/product/context/alert/alertContext'
import { handleRedirectInternal } from '@/product/common/components'
import './Cart.css'
import EmptyCart from '../EmptyCart'
import { currencyFormat } from '@/custom/common/components'
import Loaders from '@/custom/components/molecules/Loaders'
import { useTranslation } from 'react-i18next'
const Cart = () => {
    const cartContext = useContext(CartContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const history = useHistory()

    const [isLoading, setIsLoading] = useState(true)
    const { search_allproducts, changeOnCart, getAllCartProducts, responseStatus, clearResponse } =
        cartContext
    const { isAuthenticated } = authContext
    const { t } = useTranslation()
    const { setAlert } = alertContext

    let [cartProducts, setCartProducts] = useState([])
    let [nonCartProducts, setNonCartProducts] = useState([])
    let [cartValues, setCartValues] = useState({})

    const [search, setSearch] = useState({
        filters: {
            active: {
                value: '2',
                type: 'notin',
                field: 'b.active',
            },
        },
    })
    //Authentication
    useEffect(() => {
        if (isAuthenticated) {
            setIsLoading(true)
            getAllCartProducts(search)
        }
    }, [isAuthenticated])
    //search product in cart
    useEffect(() => {
        setCartProducts(search_allproducts.cartrecords.length ? search_allproducts.cartrecords : [])
        setNonCartProducts(
            search_allproducts.noncartrecords.length ? search_allproducts.noncartrecords : [],
        )
        setTimeout(() => {
            setIsLoading(false)
        }, 1500)
    }, [search_allproducts])
    //get product list in cart
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'cartchange' || responseStatus.from === 'addToCart') {
                if (responseStatus.status === 'success') {
                    getAllCartProducts(search)
                }
            }
        }
    }, [responseStatus])

    console.log(cartProducts, 'cartProducts')

    return (
        <>
            <div className="cart">
                {isLoading ? (
                    <div className="container">
                        <Loaders name="cart" isLoading={isLoading} loop={1} />
                    </div>
                ) : (
                    <>
                        {nonCartProducts.length || cartProducts.length ? (
                            <>
                                {cartProducts.length ? (
                                    <>
                                        {cartProducts.map((data, index) => (
                                            <>
                                                {data.cartItems.length ? (
                                                    <>
                                                        <div className="customContainer">
                                                            <div className="cartCnt d-flex justify-content-between">
                                                                <div className="cartLt">
                                                                    <div className="cartProducts">
                                                                        <div className="cartHead d-flex justify-content-start align-items-center">
                                                                            <h1>
                                                                                {t('shopping_cart')}
                                                                            </h1>
                                                                            <span>
                                                                                <span>
                                                                                    {
                                                                                        data
                                                                                            .cartItems
                                                                                            .length
                                                                                    }
                                                                                    &nbsp;
                                                                                    {t(
                                                                                        'results_found',
                                                                                    )}
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            {data.cartItems.map(
                                                                                (data, index) => (
                                                                                    <CartItem
                                                                                        key={index}
                                                                                        data={data}
                                                                                        from="cart"
                                                                                        changeOnCart={
                                                                                            changeOnCart
                                                                                        }
                                                                                    />
                                                                                ),
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="cartRt">
                                                                    <div className="cartSummary">
                                                                        <div>
                                                                            <h3>
                                                                                {t(
                                                                                    'shopping_cart_summary',
                                                                                )}
                                                                            </h3>
                                                                            <div className="cartSummaryValue">
                                                                                <label>
                                                                                    {t(
                                                                                        'total_payable',
                                                                                    )}
                                                                                    (
                                                                                    {
                                                                                        data
                                                                                            .cartValues
                                                                                            .total_items
                                                                                    }{' '}
                                                                                    {t('items')}):
                                                                                </label>
                                                                                <h5>
                                                                                    {currencyFormat(
                                                                                        data
                                                                                            .cartValues
                                                                                            .total_amount,
                                                                                        t,
                                                                                    )}
                                                                                </h5>
                                                                            </div>
                                                                        </div>
                                                                        <PrimaryButton
                                                                            id="cart_auction_products"
                                                                            label={t(
                                                                                'proceed_to_checkout',
                                                                            )}
                                                                            onClick={() =>
                                                                                handleRedirectInternal(
                                                                                    history,
                                                                                    `checkout/auction/${data.cartValues.cart_group}`,
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <></>
                                                    // <div className="cartRt">
                                                    //     <div className="cartSummary">
                                                    //         <h3>Shopping Cart Summary</h3>
                                                    //         <label>Subtotal (0 Items)</label>
                                                    //         <h5>{currencyFormat(0)}</h5>
                                                    //     </div>
                                                    // </div>
                                                )}
                                            </>
                                        ))}
                                    </>
                                ) : null}
                                <div className="cart customContainer">
                                    <div className="d-flex justify-content-between">
                                        <div className="cartLt">
                                            {nonCartProducts.length ? (
                                                <div className="cartProducts">
                                                    <div className="cartHead d-flex justify-content-start align-items-center">
                                                        <h1>{t('your_items')}</h1>
                                                        <span>
                                                            {nonCartProducts.length ? (
                                                                <span>
                                                                    {nonCartProducts.length}{' '}
                                                                    {t('results_found')}
                                                                </span>
                                                            ) : null}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        {nonCartProducts.map((data, index) => (
                                                            <CartItem
                                                                data={data}
                                                                key={index}
                                                                from="cart"
                                                                changeOnCart={changeOnCart}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="cart customContainer">
                                <div className="d-flex justify-content-between">
                                    <EmptyCart />
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    )
}

export default Cart
