import React, { useContext, useState, useEffect } from 'react'
import ProductCommonContext from '@/product/context/common/commonContext'

export default function CurrencyContextFormat(props) {
    const productCommonContext = useContext(ProductCommonContext)

    const { currencyValue } = productCommonContext

    const [currency, setCurrency] = useState(1)

    useEffect(() => {
        let amount = 0
        if (props.amount) {
            amount = `${new Intl.NumberFormat('ar-SA', {
                minimumFractionDigits: 2,
                style: 'currency',
                currency: 'SAR',
            }).format(props.amount)}`

            if (currencyValue) {
                let currency = currencyValue
                if (currency.country_exactcode) {
                    amount = props.amount * currency.current_value
                    amount = `${new Intl.NumberFormat(currency.currency_code, {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: currency.country_exactcode,
                    }).format(amount)}`
                }
            }
        } else {
            amount = `${new Intl.NumberFormat('ar-SA', {
                minimumFractionDigits: 2,
                style: 'currency',
                currency: 'SAR',
            }).format(0)}`
        }

        setCurrency(amount)
    }, [currencyValue, props.amount])

    return <>{currency}</>
}
