import React, { useContext, useEffect, useState } from 'react'
import { mapData, converDate, getStateCode, getCountryCode } from '@/product/common/components'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import { Button } from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomDialog from '../../custom/components/organisms/Dialog/index'

import HyperPayCardContext from '@/product/context/payment/hyperpay/card/cardContext'
import AuthContext from '@/product/context/auth/authContext'
import ProductCommonContext from '@/product/context/common/commonContext'
import AlertContext from '@/product/context/alert/alertContext'
import Popup from '../../custom/components/organisms/Popup'
import Loaders from '../../custom/components/molecules/Loaders'
import { useTranslation } from 'react-i18next'
function CyberSourceCardDetails(props) {
    const authContext = useContext(AuthContext)
    const productCommonContext = useContext(ProductCommonContext)
    const alertContext = useContext(AlertContext)
    const [loading, setLoading] = useState(false)
    const { setAlert } = alertContext
    const { user, loadUser } = authContext

    const [disabled, setDisabled] = useState(false)
    const [changeStatus, setChangeStatus] = useState(false)
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }
    const { t } = useTranslation()

    const [reload, setReload] = useState(false)

    // useEffect(() => {
    //     if (props.scriptSrc) {
    //         console.log(props.scriptSrc, 'fghyy')
    //         // Include the JavaScript code dynamically

    //         const scriptElement = document.createElement('script')
    //         if (props?.pay_type === 'card') {
    //             scriptElement.innerHTML = ` var wpwlOptions = { style: "card",locale: "ar", registrations: { requireCvv: true } }; `
    //             scriptElement.src = `${
    //                 process.env.REACT_APP_OPPWA_URL || 'https://eu-test.oppwa.com'
    //             }/v1/paymentWidgets.js?checkoutId=${props.scriptSrc}`
    //         } else if (props?.pay_type === 'apple_pay') {
    //             scriptElement.src = `${
    //                 process.env.REACT_APP_APPLE_OPPWA_URL || 'https://eu-test.oppwa.com'
    //             }/v1/paymentWidgets.js?checkoutId=${props.scriptSrc}`
    //         } else if (props?.pay_type === 'stc_pay') {
    //             scriptElement.src = `${
    //                 process.env.REACT_APP_OPPWA_URL || 'https://eu-test.oppwa.com'
    //             }/v1/paymentWidgets.js?checkoutId=${props.scriptSrc}`
    //         }

    //         document.body.appendChild(scriptElement)
    //         return () => {
    //             document.body.removeChild(scriptElement)
    //         }
    //     }

    //     // Cleanup the script element when the component unmounts
    // }, [props.scriptSrc])
    useEffect(() => {
        if (props.scriptSrc) {
            console.log(props.scriptSrc, 'fghyy')

            // Create a script element for the configuration
            const configScript = document.createElement('script')
            let configScriptContent = ''
            let language = localStorage.i18nextLng === 'for_arabic' ? 'ar' : 'en'

            document.body.appendChild(configScript)

            if (props?.pay_type === 'card') {
                configScriptContent = `
          var wpwlOptions = { style: "card",locale:"${language}", registrations: { requireCvv: true } };
        `
            } else {
                configScriptContent = `var wpwlOptions = {locale:"${language}"};` // Default configuration if not 'card'
            }

            configScript.innerHTML = configScriptContent
            document.body.appendChild(configScript)

            // Create a script element for the external script
            const externalScript = document.createElement('script')
            let externalScriptUrl = ''

            if (props?.pay_type === 'apple_pay') {
                externalScriptUrl = `${
                    process.env.REACT_APP_APPLE_OPPWA_URL || 'https://eu-test.oppwa.com'
                }/v1/paymentWidgets.js?checkoutId=${props.scriptSrc}`
            } else if (props?.pay_type === 'stc_pay') {
                externalScriptUrl = `${
                    process.env.REACT_APP_OPPWA_URL || 'https://eu-test.oppwa.com'
                }/v1/paymentWidgets.js?checkoutId=${props.scriptSrc}`
            } else {
                externalScriptUrl = `${
                    process.env.REACT_APP_OPPWA_URL || 'https://eu-test.oppwa.com'
                }/v1/paymentWidgets.js?checkoutId=${props.scriptSrc}`
            }

            externalScript.src = externalScriptUrl
            externalScript.async = true // Load asynchronously
            document.body.appendChild(externalScript)

            // Cleanup function to remove both script elements
            return () => {
                document.body.removeChild(configScript)
                document.body.removeChild(externalScript)
            }
        }
    }, [props.scriptSrc, props.pay_type])
    return (
        <>
            <Popup
                open={props.scriptSrc && props.data.status === 'new' && props.data.popup}
                size="md"
                handleClose={() => props.function(false, 'new', null)}
                modaltitle={t('Payment')}
            >
                <div>
                    {/* Add your CSS styles here */}
                    {/* <style>
                {`
          body {
            background-color: #f6f6f5;
          }
        `}
            </style> */}

                    {/* Your payment form */}

                    <form
                        action={`${process.env.REACT_APP_FRONT_DOMAIN}/successPay`}
                        className="paymentWidgets"
                        data-brands={
                            props?.pay_type === 'card'
                                ? 'VISA MASTER AMEX'
                                : props?.pay_type === 'stc_pay'
                                ? 'STC_PAY'
                                : 'APPLEPAY'
                        }
                    ></form>
                </div>
            </Popup>
        </>
    )
}

export default CyberSourceCardDetails
