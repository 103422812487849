import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PayContext from '../../product/context/payment/payContext'
import AuthContext from '../../product/context/auth/authContext'
import Lottie from 'react-lottie'
import animationData from '../../assets/lottie/paymentAnimation.json'
import successAnimation from '../../assets/lottie/successAnimation.json'
import failureAnimation from '../../assets/lottie/failureAnimation.json'
import queryString from 'query-string'
import { handleRedirectInternal } from '../../product/common/components'
import CustomDialog from '../../custom/components/organisms/Dialog'
import { Button } from '@material-ui/core'
import { currencyFormat } from '../../custom/common/components'
import PayGccContext from '../../product/context/payment/paygcc/payGccContext'
import BuyerContext from '../../product/context/buyer/buyerContext'
import HyperPayContext from '../../product/context/payment/hyperpay/payment/hyperPayContext'
import alertContext from '@/product/context/alert/alertContext'
import { useTranslation } from 'react-i18next'
const PaymentSuccess = (props) => {
    const hyperPayContext = useContext(HyperPayContext)
    const buyerContext = useContext(BuyerContext)
    const authContext = useContext(AuthContext)
    let { bidConfirm } = buyerContext
    const payGccContext = useContext(PayGccContext)
    const [paymentResponse, setPaymentResponse] = useState(null)
    const {
        responseStatus: responseStatusHyperPay,
        createCardTokenResponse,
        createCardAutoPayTokenResponse,
    } = hyperPayContext
    const [rosoomResponse, setRosoomResponse] = useState(null)
    const [toggleDialogDeposit, setToggleDialogDeposit] = useState(false)
    const { setAlert } = useContext(alertContext)

    const changeDialogStatusDeposit = () => {
        setToggleDialogDeposit(!toggleDialogDeposit)
    }

    const { isAuthenticated, loadUser } = authContext
    const history = useHistory()
    // const {
    //     responseCartPayTm,
    //     responseStatus: responseStatusPayment,
    //     clearResponse: clearResponsePayment,
    // } = payContext
    useEffect(() => {
        if (responseStatusHyperPay && responseStatusHyperPay.status === 'success') {
            setAlert(responseStatusHyperPay.message, responseStatusHyperPay.status)
        }
    }, [responseStatusHyperPay])
    const { t } = useTranslation()
    useEffect(() => {
        if (props.location.search) {
            const response = queryString.parse(props.location.search)
            setPaymentResponse(null)
            console.log(response, 'what response')

            // createCardTokenResponse({
            //     checkout_id: response.id,
            //     is_default: props.match.params.default || 0,
            // })
            createCardAutoPayTokenResponse({
                is_default: props.match.params.default || 0,
                checkout_id: response.id,
            })
            setToggleDialogDeposit(true)
            // responseCartPayTm({ response })
            history.replace({
                search: '',
            })
        }
    }, [props.location.search])

    // useEffect(() => {
    //     if (responseStatusPayment) {
    //         if (responseStatusPayment.from === 'paytmResponse') {
    //             setPaymentResponse(responseStatusPayment)
    //             clearResponsePayment()
    //             setToggleDialogDeposit(true)
    //             loadUser()
    //         }
    //     }
    // }, [responseStatusPayment])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        speed: '0.25',
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    const paymentSuccessOptions = {
        loop: true,
        autoplay: true,
        speed: '0.25',
        animationData: successAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    const paymentFailureOptions = {
        loop: true,
        autoplay: true,
        speed: '0.25',
        animationData: failureAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    useEffect(() => {
        if (responseStatusHyperPay) {
            if (responseStatusHyperPay.status === 'success') {
                history.goBack()
            }
        }
    }, [responseStatusHyperPay])

    const redirectFunction = () => {
        history.goBack()
    }
    return (
        <div className="container">
            <div className="paymentResponse">
                <Lottie options={defaultOptions} speed="0.25" height={300} width={400} />
                <h2>{t('Hang in there your card is being processed')}</h2>
                <h4>{t('Please do not press the back button or refresh the page')}</h4>
            </div>
            {responseStatusHyperPay && responseStatusHyperPay.status ? (
                <CustomDialog
                    title=""
                    disableBackdropClick
                    open={toggleDialogDeposit}
                    className="paymentNotifCnt"
                    function={changeDialogStatusDeposit}
                >
                    {responseStatusHyperPay.status === 'success' ? (
                        <div className="paymentNotif">
                            <Lottie
                                options={paymentSuccessOptions}
                                speed="0.25"
                                height="auto"
                                width={150}
                            />
                            <div>
                                <h3>{t('Card Added Successfully')}</h3>

                                {/* <h5>
                                    You have successfully made a purchase of{' '}
                                    {currencyFormat(rosoomResponse.serviceCost)}.
                                </h5> */}
                                <Button onClick={() => redirectFunction()}>{t('ok')}</Button>
                            </div>
                        </div>
                    ) : (
                        <div className="paymentNotif failure">
                            <Lottie
                                options={paymentFailureOptions}
                                speed="0.25"
                                height="auto"
                                width={150}
                            />
                            <div>
                                <h3>{t('Card Action Failed')}</h3>
                                <h5>
                                    {responseStatusHyperPay.message
                                        ? responseStatusHyperPay.message
                                        : ''}
                                </h5>
                                <Button onClick={() => redirectFunction()}>{t('ok')}</Button>
                            </div>
                        </div>
                    )}
                </CustomDialog>
            ) : null}
        </div>
    )
}

export default PaymentSuccess
