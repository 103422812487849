import React, { useState, useContext, useEffect } from 'react'
import ProductContext from '@/product/context/product/productContext'
import AuthContext from '@/product/context/auth/authContext'

import './FavoriteCheckbox.css'

const FavoriteCheckbox = ({ watchlisted, project_id }) => {
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const { isAuthenticated } = authContext

    const [checked, setChecked] = useState(parseInt(watchlisted) > 0 ? true : false)
    const { addWatchlist, removeWatchlist } = productContext

    useEffect(() => {
        setChecked(parseInt(watchlisted) > 0 ? true : false)
        return () => {
            setChecked(false)
        }
    }, [project_id, watchlisted])

    const toggleFavourite = () => {
        if (checked) {
            removeWatchlist({ project_id })
            setChecked(false)
        } else {
            addWatchlist({ project_id })
            setChecked(true)
        }
    }

    return (
        <>
            {isAuthenticated && (
                <div className="favoriteCheck d-flex justify-content-center align-items-center">
                    <input
                        id={project_id}
                        type="checkbox"
                        checked={checked}
                        onChange={toggleFavourite}
                    />
                    {checked ? (
                        <label htmlFor={project_id}>
                            <span className="material-icons">favorite</span>
                        </label>
                    ) : (
                        <label htmlFor={project_id}>
                            <span className="material-icons">favorite_border</span>
                        </label>
                    )}
                </div>
            )}
        </>
    )
}
export default FavoriteCheckbox
