import React, { useState, useContext, useEffect, useRef } from 'react'
import DashboardLayout from './DashboardLayout'
import { ListItem } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import MyInvoiceBody from './MyInvoices/MyInvoiceBody'
import { useTranslation } from 'react-i18next'
function MyInvoices(props) {
    const initialValues = {
        limit: 10,
        page: 1,
        orderby: 1,
        checkout: 1,
        filters: {
            active: {
                value: '2',
                type: 'notin',
                field: 'b.active',
            },
        },
    }
    const { t } = useTranslation()
    return (
        <DashboardLayout title={'my invoices'}>
            <div className="dashboardInner myInvoices">
                <div className="auctionStatus">
                    <ListItem button>
                        <NavLink activeClassName="active" to="/invoices/all">
                            {t('all')}
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/invoices/paid">
                            {t('paid')}
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/invoices/partial">
                            {t('unpaid')}
                        </NavLink>
                    </ListItem>
                </div>
                <MyInvoiceBody initialValues={initialValues} action={props.match.params.action} />
            </div>
        </DashboardLayout>
    )
}

export default MyInvoices
