import React, { useState, useContext, useEffect } from 'react'
import { Button } from '@material-ui/core'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import './Dashboard.css'
import DashboardLayout from './DashboardLayout'
import CustomDialog from '../../custom/components/organisms/Dialog'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import CreditContext from '../../product/context/credit/creditContext'
import { handleRedirectInternal } from '@/custom/common/components'
import AuthContext from '../../product/context/auth/authContext'
import { useHistory } from 'react-router-dom'
import PayContext from '../../product/context/payment/hyperpay/payment/hyperPayContext'
import { mapData, calculatePercentage } from '../../product/common/components'
import CustomCommonContext from '@/custom/context/common/commonContext'
import { Pagination } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import HyperPay from '../Checkout/HyperPay'
import NoRecordsFound from '../../custom/components/atoms/NoRecordsFound'
import HyperPayCardContext from '@/product/context/payment/hyperpay/card/cardContext'
import {
    currencyFormat,
    dateFormatFront,
    dateTimeFormatFunction,
} from '../../custom/common/components'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { TrendingUpRounded } from '@material-ui/icons'

function MyWallet() {
    const { t } = useTranslation()
    const [toggleDialog, setToggleDialog] = useState(false)
    const creditContext = useContext(CreditContext)
    const authContext = useContext(AuthContext)
    const payContext = useContext(PayContext)
    const history = useHistory()
    const [disable, setDisable] = useState(false)

    const { user, loadUser, isAuthenticated } = authContext
    const { search_allusercredit, getCreditHistory, responseStatus } = creditContext
    const customCommonContext = useContext(CustomCommonContext)
    const { configVariables } = customCommonContext
    const {
        get_all_card_details,
        responseStatus: responseStatusCard,
        clearResponse: clearResponseCard,
    } = useContext(HyperPayCardContext)
    const [cardCount, setCardCount] = useState(0)

    const {
        payCart,
        responseStatus: responseStatusPayment,
        clearResponse: clearResponsePayment,
    } = payContext

    const [paymentId, setPaymentId] = useState(0)
    const [managePay, setManagePay] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const [toggleDialogCard, setToggleDialogCard] = useState(false)
    const changeDialogStatusCard = () => {
        setToggleDialogCard(!toggleDialogCard)
    }
    useEffect(() => {
        setPaymentId(0)
        toggleFullScreenPayPopup(false, 'new', null)
    }, [])

    const changeDialogStatus = () => {
        formik.handleReset()
        setToggleDialog(!toggleDialog)
    }

    const validationArray = Yup.object({
        amount: Yup.number().min(0).required(t('enter_points')),
        hyperpay_type: Yup.string().required(t('select_payment_type')),
    })

    const formik = useFormik({
        initialValues: {
            amount: '',
            tax_percent: configVariables?.tax || 0,
            sub_total: 0,
            total_amount: 0,
            total_tax: 0,
            hyperpay_type: 'card',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (toggleDialog) {
                let formData = {
                    cart_id: 0,
                    payment_object: {
                        credit_notes: 'Wallet Deposit Amount',
                        credit_type: 'user',
                        creditor_id: user.id,
                    },
                    sub_total: values.amount,
                    amount: values.total_amount,
                    total_tax: values.total_tax,
                    pay_type: 'deposit',
                    hyperpay_type: values.hyperpay_type,
                }
                payCart(formData)
                setDisable(true)
            } else {
                setToggleDialog(true)
            }
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'addCreditBalance') {
                if (responseStatus.status === 'success') {
                    setToggleDialog(false)
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (get_all_card_details && get_all_card_details.records) {
            setCardCount(get_all_card_details.records.length)
        }
    }, [get_all_card_details])

    useEffect(() => {
        formik.values.tax_percent = configVariables?.tax || 0
        console.log(configVariables, 'allConfigurationVariables')
    }, [configVariables])

    let [viewProduct, setViewProduct] = useState([])

    const [search, setSearch] = useState({
        limit: 10,
        page: 1,
        orderby: 'ucd.id, desc',
        order: '',
    })

    useEffect(() => {
        if (isAuthenticated) {
            loadUser()
            getCreditHistory(search)
        }
    }, [search, isAuthenticated])

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    useEffect(() => {
        if (search_allusercredit.records && search_allusercredit.records.length) {
            const mainData = JSON.parse(JSON.stringify(search_allusercredit.records))
            if (localStorage.i18nextLng === 'for_arabic') {
                const data = {
                    'Refund Deposit': 'استرداد الوديعة',
                    'Wallet Deposit Amount': 'مبلغ إيداع المحفظة',
                    'Bid Deposit': 'إيداع العطاء',
                }
                mainData.map((ele) => {
                    if (ele.credit_notes.includes('Bid Deposit')) {
                        ele.credit_notes = ele.credit_notes.replace(
                            'Bid Deposit',
                            data['Bid Deposit'],
                        )
                    } else if (ele.credit_notes.includes('Wallet Deposit Amount')) {
                        ele.credit_notes = ele.credit_notes.replace(
                            'Wallet Deposit Amount',
                            data['Wallet Deposit Amount'],
                        )
                    } else if (ele.credit_notes.includes('Refund Deposit')) {
                        ele.credit_notes = ele.credit_notes.replace(
                            'Refund Deposit',
                            data['Wallet Deposit Amount'],
                        )
                    }
                })
            }
            setViewProduct(mainData.length ? mainData : [])
        } else {
            setViewProduct([])
        }
    }, [search_allusercredit, localStorage.i18nextLng])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const amount = {
        formik: formik,
        data: [
            {
                label: t('enter_points'),
                name: 'amount',
                type: 'number',
                placeholder: t('enter_points'),
                class: 'mt-3 ',
                autoFocus: false,
            },
            {
                label: t('payment_type'),
                type: 'radio',
                name: 'hyperpay_type',
                item: [
                    {
                        id: 'card',
                        description: (
                            <div className="payment-option">
                                <img
                                    src="/assets/svg/visa_pay_logo.svg"
                                    alt="STC Pay Logo"
                                    className="payment-logo"
                                />
                                {/* <p>t('card')</p> */}
                            </div>
                        ),
                    },
                    {
                        id: 'stc_pay',
                        description: (
                            <div className="payment-option">
                                <img
                                    src="/assets/svg/stc_pay_logo.svg"
                                    alt="STC Pay Logo"
                                    className="payment-logo"
                                />
                                {/* <p>{t('stc_pay')}</p> */}
                            </div>
                        ),
                    },
                    {
                        id: 'apple_pay',
                        description: (
                            <div className="payment-option">
                                <img
                                    src="/assets/svg/apple_pay_logo.svg"
                                    alt="Apple Pay Logo"
                                    className="payment-logo"
                                />
                                {/* <p> {t('apple_pay')} </p> */}
                            </div>
                        ),
                    },
                ],
                class: 'col-12 pointsPaymentCnt',
            },
        ],
    }

    //To calculate the processingfees
    const getProcessingFees = (totalAmount, processingPercent) => {
        if (processingPercent && totalAmount) {
            let calculatedPercent = calculatePercentage(parseFloat(totalAmount), processingPercent)
            return calculatedPercent
        } else {
            return {
                percentAmount: 0,
            }
        }
    }

    useEffect(() => {
        if (formik.values.amount !== '' && formik.values.amount) {
            let calculatedPercent = getProcessingFees(
                formik.values.amount,
                formik.values.tax_percent,
            )
            formik.values.total_amount = calculatedPercent.totalAmount
            formik.values.total_tax = calculatedPercent.percentAmount
        }
    }, [formik.values.amount])

    const toggleFullScreenPayPopup = (popup, status, data) => {
        setManagePay({ popup, status, data })
    }

    useEffect(() => {
        if (responseStatusPayment) {
            console.log('responseStatusPayment', responseStatusPayment)

            if (responseStatusPayment.from === 'payment') {
                //setAlert(responseStatusPayment.message, responseStatusPayment.status)

                if (
                    responseStatusPayment.status === 'success' ||
                    responseStatusPayment.status === 'COMPLETED'
                ) {
                    console.log('Setting ID', responseStatusPayment)
                    // setPaymentId(responseStatusPayment.data.id)
                    setPaymentId(responseStatusPayment.data?.result?.id)
                    toggleFullScreenPayPopup(true, 'new', null)
                    formik.values.amount = ''
                    setToggleDialog(false)
                    // if (
                    //     responseStatusPayment?.data?.common_invoice &&
                    //     formikPayment.values.delivery_mode != 2
                    // ) {
                    //     console.log('kkkk')
                    //     handleRedirectInternal(
                    //         history,
                    //         `appointment/schedule/${responseStatusPayment.data.common_invoice}`,
                    //     )
                    // } else {
                    // handleRedirectInternal(history, 'invoices/all')
                    // }
                }
                setDisable(false)
            }
        }
        return () => {
            clearResponsePayment()
        }
        //setLoading(false)
    }, [responseStatusPayment])

    const closeFunction = () => {
        setToggleDialog(false)
        formik.values.amount = ''
    }

    const addFunction = () => {
        changeDialogStatus()
    }

    return (
        <DashboardLayout title={t('my_wallet')}>
            <div className="myAccount myWallet">
                <div className="accountCnt">
                    <h2 className="dashTitle">{t('my_wallet')}</h2>

                    <div className="walletHead d-flex justify-content-between align-items-center flex-wrap">
                        <h3 className="walletBalance">
                            <span className="material-icons">account_balance_wallet</span>
                            {t('my_wallet_balance')}:
                            <span>{user?.deposit_amount ? user?.deposit_amount : 0}</span>
                        </h3>
                        <Button variant="outlined" onClick={addFunction}>
                            <span className="material-icons">add</span>

                            {t('add_points')}
                        </Button>
                    </div>
                    {viewProduct.length ? (
                        <>
                            <div className="table-responsive naamTable">
                                <table className="table text-left">
                                    <thead>
                                        <tr>
                                            <th scope="col">{t('date')}</th>
                                            <th scope="col">{t('description')}</th>
                                            <th scope="col">{t('Points')}</th>
                                            <th scope="col">{t('type')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {viewProduct.map((data, index) => (
                                            <tr key={index}>
                                                <td>{dateFormatFront(data.date_added)}</td>
                                                <td>{data.credit_notes || '-'}</td>
                                                <td>{data.amount}</td>
                                                <td
                                                    className={`${
                                                        data.remove_credit === 0
                                                            ? 'credit'
                                                            : 'debit'
                                                    }`}
                                                >
                                                    {data.remove_credit === 0 ? (
                                                        <span className="wbCredit">
                                                            {t('credit')}
                                                        </span>
                                                    ) : (
                                                        <span className="wbDebit">
                                                            {t('debit')}
                                                        </span>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper">
                                <h6>
                                    {t('showing')} {search_allusercredit.setDisp} {t('of')}{' '}
                                    {search_allusercredit.totalRecords}
                                </h6>
                                <Pagination
                                    count={Math.ceil(
                                        search_allusercredit.totalRecords / search.limit,
                                    )}
                                    page={search.page}
                                    onChange={onHandlePage}
                                    siblingCount={3}
                                    showFirstButton
                                    showLastButton
                                    boundaryCount={2}
                                />
                            </div>
                        </>
                    ) : (
                        <NoRecordsFound />
                    )}
                    <HyperPay
                        pay_type={formik.values.hyperpay_type}
                        scriptSrc={paymentId}
                        data={managePay}
                        function={toggleFullScreenPayPopup}
                    />

                    <CustomDialog
                        title={t('add_points')}
                        open={toggleDialog}
                        function={changeDialogStatus}
                        className="addPointsDialog"
                    >
                        {/* <h5>{t('enter_the_points_to_be_added')}</h5> */}
                        <form onSubmit={formik.handleSubmit} className="addPointsForm">
                            {Object.values(mapData(amount))}
                            <div style={{ marginTop: '16px' }}>
                                {formik.values.total_tax && formik.values.amount !== '' ? (
                                    <p>
                                        <label>
                                            {t('tax')} ({formik.values.tax_percent}%):
                                        </label>
                                        <span>{currencyFormat(formik.values.total_tax, t)}</span>
                                    </p>
                                ) : null}

                                {formik.values.total_amount && formik.values.amount !== '' ? (
                                    <p>
                                        <label>{t('total_amount')}: </label>
                                        <span>{currencyFormat(formik.values.total_amount, t)}</span>
                                    </p>
                                ) : null}
                            </div>

                            <div className="actionWrapper">
                                <Button onClick={() => closeFunction()}>{t('cancel')}</Button>
                                <PrimaryButton
                                    type="submit"
                                    label={t('proceed_to_pay')}
                                    disabled={formik.values.amount !== '' ? false : true}
                                />
                            </div>
                        </form>
                    </CustomDialog>
                    <CustomDialog
                        title={t('No Cards Found')}
                        open={toggleDialogCard}
                        function={changeDialogStatusCard}
                    >
                        <h5>{t("You don't have added any cards yet!")}</h5>
                        <h5>{t('Please add a card to continue Bidding')}</h5>
                        <div className="actionWrapper">
                            <Button id="cancel" onClick={() => setToggleDialogCard(false)}>
                                {t('cancel')}
                            </Button>
                            <PrimaryButton
                                id="add_card"
                                label={t('continue')}
                                btnSize="small"
                                onClick={() =>
                                    handleRedirectInternal(history, 'payments/saved_cards')
                                }
                            />
                        </div>
                    </CustomDialog>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default MyWallet
