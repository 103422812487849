import React from 'react'
import Skeletons from '../../Skeletons'

function AdsBannerSkeleton() {
    return (
        <div className="adsBannerSkeleton">
            <div className="absItem">
                <Skeletons type="title" />
            </div>
        </div>
    )
}

export default AdsBannerSkeleton
