import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import CommonContext from '../../custom/context/common/commonContext'
import './StaticPages.css'

const Static = (props) => {
    const history = useHistory()
    const commonContext = useContext(CommonContext)

    const { static_page, getStaticPage, currentLanguage } = commonContext
    let [viewStatic, setviewStatic] = useState([])

    const [search, setSearch] = useState({
        id: 0,
    })

    useEffect(() => {
        if (props.location.pathname === '/howitworks') {
            setSearch({ id: 'howitworks' })
        } else if (props.location.pathname === '/features') {
            setSearch({ id: 'features' })
        } else if (props.location.pathname === '/pricing') {
            setSearch({ id: 'pricing' })
        } else if (props.location.pathname === '/testimonials') {
            setSearch({ id: 'testimonials' })
        } else if (props.location.pathname === '/team') {
            setSearch({ id: 'team' })
        } else if (props.location.pathname === '/careers') {
            setSearch({ id: 'careers' })
        } else if (props.location.pathname === '/press') {
            setSearch({ id: 'press' })
        } else if (props.location.pathname === '/stores') {
            setSearch({ id: 'stores' })
        } else if (props.location.pathname === '/terms') {
            setSearch({ id: 'terms' })
        } else if (props.location.pathname === '/legal') {
            setSearch({ id: 'legal' })
        } else if (props.location.pathname === '/patents') {
            setSearch({ id: 'patents' })
        } else if (props.location.pathname === '/faq') {
            setSearch({ id: 'faq' })
        } else if (props.location.pathname === '/privacy') {
            setSearch({ id: 'privacy' })
        }
    }, [props.location.pathname])

    useEffect(() => {
        if (search.id !== 0) {
            getStaticPage(search)
        }
    }, [search])

    useEffect(() => {
        setviewStatic(static_page.record ? static_page.record : null)
    }, [static_page])

    return (
        <div className="staticPages">
            <div className="container">
                <div className="d-flex justify-content-center align-items-start">
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                currentLanguage && currentLanguage === 'for_arabic'
                                    ? viewStatic.content_arabic
                                    : viewStatic.content,
                        }}
                    ></div>
                </div>
            </div>
        </div>
    )
}

export default Static
