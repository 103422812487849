import React, { useState, useRef, useEffect, useContext } from 'react'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
// import './ReturnItemList.css'
import { dateFormatFront, handleRedirectInternal, currencyFormat } from '../../../common/components'
import PrimaryButton from '../../atoms/PrimaryButton/index'
import CryptoJS from 'crypto-js'
import aes from 'crypto-js/aes'

const ListView = (props) => {
    const product = props.data
    const history = useHistory()

    const moveToReturnCheckout = (product) => {
        console.log('PRODUCT: ', product)
        var ciphertext = aes
            .encrypt(JSON.stringify(product), process.env.REACT_APP_AES_ENCRYPTION_SECRET)
            .toString()
        sessionStorage.setItem('returnItem', ciphertext)
        handleRedirectInternal(history, 'returncheckout')
    }
    return (
        <>
            <div className="productCardList d-flex justify-content-between align-items-start">
                <div className="psListLt d-flex">
                    <div className="pclImg">
                        <img src={product.avatarorg} alt={product.leadDescription} />
                    </div>

                    <div className="listContent">
                        <h2 onClick={props.drawerHandler} className="listProdTitle">
                            {product.leadDescription}
                        </h2>
                    </div>
                </div>

                <div className="listActBtn">
                    <div className="listTimer d-flex justify-content-between align-items-center">
                        <h6>Closed on:</h6>
                        <p className="d-flex align-items-center">
                            {dateFormatFront(product.date_closed)}
                        </p>
                    </div>
                    <div className="listBidInfo d-flex justify-content-between align-items-center">
                        <h6>Paid Status:</h6>
                        <p className="d-flex justify-content-between align-items-center">
                            <span> {product.isPaid == 1 ? 'Paid' : 'Unpaid'} </span>
                        </p>
                    </div>
                    <div className="listBidInfo d-flex justify-content-between align-items-center mb-4">
                        <h6>Item Status:</h6>
                        <p className="d-flex justify-content-between align-items-center">
                            <span>
                                {product.appointmentId == null
                                    ? 'In Shopping Cart'
                                    : product.appointmentStatusTypeId == 4
                                    ? 'Picked Up'
                                    : 'In Appointment'}
                            </span>
                        </p>
                    </div>
                    <div className="d-flex justify-content-center mt-2">
                        <PrimaryButton
                            label="Return"
                            btnSize="small"
                            onClick={() => moveToReturnCheckout(product)}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default ListView
