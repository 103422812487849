import { ListItem } from '@material-ui/core'
import React, { useEffect, useState, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import './Dashboard.css'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AuthContext from '@/product/context/auth/authContext'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}))

const SideNav = () => {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [active, setActive] = React.useState(false)
    const authContext = useContext(AuthContext)
    const location = useLocation()
    const handleClick = () => {
        setOpen(!open)
    }
    const { t } = useTranslation()
    const dropDownList = ['auction', 'winning', 'outbid', 'won']
    const [expanded, setExpanded] = useState('')
    const { user } = authContext

    const handleChange = (panel) => {
        if (expanded === panel) {
            setExpanded('')
        } else {
            setExpanded(panel)
        }
    }
    useEffect(() => {
        let val = location.pathname.split('/')
        const path = val[val.length - 1]
        if (dropDownList.includes(path)) {
            setOpen(true)
            setActive(true)
        } else {
            setActive(false)
        }
    }, [location.pathname])

    useEffect(() => {
        if (location.pathname.split('/')[1]) {
            setExpanded(location.pathname.split('/')[1])
        }
    }, [location.pathname])
    return (
        <div className="sideNav">
            <ul>
                <ListItem button>
                    <NavLink activeClassName="active" to="/myaccount">
                        {user && user.profile_image ? (
                            <span className="profileImgCnt">
                                <img
                                    src={
                                        global.site_url +
                                        `/uploads/useravatar/${user.profile_image}`
                                    }
                                />
                            </span>
                        ) : (
                            <span className="material-icons">account_circle</span>
                        )}
                        {t('my_account')}
                    </NavLink>
                </ListItem>
                <ListItem button onClick={handleClick}>
                    <div
                        className={`d-flex justify-content-between align-items-center w-100 ${
                            active && 'activeDropdown'
                        }`}
                    >
                        <span className="d-flex">
                            <span className="material-icons">gavel</span>
                            {t('my_auctions')}
                        </span>
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </div>
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button>
                            <NavLink activeClassName="active" to="/dashboard/auction">
                                {t('All')}
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink activeClassName="active" to="/dashboard/winning">
                                {t('winning')}
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink activeClassName="active" to="/dashboard/outbid">
                                {t('currently_outbid')}
                            </NavLink>
                        </ListItem>
                        <ListItem button>
                            <NavLink activeClassName="active" to="/dashboard/won">
                                {/* <span className="material-icons">emoji_events</span> */}
                                {t('won')}
                            </NavLink>
                        </ListItem>
                    </List>
                </Collapse>
                {/* <ListItem button>
                    <NavLink activeClassName="active" to="/dashboard/buynow">
                        <span className="material-icons">favorite</span>
                        {t('my_purchases')}
                    </NavLink>
                </ListItem> */}

                <ListItem button>
                    <NavLink activeClassName="active" to="/dashboard/watchlist">
                        <span className="material-icons">favorite</span>
                        {t('Watchlist')}
                    </NavLink>
                </ListItem>

                {/* <ListItem button>
                    <NavLink activeClassName="active" to="/cart">
                        <span className="material-icons">shopping_cart</span>Cart
                    </NavLink>
                </ListItem>
                <ListItem button>
                    <NavLink activeClassName="active" to="/appointments">
                        <span className="material-icons">book_online</span>Appointments
                    </NavLink>
                </ListItem> */}
                {/* <ListItem button>
                    <NavLink activeClassName="active" to="/receipts">
                        <span className="material-icons">receipt</span>
                        {t('Receipts')}
                    </NavLink>
                </ListItem> */}
                <ListItem button onClick={() => handleChange('invoices')} id="sidebar_myinvoices">
                    <div
                        className={`d-flex justify-content-between align-items-center w-100 test ${
                            expanded === 'invoices' && 'activeDropdown'
                        }`}
                    >
                        <span className="d-flex">
                            <span className="material-icons">receipt</span>
                            {t('my_invoices')}
                        </span>
                        {expanded === 'invoices' ? <ExpandLess /> : <ExpandMore />}
                    </div>
                </ListItem>

                <Collapse in={expanded === 'invoices'} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button>
                            <NavLink
                                activeClassName="active"
                                to="/invoices/all"
                                id="sidebar_allinvoices"
                            >
                                {t('all')}
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink activeClassName="active" to="/invoices/paid" id="sidebar_paid">
                                {t('paid')}
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink
                                activeClassName="active"
                                to="/invoices/partial"
                                id="sidebar_unpaid"
                            >
                                {t('unpaid')}
                            </NavLink>
                        </ListItem>
                    </List>
                </Collapse>
                {/* <ListItem button>
                    <NavLink activeClassName="active" to="/returns">
                        <span className="material-icons">keyboard_return</span>Return
                    </NavLink>
                </ListItem> */}
                {/* <ListItem button>
                    <NavLink activeClassName="active" to="/savedCard">
                        <span className="material-icons">payments</span>My Saved Cards
                    </NavLink>
                </ListItem> */}
                <ListItem button>
                    <NavLink activeClassName="active" to="/saved_search">
                        <span className="material-icons">bookmark</span>
                        {t('my_saved_searches')}
                    </NavLink>
                </ListItem>
                <ListItem button>
                    <NavLink activeClassName="active" to="/mypoints">
                        <span className="material-icons">account_balance_wallet</span>
                        {t('my_wallet')}
                    </NavLink>
                </ListItem>
                <ListItem button>
                    <NavLink activeClassName="active" to="/payments/saved_cards">
                        <span className="material-icons">style</span>
                        {t('My Cards')}
                    </NavLink>
                </ListItem>
            </ul>
        </div>
    )
}

export default SideNav
