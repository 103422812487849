import React, { useContext, useEffect, useState } from 'react'
import { mapData, converDate, getStateCode, getCountryCode } from '@/product/common/components'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import SecondaryButton from '@/product/components/atoms/SecondaryButton'
import { Button } from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomDialog from '../../custom/components/organisms/Dialog/index'
import { useTranslation } from 'react-i18next'
import HyperPayCardContext from '@/product/context/payment/hyperpay/card/cardContext'
import AuthContext from '@/product/context/auth/authContext'
import ProductCommonContext from '@/product/context/common/commonContext'
import AlertContext from '@/product/context/alert/alertContext'
import Popup from '../../custom/components/organisms/Popup'
import Loaders from '../../custom/components/molecules/Loaders'

function CyberSourceCardDetails(props) {
    const authContext = useContext(AuthContext)
    const productCommonContext = useContext(ProductCommonContext)
    const alertContext = useContext(AlertContext)
    const [loading, setLoading] = useState(false)
    const { setAlert } = alertContext
    const { user, loadUser } = authContext
    const { getPreviousData, clearPreviousData, previous_data, allCountries, allStates } =
        productCommonContext
    const { createCard, deleteCard, updateCard, defaultCard, responseStatus, clearResponse } =
        useContext(HyperPayCardContext)
    const [disabled, setDisabled] = useState(false)
    const [changeStatus, setChangeStatus] = useState(false)
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }
    const { t } = useTranslation()
    const cardType = [
        {
            value: 'CREDIT',
            show: 'Credit',
        },
        {
            value: 'DEBIT',
            show: 'Debit',
        },
    ]

    const cardBrand = [
        {
            value: 'VISA',
            show: 'Visa',
        },
        {
            value: 'MASTERCARD',
            show: 'Mastercard',
        },
        {
            value: 'AMEX',
            show: 'Amex',
        },
    ]

    const [reload, setReload] = useState(false)
    const creditCardYearOptions = []
    let date = new Date()
    let currentYear = date.getFullYear()
    let upToYear = parseInt(currentYear) + 25
    for (let year = parseInt(currentYear); year < parseInt(upToYear); year++) {
        creditCardYearOptions.push({
            value: year,
            show: year,
        })
    }

    const validationCard = Yup.object({
        card_number: Yup.string()
            .min(12, 'Invalid credit card number!')
            .max(16, 'Invalid credit card number!')
            .required('Required!'),
        card_first_name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z ]*$/g, 'No Special Characters and Numbers Allowed')
            .required('Required!'),
        card_last_name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z ]*$/g, 'No Special Characters and Numbers Allowed')
            .required('Required!'),
        card_exp_month: Yup.string().required('Required!'),
        card_exp_year: Yup.string().required('Required!'),
        card_cvc: Yup.string()
            .trim()
            .matches(/^[0-9]*$/g, 'Only positive numbers allowed')
            .max(4, 'Maximum 4 Numbers required')
            .min(3, 'Mininum 3 Numbers required')
            .required('Required!'),
        card_address_line1: Yup.string().required('Required!'),
        card_address_line2: Yup.string(),
        card_address_city: Yup.string().required('Required!'),
        card_address_state: Yup.string().required('Required!'),
        card_address_country: Yup.string().required('Required!'),
        card_address_zip: Yup.string()
            .trim()
            .matches(/^[0-9A-Za-z-]*$/g, 'Only numbers, hyphen and alphabets allowed')
            .max(10, 'Maximum 10 characters')
            .min(4, 'Minimum 4 characters')
            .required('Required!'),
        card_type: Yup.string().required('Required!'),
        card_brand: Yup.string().required('Required!'),
        // phone: Yup.string().matches(phonenumber, 'Phone number is not valid'),
    })

    const cardFormik = useFormik({
        initialValues: {
            customer_id: user ? user.versapay_id : '',
            source_id: '',
            card_number: '',
            card_first_name: '',
            card_last_name: '',
            card_exp_month: '',
            card_exp_year: '',
            card_cvc: '',
            card_address_line1: '',
            card_address_line2: '',
            card_address_city: '',
            card_address_state: '',
            card_address_zip: '',
            card_type: 'CREDIT',
            card_brand: '',
            card_address_country: global.defaultCountry,
            is_primary: '',
            user_id: '',
            id: '',
            card_token: true,
            card_billing_id: '',
            priority: '',
        },
        validationSchema: validationCard,
        onSubmit: (values) => {
            setLoading(true)
            let value = values
            if (value.priority) {
                value.priority = 1
            }
            createCard({
                ...value,
            })
        },
    })

    let currMonth = date.getMonth() + 1
    const creditCardMonthOptions = []
    let startMonth = parseInt(cardFormik.values?.card_exp_year, 10) <= currentYear ? currMonth : 1
    for (let month = parseInt(startMonth); month <= parseInt(12); month++) {
        let monthValue = ('0' + month).slice(-2)
        creditCardMonthOptions.push({
            value: ('0' + month).slice(-2),
            show: ('0' + month).slice(-2),
        })

        // creditCardMonthOptions.filter.push({
        //     value: ('0' + month).slice(-2),
        //     show: ('0' + month).slice(-2),
        // })
    }

    const validationupdateCard = Yup.object({
        card_number: Yup.string()
            .min(12, 'Invalid credit card number!')
            .max(16, 'Invalid credit card number!')
            .required('Required!'),
        card_first_name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z ]*$/g, 'No Special Characters and Numbers Allowed')
            .required('Required!'),
        card_last_name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z ]*$/g, 'No Special Characters and Numbers Allowed')
            .required('Required!'),
        card_exp_month: Yup.string().required('Required!'),
        card_exp_year: Yup.string().required('Required!'),
        card_cvc: Yup.string()
            .trim()
            .matches(/^[0-9]*$/g, 'Only positive numbers allowed')
            .max(4, 'Maximum 4 Numbers required')
            .min(3, 'Mininum 3 Numbers required')
            .required('Required!'),
        card_address_line1: Yup.string().required('Required!'),
        card_address_line2: Yup.string(),
        card_address_city: Yup.string().required('Required!'),
        card_address_state: Yup.string().required('Required!'),
        card_address_country: Yup.string().required('Required!'),
        card_address_zip: Yup.string()
            .trim()
            .matches(/^[0-9A-Za-z-]*$/g, 'Only numbers, hyphen and alphabets allowed')
            .max(10, 'Maximum 10 characters')
            .min(4, 'Minimum 4 characters')
            .required('Required!'),
        card_type: Yup.string().required('Required!'),
        card_brand: Yup.string().required('Required!'),
    })

    const updateCardformik = useFormik({
        initialValues: {
            customer_id: user ? user.versapay_id : '',
            source_id: '',
            card_number: '',
            card_first_name: '',
            card_last_name: '',
            card_exp_month: '',
            card_exp_year: '',
            card_cvc: '',
            card_address_line1: '',
            card_address_line2: '',
            card_address_city: '',
            card_address_state: '',
            card_address_zip: '',
            card_type: 'CREDIT',
            card_brand: '',
            card_address_country: global.defaultCountry,
            is_primary: '',
            user_id: '',
            id: '',
            card_token: true,
            card_billing_id: '',
            priority: false,
        },
        validationSchema: validationupdateCard,
        onSubmit: (values) => {
            setLoading(true)
            let value = values
            if (value.priority) {
                value.priority = 1
            }
            createCard({
                ...value,
            })
        },
    })

    const formikSelection = useFormik({
        initialValues: {
            data: '',
            status: '',
        },
        onSubmit: (values) => {
            const dataValue = values
            console.log('props.data.data', props.data)
            if (dataValue.status === 'delete') {
                deleteCard({
                    token: props.data.data['token'],
                    id: props.data.data['id'],
                })
                setChangeStatus(false)
                props.function(false, 'new', null)
            } else if (dataValue.status === 'default') {
                defaultCard({
                    user_id: user.id,
                    id: props.data.data['id'],
                    is_default: 1,
                })
                setChangeStatus(false)
                props.function(false, 'new', null)
            }
        },
    })

    // useEffect(() => {
    //     console.log('here')
    //     if (previous_data) {
    //         if (previous_data.records.length) {
    //             // setAlert(
    //             //     'Removal of Card/ACH is permitted only after 6 weeks from the last bid',
    //             //     'error',
    //             // )
    //             // formikSelection.values.data = ''
    //             // formikSelection.values.status = ''
    //             // clearPreviousData()
    //             // props.function(false, 'new', null)
    //         } else if (formikSelection.values.status) {
    //             setChangeStatus(true)
    //         }
    //     }
    // }, [previous_data])

    // useEffect(() => {
    //     if (cardFormik.values.card_number) {
    //         let string = cardFormik.values.card_number.toString()
    //         console.log(string, 'cardbrand')
    //         let firstChar = string.charAt(0)
    //         console.log(firstChar, 'firstt')

    //         if (firstChar == 3) {
    //             cardFormik.values.card_brand = 'AMEX'
    //         } else if (firstChar == 5) {
    //             cardFormik.values.card_brand = 'MASTERCARD'
    //         } else if (firstChar == 4) {
    //             cardFormik.values.card_brand = 'VISA'
    //         } else {
    //             cardFormik.values.card_brand = ''
    //         }
    //     } else {
    //         cardFormik.values.card_brand = ''
    //     }
    // }, [cardFormik.values.card_number])

    // useEffect(() => {
    //     if (updateCardformik.values.card_number) {
    //         let string = updateCardformik.values.card_number.toString()
    //         console.log(string, 'cardbrand')
    //         let firstChar = string.charAt(0)
    //         console.log(firstChar, 'firstt')
    //         if (firstChar == 3) {
    //             updateCardformik.values.card_brand = 'AMEX'
    //         } else if (firstChar == 5) {
    //             updateCardformik.values.card_brand = 'MASTERCARD'
    //         } else if (firstChar == 4) {
    //             updateCardformik.values.card_brand = 'VISA'
    //         } else {
    //             updateCardformik.values.card_brand = ''
    //         }
    //     } else {
    //         updateCardformik.values.card_brand = ''
    //     }
    // }, [updateCardformik.values.card_number])

    useEffect(() => {
        if (props.data.data) {
            if (props.data.status === 'delete') {
                formikSelection.values.data = props.data.data
                formikSelection.values.status = props.data.status
                setChangeStatus(true)
                // if (get_all_card_details.records.length > 1) {
                //     setChangeStatus(true)
                // } else {
                //     setAlert('Default card is required', 'error')
                //     setChangeStatus(false)
                //     props.function(false, 'new', null)
                // }
            } else if (props.data.status === 'default') {
                formikSelection.values.data = props.data.data
                formikSelection.values.status = props.data.status
                setChangeStatus(true)
            } else if (props.data.status === 'update') {
                const preFillAddress = props.preFillAddress || null
                console.log('props.data.data', props.data.data)
                updateCardformik.values.card_billing_id = props.data.data['id']
                updateCardformik.values.priority =
                    props.data.data['priority'] === '1' ? true : false

                updateCardformik.values.card_first_name = props.data.data['first_name']
                updateCardformik.values.card_last_name = props.data.data['last_name']
                updateCardformik.values.card_number = ''
                updateCardformik.values.card_exp_month = props.data.data['cc_exp'].slice(0, 2)
                updateCardformik.values.card_exp_year = '20' + props.data.data['cc_exp'].slice(-2)

                updateCardformik.values.card_cvc = ''

                updateCardformik.values.card_address_line1 = props.data.data['address_1']
                    ? props.data.data['address_1']
                    : preFillAddress && preFillAddress.address1
                    ? preFillAddress.address1
                    : ''
                updateCardformik.values.card_address_line2 =
                    props.data.data['address_2'] && Object.keys(props.data.data['address_2']).length
                        ? props.data.data['address_2']
                        : preFillAddress && preFillAddress.address2
                        ? preFillAddress.address2
                        : ''

                updateCardformik.values.card_address_city = props.data.data['city']
                    ? props.data.data['city']
                    : preFillAddress && preFillAddress.city
                    ? preFillAddress.city
                    : ''
                updateCardformik.values.card_address_country = props.data.data['country']
                    ? getCountryCode(allCountries, props.data.data['country']).isoCode
                    : preFillAddress && preFillAddress.country
                    ? preFillAddress.country
                    : global.defaultCountry

                updateCardformik.values.card_address_state = props.data.data['state']
                    ? getStateCode(allStates, props.data.data['state']).isoCode
                    : preFillAddress && preFillAddress.state
                    ? preFillAddress.state
                    : ''
                updateCardformik.values.card_address_zip = props.data.data['postal_code']
                    ? props.data.data['postal_code']
                    : preFillAddress && preFillAddress.zip
                    ? preFillAddress.zip
                    : ''

                updateCardformik.values.card_type = 'CREDIT'
                updateCardformik.values.card_brand = ''

                setReload(!reload)
            }
        } else if (props.data.status === 'new') {
            const preFillAddress = props.preFillAddress || null

            cardFormik.values.card_billing_id = ''
            cardFormik.values.card_first_name = ''
            cardFormik.values.card_last_name = ''
            cardFormik.values.card_number = ''
            cardFormik.values.card_exp_month = ''
            cardFormik.values.card_exp_year = ''
            cardFormik.values.card_cvc = ''

            cardFormik.values.card_address_line1 = preFillAddress ? preFillAddress.address1 : ''
            cardFormik.values.card_address_line2 = preFillAddress
                ? preFillAddress.address2
                    ? preFillAddress.address2
                    : ''
                : ''
            cardFormik.values.card_address_city = preFillAddress ? preFillAddress.city : ''
            cardFormik.values.card_address_state = preFillAddress ? preFillAddress.state : ''
            cardFormik.values.card_address_zip = preFillAddress ? preFillAddress.zip : ''
            cardFormik.values.card_type = 'CREDIT'
            cardFormik.values.card_brand = ''
            cardFormik.values.card_address_country = preFillAddress
                ? preFillAddress.country
                : global.defaultCountry
            cardFormik.values.priority = true

            setReload(!reload)
        }
    }, [props.data.data, props.data])

    useEffect(() => {
        if (responseStatus) {
            if (
                responseStatus.from === 'card_source_create' ||
                responseStatus.from === 'card_source_create_customer_card'
            ) {
                setDisabled(false)
                props.function(false, 'new', null)
            }
            setLoading(false)
        }
    }, [responseStatus])
    console.log('allStates', allStates)
    const cardUpdate = {
        formik: updateCardformik,
        data: [
            {
                label: 'Card Holder First Name',
                type: 'text',
                placeholder: 'Enter your card holder first name',
                class: 'col-6',
                name: 'card_first_name',
            },
            {
                label: 'Card Holder Last Name',
                type: 'text',
                placeholder: 'Enter your card holder last name',
                class: 'col-6',
                name: 'card_last_name',
            },
            {
                label: 'Card Number',
                type: 'number',
                placeholder: 'Enter your card number',
                class: 'col-12',
                name: 'card_number',
            },
            {
                label: 'Expiry Month',
                placeholder: 'MM',
                class: 'col-6',
                type: 'select',
                name: 'card_exp_month',
                options: creditCardMonthOptions,
            },
            {
                label: 'Expiry Year',
                placeholder: 'YYYY',
                class: 'col-6',
                type: 'select',
                name: 'card_exp_year',
                options: creditCardYearOptions,
            },
            {
                label: 'CVV',
                type: 'text',
                placeholder: 'Enter your Cvv',
                class: 'col-6',
                name: 'card_cvc',
            },
            {
                class: 'col-6',
                type: 'misc',
            },
            // {
            //     label: 'Card Type',
            //     placeholder: 'Select Card Type',
            //     class: 'col-6',
            //     type: 'select',
            //     name: 'card_type',
            //     options: cardType,
            // },
            {
                label: 'Card Brand',
                placeholder: 'Select Card Brand',
                class: 'col-6',
                type: 'select',
                name: 'card_brand',

                options: cardBrand,
            },
            {
                label: 'Card address line 1',
                type: 'text',
                placeholder: 'Enter card address line1',
                class: 'col-12',
                name: 'card_address_line1',
            },
            {
                label: 'Card address line 2',
                type: 'text',
                placeholder: 'Enter card address line2',
                class: 'col-12',
                name: 'card_address_line2',
            },
            {
                label: 'Card address city',
                type: 'text',
                placeholder: 'Enter card address city',
                class: 'col-12',
                name: 'card_address_city',
            },
            // {
            //     label: 'Card address state',
            //     type: 'text',
            //     placeholder: 'Enter card address state',
            //     class: 'col-12',
            //     name: 'card_address_state',
            // },
            // {
            //     label: 'Card address country',
            //     type: 'text',
            //     placeholder: 'Enter card address country',
            //     class: 'col-12',
            //     name: 'card_address_country',
            // },
            {
                label: 'Card address zip',
                type: 'text',
                placeholder: 'Enter card address zip',
                class: 'col-12',
                name: 'card_address_zip',
            },
            {
                label: 'Card address state',
                placeholder: 'Select card address state',
                class: 'col-12',
                type: 'select',
                options: allStates
                    .filter(
                        (state) =>
                            (updateCardformik.values.card_address_country
                                ? updateCardformik.values.card_address_country.indexOf(
                                      state.countryCode,
                                  ) !== -1
                                : global.defaultCountry.indexOf(state.countryCode) !== -1) &&
                            global.ignoreStates.indexOf(state.isoCode) === -1,
                    )
                    .map((buscat) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = buscat.name
                        busCategoryChanged.value = buscat.name
                        return busCategoryChanged
                    }),
                name: 'card_address_state',
            },
            {
                label: 'Card address country',
                placeholder: 'Select card address country',
                class: 'col-12',
                type: 'select',
                options: allCountries
                    ? allCountries
                          .filter((country) => global.ignoreCountry.indexOf(country.isoCode) === -1)
                          .map((country) => {
                              let busCategoryChanged = {}
                              busCategoryChanged.show = country.name
                              busCategoryChanged.value = country.isoCode
                              return busCategoryChanged
                          })
                    : [],
                name: 'card_address_country',
            },
            {
                label: 'Set as Default Card',
                placeholder: 'Default Card',
                type: 'checkbox',
                class: 'col-12',
                name: 'priority',
            },
        ],
    }

    const cardDetails = {
        formik: cardFormik,
        data: [
            {
                label: 'Card Holder First Name',
                type: 'text',
                placeholder: 'Enter your card holder first name',
                class: 'col-6',
                name: 'card_first_name',
            },
            {
                label: 'Card Holder Last Name',
                type: 'text',
                placeholder: 'Enter your card holder last name',
                class: 'col-6',
                name: 'card_last_name',
            },
            {
                label: 'Card Number',
                type: 'number',
                placeholder: 'Enter your card number',
                class: 'col-12',
                name: 'card_number',
            },
            {
                label: 'Expiry Month',
                placeholder: 'MM',
                class: 'col-6',
                type: 'select',
                name: 'card_exp_month',
                options: creditCardMonthOptions,
            },
            {
                label: 'Expiry Year',
                placeholder: 'YYYY',
                class: 'col-6',
                type: 'select',
                name: 'card_exp_year',
                options: creditCardYearOptions,
            },
            {
                label: 'CVV',
                type: 'text',
                placeholder: 'Enter your Cvv',
                class: 'col-6',
                name: 'card_cvc',
            },
            // {
            //     label: 'Card Type',
            //     placeholder: 'Select Card Type',
            //     class: 'col-6',
            //     type: 'select',
            //     name: 'card_type',
            //     options: cardType,
            // },
            {
                label: 'Card Brand',
                placeholder: 'Select Card Brand',
                class: 'col-6',
                type: 'select',
                name: 'card_brand',
                options: cardBrand,
            },
            {
                label: 'Card address line 1',
                type: 'text',
                placeholder: 'Enter card address line1',
                class: 'col-12',
                name: 'card_address_line1',
            },
            {
                label: 'Card address line 2',
                type: 'text',
                placeholder: 'Enter card address line2',
                class: 'col-12',
                name: 'card_address_line2',
            },
            {
                label: 'Card address city',
                type: 'text',
                placeholder: 'Enter card address city',
                class: 'col-12',
                name: 'card_address_city',
            },
            // {
            //     label: 'Card address state',
            //     type: 'text',
            //     placeholder: 'Enter card address state',
            //     class: 'col-12',
            //     name: 'card_address_state',
            // },
            // {
            //     label: 'Card address country',
            //     type: 'text',
            //     placeholder: 'Enter card address country',
            //     class: 'col-12',
            //     name: 'card_address_country',
            // },
            {
                label: 'Card address zip',
                type: 'text',
                placeholder: 'Enter card address zip',
                class: 'col-12',
                name: 'card_address_zip',
            },
            {
                label: 'Card address state',
                placeholder: 'Select card address state',
                class: 'col-12',
                type: 'select',
                options: allStates
                    .filter(
                        (state) =>
                            (cardFormik.values.card_address_country
                                ? cardFormik.values.card_address_country.indexOf(
                                      state.countryCode,
                                  ) !== -1
                                : global.defaultCountry.indexOf(state.countryCode) !== -1) &&
                            global.ignoreStates.indexOf(state.isoCode) === -1,
                    )
                    .map((buscat) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = buscat.name
                        busCategoryChanged.value = buscat.name
                        return busCategoryChanged
                    }),
                name: 'card_address_state',
            },
            {
                label: 'Card address country',
                placeholder: 'Select card address country',
                class: 'col-12',
                type: 'select',
                options: allCountries
                    ? allCountries
                          .filter((country) => global.ignoreCountry.indexOf(country.isoCode) === -1)
                          .map((country) => {
                              let busCategoryChanged = {}
                              busCategoryChanged.show = country.name
                              busCategoryChanged.value = country.isoCode
                              return busCategoryChanged
                          })
                    : [],
                name: 'card_address_country',
            },
            {
                label: 'Set as Default Card',
                placeholder: 'Default Card',
                type: 'checkbox',
                class: 'col-12',
                name: 'priority',
            },
        ],
    }

    // useEffect(() => {
    //     console.log(props.scriptSrc, 'fghyy')
    //     // Include the JavaScript code dynamically
    //     if (props.scriptSrc) {
    //         const scriptElement = document.createElement('script')
    //         scriptElement.innerHTML = `
    //           var wpwlOptions = { style: "card",locale: "ar" };
    //         `
    //         scriptElement.src = `${
    //             process.env.REACT_APP_OPPWA_URL || 'https://eu-test.oppwa.com'
    //         }/v1/paymentWidgets.js?checkoutId=${props.scriptSrc}/registration`

    //         document.body.appendChild(scriptElement)
    //         return () => {
    //             document.body.removeChild(scriptElement)
    //         }
    //     }

    //     // Cleanup the script element when the component unmounts
    // }, [props.scriptSrc])
    useEffect(() => {
        if (props.scriptSrc) {
            // Create a script element to set global options
            const configScript = document.createElement('script')
            let language = localStorage.i18nextLng === 'for_arabic' ? 'ar' : 'en'
            configScript.innerHTML = `
        var wpwlOptions = { style: "card", locale:"${language}"};
      `
            document.body.appendChild(configScript)

            // Create another script element for the external script
            const externalScript = document.createElement('script')
            externalScript.src = `${
                process.env.REACT_APP_OPPWA_URL || 'https://eu-test.oppwa.com'
            }/v1/paymentWidgets.js?checkoutId=${props.scriptSrc}/registration`
            externalScript.async = true

            document.body.appendChild(externalScript)

            // Cleanup function to remove both script elements
            return () => {
                document.body.removeChild(configScript)
                document.body.removeChild(externalScript)
            }
        }
    }, [props.scriptSrc])
    return (
        <>
            <Popup
                open={props.scriptSrc && props.data.status === 'new' && props.data.popup}
                size="md"
                handleClose={() => props.function(false, 'new', null)}
                modaltitle={t('add_card_details')}
            >
                <div>
                    {/* Add your CSS styles here */}
                    {/* <style>
                {`
          body {
            background-color: #f6f6f5;
          }
        `}
            </style> */}

                    {/* Your payment form */}

                    <form
                        action={`/paymentSuccess/${
                            props.allCards && props.allCards.length ? 0 : 1
                        }`}
                        className="paymentWidgets"
                        data-brands="VISA MASTER AMEX"
                    ></form>
                </div>
            </Popup>

            <CustomDialog
                title={t('Confirmation')}
                open={changeStatus}
                function={changeChangeStatus}
                className={'cardViewDialog'}
            >
                <h5>
                    {formikSelection.values.status === 'delete'
                        ? t('Are you sure you want to delete')
                        : formikSelection.values.status === 'default'
                        ? t('Are you sure you want to make this as default card')
                        : t('Are you sure you want to change the status')}
                </h5>
                <div className="actionWrapper">
                    <SecondaryButton onClick={() => changeChangeStatus()} label={t('cancel')} />
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton type="submit" label={t('confirm')} />
                    </form>
                </div>
            </CustomDialog>

            <Popup
                open={props.data.popup && props.data.status === 'update'}
                handleClose={() => props.function(false, 'update', null)}
                size="md"
                modaltitle={t('Update Card Details')}
            >
                <form onSubmit={updateCardformik.handleSubmit} autoComplete="nofill">
                    <div className="row">
                        {mapData(cardUpdate)}
                        <div className="addCardModalActions">
                            <Button
                                type="button"
                                onClick={() => props.function(false, 'update', null)}
                            >
                                {t('cancel')}
                            </Button>
                            <PrimaryButton
                                disabled={disabled}
                                btnSize="large"
                                type="submit"
                                id="updatecard_button"
                                label={t('save')}
                            />
                        </div>
                    </div>
                </form>
            </Popup>
        </>
    )
}

export default CyberSourceCardDetails
