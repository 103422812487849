import React from 'react'
import TextField from '@material-ui/core/TextField'

const CustomInput = (props) => {
    const shrink = props.shrink ? props.shrink.toString() : 'false'
    return (
        <div className="customInput" style={props.inputStyle || props.inputStyle}>
            <TextField
                value={props.value}
                autoFocus={props.autoFocus}
                name={props.name}
                onChange={props.onChange || props.onChange}
                onBlur={props.onBlur || props.onBlur}
                onKeyUp={props.onKeyUp || props.onKeyUp}
                InputProps={{
                    inputProps: props.inputProps,
                    startAdornment: props.startAdornment,
                    endAdornment: props.endAdornment,
                    shrink: shrink,
                }}
                id={props.id}
                label={props.label}
                type={props.type}
                size={props.size}
                disabled={props.disabled}
                variant="outlined"
                placeholder={props.placeholder}
                error={props.error}
                helperText={props.helperText}
                autoComplete={props.autoComplete ? props.autoComplete : 'on'}
            />
        </div>
    )
}

export default CustomInput
