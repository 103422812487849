import React, { useContext, useState, useEffect } from 'react'
import CustomDialog from '../../custom/components/organisms/CustomDialog'
import Button from '@material-ui/core/Button'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import UserContext from '../../custom/context/user/userContext'
import { mapData } from '../../product/common/components'

export default function PhoneValidation(props) {
    const phoneVerify = props.phoneVerify
    const changePhoneVerify = props.changePhoneVerify
    const formik = props.formik
    const setPhoneVerify = props.setPhoneVerify

    const userContext = useContext(UserContext)

    const {
        sendPhoneVerifyCode,
        verifyPhoneVerifyCode,
        responseStatus: responseStatusUser,
    } = userContext

    const sendVerifyCode = () => {
        sendPhoneVerifyCode({ phone: formik.values.phone })
    }

    const verifyPhoneCode = () => {
        verifyPhoneVerifyCode({
            phone: formik.values.phone,
            verify_code: parseInt(formik.values.verify_code, 10),
        })
    }

    const verifyPhone = [
        {
            label: 'Verification Code',
            placeholder: 'Enter Verification Code',
            class: 'col-12',
            type: 'number',
            name: 'verify_code',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (responseStatusUser) {
            if (
                responseStatusUser.from === 'sendPhoneVerifyCode' &&
                responseStatusUser.status === 'success'
            ) {
                formik.values.phone_verifysent = 1
            }
            if (
                responseStatusUser.from === 'verifyPhoneVerifyCode' &&
                responseStatusUser.status === 'success'
            ) {
                formik.values.phone_verified = 1
                formik.values.verified_phonenum = formik.values.phone
                setPhoneVerify(false)
            }
        }
    }, [responseStatusUser])

    return (
        <CustomDialog
            className="verifyPhone"
            title={'Verify Phone'}
            open={phoneVerify}
            // function={changePhoneVerify}
        >
            Verify phone number : {formik.values.phone}
            <br />
            {formik.values.phone_verifysent === 1 && (
                <>
                    <br />
                    <div className="row phVerInp">{Object.values(mapData(verifyPhone))}</div>
                    <span className="phVer">* Your Code will expire in 5 minutes</span>
                    <br />
                    <br />
                    <PrimaryButton onClick={verifyPhoneCode} label="VERIFY CODE" />
                </>
            )}
            <div className="actionWrapper">
                <Button onClick={() => changePhoneVerify()}>Cancel</Button>
                {formik.values.phone_verifysent === 1 ? (
                    <Button className="resendSms" onClick={() => sendVerifyCode()}>
                        Resend SMS
                    </Button>
                ) : (
                    <PrimaryButton label="Send SMS" onClick={() => sendVerifyCode()} />
                )}
            </div>
        </CustomDialog>
    )
}
