import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import { Link } from 'react-router-dom'
import { handleRedirectInternal, mapData } from '../../product/common/components'
import { useHistory } from 'react-router-dom'
import UserContext from '../../product/context/user/userContext'
import AuthContext from '../../product/context/auth/authContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import './VerifyEmail.css'
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginTop: '10px',
        width: '100%',
    },
}))

const VerifyEmail = (props) => {
    const classes = useStyles()
    const userContext = useContext(UserContext)
    const authContext = useContext(AuthContext)
    const [loading, setLoading] = useState(true)
    const [emailVerified, setEmailVerified] = useState(false)

    const { verifyEmail, clearResponse } = userContext
    const { login, responseStatus, isAuthenticated, loadUser } = authContext

    const history = useHistory()

    let [passwordShown, setPasswordShown] = useState(false)

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const validationArray = Yup.object({
        email: Yup.string().email('Invalid email format').required('Required!'),
        facebook_id: Yup.string(),
        google_id: Yup.string(),
        password: Yup.string().when(['facebook_id', 'google_id'], {
            is: (facebookID, googleID) => !facebookID && !googleID,
            then: Yup.string().required('Required!'),
        }),
    })

    const formik = useFormik({
        initialValues: {
            email: localStorage.email ? localStorage.email : '',
            password: localStorage.password ? localStorage.password : '',
            google_id: '',
            facebook_id: '',
            remember_me: localStorage.remember_me ? localStorage.remember_me : false,
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (values.remember_me) {
                localStorage.email = values.email
                localStorage.password = values.password
                localStorage.remember_me = values.remember_me
            } else {
                delete localStorage.email
                delete localStorage.password
                delete localStorage.remember_me
            }
            login(values)
        },
    })

    const rememberMe = [
        {
            label: 'Remember me',
            name: 'remember_me',
            type: 'checkbox',
            placeholder: 'Remember me',
            class: 'col-12',
            formik: formik,
        },
    ]

    const loginInfo = [
        {
            label: 'Email address',
            name: 'email',
            type: 'email',
            placeholder: 'Enter your email address',
            class: 'col-12',
            autoFocus: true,
            formik: formik,
        },
        {
            label: 'Password',
            name: 'password',
            type: passwordShown ? 'text' : 'password',
            placeholder: 'Enter your password',
            class: 'col-12',
            formik: formik,
            endAdornment: passwordShown ? (
                <span className="material-icons cursorPointer" onClick={togglePasswordVisiblity}>
                    visibility_off
                </span>
            ) : (
                <span className="material-icons cursorPointer" onClick={togglePasswordVisiblity}>
                    visibility
                </span>
            ),
        },
    ]

    useEffect(() => {
        if (props.match.params.token) {
            setLoading(true)
            verifyEmail({ user_token: props.match.params.token, status: 'active' })
        } else {
            handleRedirectInternal(history, '')
        }
    }, [props.match.params.token])

    // useEffect(() => {
    //     if (responseStatus) {
    //         if (responseStatus.from === 'verifyEmail') {
    //             if (responseStatus.status === 'success') {
    //                 setEmailVerified(true)
    //                 setLoading(false)
    //             } else {
    //                 setEmailVerified(false)
    //                 setLoading(false)
    //             }
    //         }
    //     }
    // }, [responseStatus])

    useEffect(() => {
        console.log('ram check', responseStatus)
        if (responseStatus) {
            if (responseStatus.from === 'login') {
                if (responseStatus.status === 'success') {
                    setEmailVerified(true)
                    setLoading(false)
                    handleRedirectInternal(history, 'search')
                } else {
                    setEmailVerified(false)
                    setLoading(false)
                }
            }
        }
    }, [responseStatus])

    return (
        <div>
            {loading ? (
                <p> Loading</p>
            ) : emailVerified ? (
                <div className={classes.root}>
                    <div className="emailContainer emailSuccessful">
                        <h3>Your account is verified!</h3>

                        <object
                            aria-label="Success"
                            data="/assets/svg/emailVerifiedSuccess.svg"
                            type="image/svg+xml"
                        />
                        {isAuthenticated ? (
                            <PrimaryButton
                                label="Browse Products"
                                onClick={() => {
                                    loadUser()
                                    handleRedirectInternal(history, 'search')
                                }}
                            />
                        ) : (
                            <>
                                <p>
                                    <strong>Please login below</strong>
                                </p>
                                <div className="loginLt">
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="row">
                                            {Object.values(mapData(loginInfo))}
                                        </div>
                                        <Link className="fpLink" to="/forgot_password">
                                            Forgot Password ?
                                        </Link>
                                        {Object.values(mapData(rememberMe))}
                                        <PrimaryButton
                                            label="LOGIN"
                                            type="submit"
                                            btnSize="large"
                                        />
                                    </form>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <div className={classes.root}>
                    <div className="emailContainer emailError">
                        <h3>Your email could not be verified !</h3>
                        <p>We couldn&apos;t verify your email address, Please try again.</p>
                        <object
                            aria-label="Success"
                            data="/assets/svg/emailVerifiedError.svg"
                            type="image/svg+xml"
                        />
                        <PrimaryButton
                            label="Register Again"
                            onClick={() => handleRedirectInternal(history, 'registration')}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}
export default VerifyEmail
