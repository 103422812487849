import React, { useEffect, useState, useContext } from 'react'
import './App.css'
import Routes from './routes'
import { BrowserRouter as Router } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { I18nextProvider } from 'react-i18next'
import AuthState from '@/product/context/auth/authState'
import CustomCommonState from './custom/context/common/commonState'
import ProductCommonState from '@/product/context/common/commonState'
import UserState from '@/product/context/user/userState'
import AlertState from '@/product/context/alert/alertState'
import ProductState from '@/product/context/product/productState'
import InvoiceState from '@/product/context/invoice/invoiceState'
import DepositState from '@/product/context/deposit/depositState'
import CustomProductState from '@/custom/context/product/productState'
import AuctionState from '@/product/context/auction/auctionState'
import CreditState from '@/product/context/credit/creditState'
import OfflineState from '@/product/context/offline/offlineState'
import CartState from '@/product/context/cart/cartState'
import BuyerState from '@/product/context/buyer/buyerState'
import AutopltState from '@/product/context/autopilot/autopltState'
import PayState from '@/product/context/payment/payState'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import ArtaShippingState from './product/context/shipping/arta/artaState'
import MomentUtils from '@date-io/moment'
import { SnackbarProvider } from 'notistack'
import Alerts from '@/product/common/alert'
import { setAuthToken, setIPAddress, setLocation } from '@/product/common/api'
import BuynowState from '@/product/context/buynow/buynowState'
import AppointmentState from '@/product/context/appointment/appointmentState'
import RosoomState from '@/product/context/payment/rosoom/rosoomState'
import HyperpayCardState from '@/product/context/payment/hyperpay/card/cardState'
import StripeCardState from './product/context/stripe/card/cardState'
import StripeCustomerState from './product/context/stripe/customer/customerState'
import StripeBankState from './product/context/stripe/bank/bankState'
import SellerProductState from './product/context/seller/product/productState'
import SellerSettingState from './product/context/seller/setting/settingState'
import ShipengineState from './product/context/shipping/shipengine/shipengineState'
import SellerAuctionState from './product/context/seller/auction/auctionState'
import TemplateProductState from './product/context/seller/template/product/productState'
import TemplateAuctionState from './product/context/seller/template/auction/auctionState'
import UserStateseller from './product/context/seller/user/userState'
import SellerReportState from './product/context/seller/report/reportState'
import ShipstationState from './product/context/shipping/shipstation/shipstationState'
import MessageState from './product/context/message/messageState'
import i18n from '@/custom/common/language-i18n'
import CustomCommonContext from './custom/context/common/commonContext'
import HeadAlert from './custom/components/molecules/HeadAlert'
import publicIp from 'public-ip'
import BidState from '@/product/context/reverse/bid/bidState'
import { create } from 'jss'
import { getDistance } from 'geolib'
import ipLocation from 'iplocation'
// import rtl from 'jss-rtl'
import DynamicState from '@/product/context/dynamic/dynamicState'
import ProductStates from '@/product/context/product/productState'
import HyperPayState from '@/product/context/payment/hyperpay/payment/hyperPayState'
import CustomCommonStates from '@/custom/context/common/commonState'
import { GoogleOAuthProvider } from '@react-oauth/google'
import {
    MuiThemeProvider,
    StylesProvider,
    createTheme,
    jssPreset,
    useTheme,
} from '@material-ui/core/styles'

global.site_url = process.env.REACT_APP_DOMAIN
global.images_url = global.site_url + '/uploads/product/'
global.ignoreStates = []
global.ignoreCountry = []
global.defaultCountry = 'SA'
global.registerToken = true

if (localStorage.token) {
    setAuthToken(localStorage.token)
}

const App = () => {
    // const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
    const [ipValues, setIpValues] = React.useState(null)
    const [currLang, setCurrLang] = useState()
    const theme = useTheme()
    // const ltrTheme = createTheme({ direction: 'ltr' })
    // const rtlTheme = createTheme({ direction: 'rtl' })

    useEffect(() => {
        if (ipValues) {
            const vegasDistance = getDistance(
                {
                    latitude: ipValues.latitude,
                    longitude: ipValues.longitude,
                },
                {
                    latitude: 36.054666,
                    longitude: -115.181782,
                },
            )
            const phoneixDistance = getDistance(
                {
                    latitude: ipValues.latitude,
                    longitude: ipValues.longitude,
                },
                {
                    latitude: 33.433439,
                    longitude: -112.146476,
                },
            )
            //console.log('vegasDistance', vegasDistance)
            //console.log('phoneixDistance', phoneixDistance)
        }
    }, [ipValues])
    useEffect(() => {
        async function fetchData() {
            const ipaddress = await publicIp.v4()
            const ipLoc = await ipLocation(ipaddress)
            setIpValues({
                latitude: ipLoc.latitude,
                longitude: ipLoc.longitude,
                from: 'ipaddress',
            })
            setLocation(ipLoc && ipLoc.city ? ipLoc.city : '')
            setIPAddress(ipaddress)
        }
        fetchData()
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setIpValues({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        from: 'browser',
                    })
                },
                () => {},
            )
        }
    }, [])

    console.log('test========>', process.env.REACT_APP_GOOGLE_CLIENT_ID)

    return (
        <>
            <CookiesProvider>
                {/* <StylesProvider jss={jss}> */}
                <I18nextProvider i18n={i18n}>
                    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                        <CustomCommonState>
                            {/* <StylesProvider jss={jss}>
                        <MuiThemeProvider
                            theme={createMuiTheme({
                                direction: currLang,
                            })}
                        > */}
                            <ProductCommonState>
                                <AuthState>
                                    <AlertState>
                                        <UserState>
                                            <UserStateseller>
                                                <HyperpayCardState>
                                                    <StripeCardState>
                                                        <StripeCustomerState>
                                                            <StripeBankState>
                                                                <ProductState>
                                                                    <CustomProductState>
                                                                        <AuctionState>
                                                                            <BuynowState>
                                                                                <CartState>
                                                                                    <AppointmentState>
                                                                                        <CreditState>
                                                                                            <RosoomState>
                                                                                                <PayState>
                                                                                                    <HyperPayState>
                                                                                                        <SellerReportState>
                                                                                                            <SellerSettingState>
                                                                                                                <OfflineState>
                                                                                                                    <InvoiceState>
                                                                                                                        <DepositState>
                                                                                                                            <BuyerState>
                                                                                                                                <AutopltState>
                                                                                                                                    <ArtaShippingState>
                                                                                                                                        <ShipengineState>
                                                                                                                                            <SellerProductState>
                                                                                                                                                <SellerAuctionState>
                                                                                                                                                    <TemplateProductState>
                                                                                                                                                        <TemplateAuctionState>
                                                                                                                                                            <ShipstationState>
                                                                                                                                                                <BidState>
                                                                                                                                                                    <MessageState>
                                                                                                                                                                        <DynamicState>
                                                                                                                                                                            <MuiPickersUtilsProvider
                                                                                                                                                                                utils={
                                                                                                                                                                                    MomentUtils
                                                                                                                                                                                }
                                                                                                                                                                            >
                                                                                                                                                                                <SnackbarProvider
                                                                                                                                                                                    maxSnack={
                                                                                                                                                                                        3
                                                                                                                                                                                    }
                                                                                                                                                                                >
                                                                                                                                                                                    <div className="App">
                                                                                                                                                                                        <Alerts />
                                                                                                                                                                                        <HeadAlert />
                                                                                                                                                                                        <Router>
                                                                                                                                                                                            <Routes />
                                                                                                                                                                                        </Router>
                                                                                                                                                                                    </div>
                                                                                                                                                                                </SnackbarProvider>
                                                                                                                                                                            </MuiPickersUtilsProvider>
                                                                                                                                                                        </DynamicState>
                                                                                                                                                                    </MessageState>
                                                                                                                                                                </BidState>
                                                                                                                                                            </ShipstationState>
                                                                                                                                                        </TemplateAuctionState>
                                                                                                                                                    </TemplateProductState>
                                                                                                                                                </SellerAuctionState>
                                                                                                                                            </SellerProductState>
                                                                                                                                        </ShipengineState>
                                                                                                                                    </ArtaShippingState>
                                                                                                                                </AutopltState>
                                                                                                                            </BuyerState>
                                                                                                                        </DepositState>
                                                                                                                    </InvoiceState>
                                                                                                                </OfflineState>
                                                                                                            </SellerSettingState>
                                                                                                        </SellerReportState>
                                                                                                    </HyperPayState>
                                                                                                </PayState>
                                                                                            </RosoomState>
                                                                                        </CreditState>
                                                                                    </AppointmentState>
                                                                                </CartState>
                                                                            </BuynowState>
                                                                        </AuctionState>
                                                                    </CustomProductState>
                                                                </ProductState>
                                                            </StripeBankState>
                                                        </StripeCustomerState>
                                                    </StripeCardState>
                                                </HyperpayCardState>
                                            </UserStateseller>
                                        </UserState>
                                    </AlertState>
                                </AuthState>
                            </ProductCommonState>
                            {/* </MuiThemeProvider>
                    </StylesProvider> */}
                        </CustomCommonState>
                    </GoogleOAuthProvider>
                </I18nextProvider>
                {/* </StylesProvider> */}
            </CookiesProvider>
        </>
    )
}

export default App
