import React, { useState, useContext, useEffect, useRef } from 'react'
import PrimaryButton from '../../custom/components/atoms/PrimaryButton'
import CustomInput from '../../custom/components/atoms/Inputs/CustomInput'
import './Checkout.css'
import CustomSelect from '../../custom/components/atoms/Inputs/CustomSelect'
import { Button, InputAdornment, ListItem } from '@material-ui/core'
import RadioBox from '../../custom/components/atoms/RadioBox'
import { useHistory } from 'react-router-dom'
import CheckBox from '../../custom/components/atoms/CheckBox'
import ReturnContext from '../../product/context/return/returnContext'
import AuthContext from '../../product/context/auth/authContext'
import AlertContext from '../../product/context/alert/alertContext'
import RefundContext from '../../product/context/refund/refundContext'
import CommonContext from '../../product/context/common/commonContext'
import CustomCommonContext from '../../custom/context/common/commonContext'

import AppointmentContext from '../../product/context/appointment/appointmentContext'
import {
    mapData,
    handleRedirectInternal,
    currencyFormat,
    dateFormatFrontDay,
    dateTimeFormatFunction,
} from '../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import momentTz from 'moment-timezone'
import CryptoJS from 'crypto-js'
import aes from 'crypto-js/aes'

const today = new Date()
const appointmentSchedule = [
    { date: dateFormatFrontDay(today), label: 'Today' },
    {
        date: dateFormatFrontDay(new Date(today.getTime() + 24 * 3600000)),
        label: 'Tomorrow',
    },
    {
        date: dateFormatFrontDay(new Date(today.getTime() + 48 * 3600000)),
        label: 'In 2 days',
    },
    {
        date: dateFormatFrontDay(new Date(today.getTime() + 62 * 3600000)),
        label: 'In 3 days',
    },
]

const Checkout = (props) => {
    const returnContext = useContext(ReturnContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const refundContext = useContext(RefundContext)
    const commonContext = useContext(CommonContext)
    const appointmentContext = useContext(AppointmentContext)

    const customCommonContext = useContext(CustomCommonContext)

    const { allLocations } = customCommonContext

    const history = useHistory()
    const { USStates } = commonContext
    const { returnItemHandler, returnTypes, getReturnTypes, responseStatus, clearResponse } =
        returnContext

    const { available_appointments, getAvailbleAppointments } = appointmentContext

    const { isAuthenticated } = authContext
    const { responseStatus: responseStatusPayment, clearResponse: clearResponsePayment } =
        refundContext
    const { setAlert } = alertContext

    let [availAppoints, setAvailAppoints] = useState([])
    let [cartLocation, setCartLocation] = useState({})

    let [allAvailableTime, setAllAvailableTime] = useState([])

    const [search, setSearch] = useState({ cart_id: props.match.params.cart_id })
    const [dataArray, setDataArray] = useState([])

    const [returnItem, setReturnItem] = useState({})

    useEffect(() => {
        if (Object.keys(cartLocation)) {
            let cartLocationDetails = cartLocation
            const weeklyHours =
                cartLocationDetails.weeklyHours && cartLocationDetails.weeklyHours !== 'Closed'
                    ? cartLocationDetails.weeklyHours.split('-')
                    : null
            const saturdayHours =
                cartLocationDetails.saturdayHours && cartLocationDetails.saturdayHours !== 'Closed'
                    ? cartLocationDetails.saturdayHours.split('-')
                    : null
            const sundayHours =
                cartLocationDetails.sundayHours && cartLocationDetails.sundayHours !== 'Closed'
                    ? cartLocationDetails.sundayHours.split('-')
                    : null
            const timeFormat = 'h:m a'
            let weeklyHoursStart = weeklyHours
                ? moment(weeklyHours[0], [timeFormat]).format('HH:mm')
                : 0
            let weeklyHoursEnd = weeklyHours
                ? moment(weeklyHours[1], [timeFormat]).format('HH:mm')
                : 0
            let saturdayHoursStart = saturdayHours
                ? moment(saturdayHours[0], [timeFormat]).format('HH:mm')
                : 0
            let saturdayHoursEnd = saturdayHours
                ? moment(saturdayHours[1], [timeFormat]).format('HH:mm')
                : 0
            let sundayHoursStart = sundayHours
                ? moment(sundayHours[0], [timeFormat]).format('HH:mm')
                : 0
            let sundayHoursEnd = sundayHours
                ? moment(sundayHours[1], [timeFormat]).format('HH:mm')
                : 0
            if (cartLocation.timezone) {
                const dayOfWeek = new Date().getDay()
                let hoursEnd
                // Sunday
                if (dayOfWeek === 0) {
                    hoursEnd = sundayHoursEnd
                }
                // Saturday
                else if (dayOfWeek === 6) {
                    hoursEnd = saturdayHoursEnd
                }
                // Weekdays
                else {
                    hoursEnd = weeklyHoursEnd
                }
                // current date in cart location
                let currentDatetime = moment().tz(cartLocation.timezone).format()
                // end hour in cart location
                let endTime = moment()
                    .tz(cartLocation.timezone)
                    .set('hour', parseInt(hoursEnd) - 1)
                    .set('minute', 45)
                    .set('second', 0)
                    .format()
                // no more available slot for today
                if (endTime < currentDatetime) {
                    appointmentSchedule.splice(0, 1)
                }
            }
            // 1 - Monday 2 - Tuesday
            setDataArray([
                {
                    date: 0,
                    minTime: sundayHoursStart,
                    maxTime: sundayHoursEnd,
                },
                {
                    date: 1,
                    minTime: weeklyHoursStart,
                    maxTime: weeklyHoursEnd,
                },
                {
                    date: 2,
                    minTime: weeklyHoursStart,
                    maxTime: weeklyHoursEnd,
                },
                {
                    date: 3,
                    minTime: weeklyHoursStart,
                    maxTime: weeklyHoursEnd,
                },
                {
                    date: 4,
                    minTime: weeklyHoursStart,
                    maxTime: weeklyHoursEnd,
                },
                {
                    date: 5,
                    minTime: weeklyHoursStart,
                    maxTime: weeklyHoursEnd,
                },
                {
                    date: 6,
                    minTime: saturdayHoursStart,
                    maxTime: saturdayHoursEnd,
                },
            ])
        }
    }, [cartLocation])

    useEffect(() => {
        getReturnTypes()
        getAvailbleAppointments()
        if (sessionStorage.getItem('returnItem')) {
            var bytes = aes.decrypt(
                sessionStorage.getItem('returnItem'),
                process.env.REACT_APP_AES_ENCRYPTION_SECRET,
            )
            var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
            console.log('RETURN ITEM DATA: ', decryptedData)
            setReturnItem(decryptedData)
        } else {
            handleRedirectInternal(history, 'returns')
        }
    }, [])

    useEffect(() => {
        if (Object.keys(returnItem).length > 0 && allLocations.length > 0) {
            let cartLocationDetails = allLocations.filter(
                (inner) => inner.id == returnItem.location_id,
            )
            setCartLocation(cartLocationDetails[0])
        }
    }, [returnItem, allLocations])

    useEffect(() => {
        setAvailAppoints(
            available_appointments.records.length ? available_appointments.records : [],
        )
    }, [available_appointments])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'returnItem') {
                setAlert(responseStatus.message, responseStatus.status)
                clearResponse()
                handleRedirectInternal(history, 'returns')
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (responseStatusPayment) {
            if (responseStatusPayment.from === 'payment') {
                setAlert(responseStatusPayment.message, responseStatusPayment.status)
                clearResponsePayment()
                if (responseStatusPayment.status === 'success') {
                    handleRedirectInternal(history, 'returninvoice')
                }
            }
        }
    }, [responseStatusPayment])

    const validationPayment = Yup.object({
        returnTypeId: Yup.number()
            .min(1, 'Please select a reason')
            .required('Please select a reason'),
        note: Yup.string().max(255, 'Max 255 characters').required('This field is required'),
        authorizedPickup: Yup.string().when(['pickupSomeoneElse'], {
            is: (a) => a,
            then: Yup.string().required('This field is required'),
        }),
    })

    const formikPayment = useFormik({
        initialValues: {
            appointmentId: null,
            amount: null,
            returnTypeId: 0,
            appointmentTime: '',
            note: '',
            pickupDay: '',
            pickupTime: '',
            pickup: '',
            pickupSomeoneElse: false,
            authorizedPickup: '',
        },
        validateOnBlur: false,
        validationSchema: validationPayment,
        onSubmit: (values) => {
            console.log('ON SUBMIT')
            formikPayment.setSubmitting(true)
            values.buynowId = returnItem.buynowId
            values.originalAppointmentId = returnItem.appointmentId
            values.locationId = returnItem.location_id
            values.itemId = returnItem.itemId
            values.userId = returnItem.user_id
            if (returnItem.isPaid == 1) {
                values.amount = getTotal(returnItem)
            }
            if (values.pickupDay && values.pickupTime) {
                let pickupChanged = values.pickupDay + ' ' + values.pickupTime
                let pickupLocal = values.pickupTime.split(':')
                let pickupLocalHour = pickupLocal[0]
                let pickupLocalMin = pickupLocal[1]
                values.pickup = moment(pickupChanged, 'MMM Do YYYY HH:mm')
                    .tz(cartLocation.timezone)
                    .set('hour', pickupLocalHour)
                    .set('minute', pickupLocalMin)
                    .format()
            } else {
                if (returnItem.appointmentStatusTypeId == 4) {
                    if (values.pickupDay == '' && !values.appointmentId) {
                        formikPayment.setFieldError(
                            'pickupDay',
                            'Please select the appointment date',
                        )
                        return
                    }
                    if (values.pickupTime == '' && !values.appointmentId) {
                        formikPayment.setFieldError(
                            'pickupTime',
                            'Please select the appointment time',
                        )
                        return
                    }
                }
            }
            console.log('pickup values::: ', values)
            console.log('submitting')
            returnItemHandler(values)
        },
    })

    function intervals(startString, endString) {
        var start = moment(startString, 'YYYY-MM-DD HH:mm')
        var end = moment(endString, 'YYYY-MM-DD HH:mm')
        start.minutes(Math.ceil(start.minutes() / 15) * 15)
        var result = []
        var current = moment(start)
        var timezoneAbbr = moment.tz(cartLocation.timezone).format('z')
        // initialize pickup time
        formikPayment.values.pickupTime = moment(current).format('HH:mm')
        while (current < end) {
            result.push({
                value: moment(current).format('HH:mm'),
                show: `${current.format('h:mm a')} ${timezoneAbbr}`,
            })
            current.add(15, 'minutes')
        }
        return result
    }

    useEffect(() => {
        const selectedDate = moment(formikPayment.values.pickupDay, 'MMM Do YYYY').format()
        let weekDate = parseInt(moment(selectedDate).format('d'))
        let todayWeekDate = parseInt(moment().format('d'))
        let selectedWeekDay = dataArray.filter((x) => x.date === weekDate)
        console.log('weekDate: ', weekDate)
        if (weekDate >= 0) {
            if (selectedWeekDay.length > 0) {
                if (selectedWeekDay[0].minTime && selectedWeekDay[0].maxTime) {
                    let dateSelected = moment(formikPayment.values.pickupDay, 'MMM Do YYYY').format(
                        'YYYY-MM-DD',
                    )
                    let timeMinimum = moment(selectedWeekDay[0].minTime, 'HH').format('HH:mm')
                    if (todayWeekDate === weekDate) {
                        timeMinimum = moment().tz(cartLocation.timezone).format('HH:mm')
                    }
                    let timeMaximum = moment(selectedWeekDay[0].maxTime, 'HH').format('HH:mm')
                    timeMinimum = moment(dateSelected + ' ' + timeMinimum).format()
                    timeMaximum = moment(dateSelected + ' ' + timeMaximum).format()
                    const changeData = intervals(timeMinimum, timeMaximum)
                    setAllAvailableTime(changeData)
                } else {
                    setAllAvailableTime([{ value: '', show: `No Timeslots Available` }])
                }
            } else {
                setAllAvailableTime([{ value: '', show: `No Timeslots Available` }])
            }
        } else {
            setAllAvailableTime([{ value: '', show: `Select Date` }])
        }
    }, [formikPayment.values.pickupDay])

    const confirmAppointment = (data) => {
        formikPayment.setFieldValue('appointmentId', data.id)
        formikPayment.setFieldValue('appointmentTime', data.appointmentTime)
    }
    const removeAppointment = () => {
        formikPayment.setFieldValue('appointmentId', null)
        formikPayment.setFieldValue('appointmentTime', '')
    }

    const getTotal = (item) => {
        return (
            item.amount +
            item.amount * (item.buyer_premium_percent / 100) +
            item.amount * (item.tax_percent / 100)
        )
    }
    return (
        <div className="checkout customContainer">
            <Breadcrumbs aria-label="breadcrumb" className="naBreadcrumbs">
                <Link to="/returns">Returns</Link>
                <Typography color="textPrimary">Checkout</Typography>
            </Breadcrumbs>

            {availAppoints.length > 0 &&
                !formikPayment.values.appointmentId &&
                returnItem.appointmentStatusTypeId == 4 &&
                availAppoints.map((data, index) => (
                    <div
                        className="existingAppointmentBanner d-flex justify-content-between align-items-center flex-wrap"
                        key={index}
                    >
                        <span className="material-icons">restore</span>
                        <div>
                            <h2>
                                You have an existing appointment at{' '}
                                {dateTimeFormatFunction(data.appointmentTime)}.
                            </h2>
                            <h4>You can add items to the same appointment</h4>
                        </div>
                        <PrimaryButton
                            label="Continue"
                            onClick={() => confirmAppointment(data)}
                            btnSize="small"
                        />
                    </div>
                ))}

            <form onSubmit={formikPayment.handleSubmit} autoComplete="nofill">
                <div className="checkoutCnt d-flex justify-content-between">
                    <div className="checkoutLt">
                        {returnItem.appointmentStatusTypeId == 4 ? (
                            formikPayment.values.appointmentId ? (
                                <>
                                    <div className="existingAppointmentBanner d-flex justify-content-between align-items-center flex-wrap">
                                        <span className="material-icons">restore</span>
                                        <div>
                                            <h2>
                                                You have an selected your previous appointment
                                                scheduled at{' '}
                                                {dateTimeFormatFunction(
                                                    formikPayment.values.appointmentTime,
                                                )}
                                                .
                                            </h2>
                                        </div>
                                        <PrimaryButton
                                            label="Remove"
                                            onClick={() => removeAppointment()}
                                            btnSize="small"
                                        />
                                    </div>
                                </>
                            ) : (
                                <div className="schedule">
                                    <h1 className="checkoutHead">Schedule Appointment</h1>
                                    <div className="chCnt d-flex justify-content-start align-items-center">
                                        {appointmentSchedule.map((data, index) => (
                                            <div className="selectDate d-flex justify-content-center align-items-center">
                                                <input
                                                    name="pickupDay"
                                                    value={data.date}
                                                    onChange={formikPayment.handleChange}
                                                    type="radio"
                                                    id={`schedule_${index}`}
                                                    hidden
                                                ></input>
                                                <label htmlFor={`schedule_${index}`}>
                                                    <ListItem button>
                                                        <p>{data.date}</p>
                                                        <span>({data.label})</span>
                                                    </ListItem>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    <p className="scheduleError">
                                        {formikPayment.errors.pickupDay &&
                                            formikPayment.touched.pickupDay &&
                                            formikPayment.errors.pickupDay}
                                    </p>
                                    <div className="selectTime d-flex justify-content-start align-items-center">
                                        <h1 className="checkoutHead subHead">Select a time</h1>
                                        <CustomSelect
                                            label="Select a time"
                                            value={formikPayment.values.pickupTime}
                                            size="small"
                                            name="pickupTime"
                                            shrink={allAvailableTime.length && true}
                                            onChange={formikPayment.handleChange}
                                            error={
                                                formikPayment.touched.pickupTime &&
                                                formikPayment.errors.pickupTime
                                            }
                                            helperText={
                                                formikPayment.errors.pickupTime &&
                                                formikPayment.touched.pickupTime &&
                                                formikPayment.errors.pickupTime
                                            }
                                        >
                                            {allAvailableTime.map((opt, optindex) => (
                                                <option value={opt.value}>{opt.show}</option>
                                            ))}
                                        </CustomSelect>
                                    </div>
                                    <div className="selectTime d-flex justify-content-start align-items-center">
                                        <div>
                                            <h1 className="checkoutHead subHead authorizedPickupLabel">
                                                Authorize someone else to drop it off?
                                            </h1>
                                        </div>
                                        <div>
                                            <CheckBox
                                                name="pickupSomeoneElse"
                                                value={formikPayment.values.pickupSomeoneElse}
                                                checked={formikPayment.values.pickupSomeoneElse}
                                                onChange={formikPayment.handleChange}
                                            />
                                        </div>
                                        {formikPayment.values.pickupSomeoneElse ? (
                                            <div>
                                                <CustomInput
                                                    name="authorizedPickup"
                                                    inputStyle={{ marginBottom: '0px' }}
                                                    value={formikPayment.authorizedPickup}
                                                    onChange={formikPayment.handleChange}
                                                    error={
                                                        formikPayment.touched.authorizedPickup &&
                                                        formikPayment.errors.authorizedPickup
                                                    }
                                                    helperText={
                                                        formikPayment.errors.authorizedPickup &&
                                                        formikPayment.touched.authorizedPickup &&
                                                        formikPayment.errors.authorizedPickup
                                                    }
                                                    placeholder="Enter full name"
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            )
                        ) : null}

                        <div className="payment">
                            <h1 className="checkoutHead">Plese select a reason</h1>
                            <CustomSelect
                                value={formikPayment.values.returnTypeId}
                                size="small"
                                name="returnTypeId"
                                onChange={formikPayment.handleChange}
                                error={
                                    formikPayment.touched.returnTypeId &&
                                    formikPayment.errors.returnTypeId
                                }
                                helperText={
                                    formikPayment.errors.returnTypeId &&
                                    formikPayment.touched.returnTypeId &&
                                    formikPayment.errors.returnTypeId
                                }
                            >
                                <option value={0}>Plese select a reason</option>
                                {returnTypes.items.map((opt, index) => (
                                    <option key={index} value={opt.id}>
                                        {opt.name}
                                    </option>
                                ))}
                            </CustomSelect>
                            <CustomInput
                                name="note"
                                type="text"
                                value={formikPayment.values.note}
                                onChange={formikPayment.handleChange}
                                error={formikPayment.touched.note && formikPayment.errors.note}
                                helperText={
                                    formikPayment.errors.note &&
                                    formikPayment.touched.note &&
                                    formikPayment.errors.note
                                }
                                placeholder="Please describe why you are returning this item"
                            />
                        </div>

                        <div className="review">
                            <div className="d-flex align-items-center mb-3">
                                <h1 className="checkoutHead m-0">You&apos;re Returning</h1>
                            </div>

                            <div className="cartProducts">
                                <div>
                                    <div className="cartItem d-flex justify-content-between align-items-start">
                                        <div className="itemInfo d-flex justify-content-start">
                                            <div className="pclImg">
                                                <img
                                                    src={returnItem.avatarorg}
                                                    alt={returnItem.leadDescription}
                                                />
                                            </div>
                                            <div className="listContent">
                                                <h2 className="listProdTitle">
                                                    {returnItem.leadDescription}
                                                </h2>
                                            </div>
                                        </div>

                                        {/* <div className="listActBtn">
                                            <div className="listBidInfo d-flex justify-content-between align-items-center">
                                                <p className="d-flex justify-content-center align-items-center">
                                                    <span>
                                                        {currencyFormat(getTotal(returnItem))}
                                                    </span>
                                                </p>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* This CTA will be hidden after 1024px */}
                        <div className="checkoutSecAction d-flex justify-content-between align-items-center">
                            <PrimaryButton type="submit" label={'Complete Return'} />
                            <div>
                                <label>Return Total:</label>
                                <h6>
                                    {returnItem.isPaid == 1
                                        ? currencyFormat(getTotal(returnItem))
                                        : currencyFormat(0)}
                                </h6>
                            </div>
                        </div>
                    </div>

                    <div className="checkoutRt">
                        <div className="orderSummary">
                            <PrimaryButton
                                type="submit"
                                btnSize="small"
                                label={'Complete Return'}
                            />
                            <div>
                                <h3>Return Summary</h3>
                                <p>
                                    <label>Total before taxes:</label>
                                    {returnItem.isPaid == 1
                                        ? currencyFormat(returnItem.amount)
                                        : currencyFormat(0)}
                                </p>
                                <p>
                                    <label>Buyer Premium: </label>
                                    {returnItem.isPaid == 1
                                        ? currencyFormat(
                                              returnItem.amount *
                                                  (returnItem.buyer_premium_percent / 100),
                                          )
                                        : currencyFormat(0)}
                                </p>
                                <p>
                                    <label>Estimated taxes: </label>
                                    {returnItem.isPaid == 1
                                        ? currencyFormat(
                                              returnItem.amount * (returnItem.tax_percent / 100),
                                          )
                                        : currencyFormat(0)}
                                </p>
                                <p>
                                    <label>Total:</label>
                                    {returnItem.isPaid == 1
                                        ? currencyFormat(getTotal(returnItem))
                                        : currencyFormat(0)}
                                </p>
                                <hr />
                                <h4>
                                    <label>Return Total:</label>
                                    {returnItem.isPaid == 1
                                        ? currencyFormat(getTotal(returnItem))
                                        : currencyFormat(0)}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Checkout
