import React from 'react'
import './NoRecordsFound.css'
import { useTranslation } from 'react-i18next'
function NoRecordsFound() {
    const { t } = useTranslation()
    return (
        <h4 className="noRecordsFound">
            <span className="material-icons">find_in_page</span>
            {t('No Records Found!')}
        </h4>
    )
}

export default NoRecordsFound
