import React, { useState, useRef, useEffect, useContext } from 'react'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import { useHistory } from 'react-router-dom'

import {
    handleRedirectInternal,
    dateTimeFormatFunction,
    currencyFormat,
} from '@/custom/common/components'
import './InvoiceCard.css'
import { useTranslation } from 'react-i18next'
const InvoiceCard = (props) => {
    const [product, setProduct] = useState()
    const history = useHistory()
    const { t } = useTranslation()
    useEffect(() => {
        setProduct(props.data)
        console.log(product, 'pppp')
    }, [props.data])
    return (
        <>
            <div className="invoiceCard">
                {product ? (
                    <>
                        <h3 className="custName">
                            {t('invoice')} #{product.common_invoice}
                        </h3>
                        {product.paid ? (
                            <>
                                <h5>
                                    {t('paid')}:{' '}
                                    <span dir="ltr">
                                        {dateTimeFormatFunction(product.paid_date)}
                                    </span>
                                </h5>
                            </>
                        ) : (
                            <>
                                <h5>
                                    {t('created')}:{' '}
                                    <span dir="ltr">
                                        {dateTimeFormatFunction(product.date_added)}
                                    </span>
                                </h5>
                            </>
                        )}
                        <div className="icItems d-flex justify-content-between align-items-center">
                            <span>{t('total_items')}:</span> <span>{product.total_items}</span>
                        </div>
                        <div className="icPaid d-flex justify-content-between align-items-center">
                            <span>{t('amount')}:</span>{' '}
                            <span>{currencyFormat(product.total_amount, t)}</span>
                        </div>

                        <div className="icPaid d-flex justify-content-between align-items-center">
                            <span>{t('payment_status')}:</span>{' '}
                            <span>
                                {product.payment_method === 'offline' ? t('offline') : t('online')}
                            </span>
                        </div>

                        {product.partial === 1 && product.payment_method !== 'offline' ? (
                            <>
                                <PrimaryButton
                                    id="pay_invoice"
                                    label={t('pay_invoice')}
                                    onClick={() =>
                                        handleRedirectInternal(
                                            history,
                                            `checkout/invoice/${product.common_invoice}`,
                                        )
                                    }
                                    btnSize="small"
                                />
                                <PrimaryButton
                                    id="view_invoice"
                                    label={t('view_invoice')}
                                    onClick={() =>
                                        handleRedirectInternal(
                                            history,
                                            `invoice/${product.common_invoice}`,
                                        )
                                    }
                                    btnSize="small"
                                />
                            </>
                        ) : (
                            <PrimaryButton
                                id="view_invoice_card"
                                label={t('view_invoice')}
                                onClick={() =>
                                    handleRedirectInternal(
                                        history,
                                        `invoice/${product.common_invoice}`,
                                    )
                                }
                                btnSize="small"
                            />
                        )}
                        {/* {product.delivery_type === 'local_pickup' && product.projects_auction ? (
                            product.status !== 'no_show' ? (
                                <PrimaryButton
                                    label={
                                        product.appointment_id > 0
                                            ? 'Reschedule Appointment'
                                            : 'Book Appointment'
                                    }
                                    onClick={() =>
                                        handleRedirectInternal(
                                            history,
                                            `appointment/schedule/${product.common_invoice}`,
                                        )
                                    }
                                    btnSize="small"
                                />
                            ) : null
                        ) : null} */}
                    </>
                ) : null}
            </div>
        </>
    )
}

export default InvoiceCard
