import React, { useState, useContext, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import { Link, useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import ProfileComponent from '../../product/components/species/dynamic/Profile/ProfileComponent'
import RegisterComponent from '../../product/components/species/dynamic/common/Register/RegisterComponent'

import { useGoogleLogin, GoogleLogin } from '@react-oauth/google'

import './Registration.css'

import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import { mapData, handleRedirectInternal } from '../../product/common/components'
import PhoneValidation from './phoneValidation'

import AuthContext from '../../product/context/auth/authContext'
import AlertContext from '@/product/context/alert/alertContext'
import CommonContext from '../../custom/context/common/commonContext'
import DynamicContext from '@/product/context/dynamic/dynamicContext'
import ProductCommonContext from '../../product/context/common/commonContext'
import { useTranslation } from 'react-i18next'
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginTop: '10px',
        width: '100%',
    },
}))

const getSteps = () => {
    return ['Basic Information', 'Communication Information']
}

const Registration = () => {
    const classes = useStyles()
    const [activeStep, setActiveStep] = React.useState(0)
    const steps = getSteps()
    const history = useHistory()

    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const { USStates } = commonContext

    const productCommonContext = useContext(ProductCommonContext)
    const dynamicContext = useContext(DynamicContext)

    const { getAllDynamicArray, dynamicValue } = dynamicContext
    const { t } = useTranslation()
    const childFunc = useRef(null)
    const [updatedValues, setUpdatedValues] = useState({})
    const [customValidation, setCustomValidation] = useState([])
    const [additionalCustom, setAdditionalCustom] = useState([])

    const [additionalInfo, setAdditionalInfo] = useState([])
    const [customInitialBuyer, setCustomInitialBuyer] = useState([])
    const [customInitialVal, setCustomInitialVal] = useState([])
    const [customInfoValidation, setCustomInfoValidation] = useState([])
    const [fieldValue, setFieldValue] = useState([])
    const [sellerValue, setSellerValue] = useState([])
    const { configFeatures } = commonContext
    const { static_page, getStaticPage, allCountries, allStates } = productCommonContext
    let [termsLink, setTermsLink] = useState([])

    const [phoneVerify, setPhoneVerify] = React.useState(false)
    const fieldArray = ['email', 'confirm_email', 'password', 'confirm_password']
    const SubmitBtnLabel = 'REGISTER'
    const label = 'Submit'
    const changePhoneVerify = () => {
        setPhoneVerify(!phoneVerify)
    }

    const { setAlert } = alertContext
    const { register, checkValidation, responseStatus, clearResponse, isAuthenticated } =
        authContext

    const loaded = React.useRef(false)

    // Loading Google Place API Script
    function loadScript(src, position, id) {
        if (!position) {
            return
        }

        const script = document.createElement('script')
        script.setAttribute('async', '')
        script.setAttribute('id', id)
        script.src = src
        position.appendChild(script)
    }

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            )
        }

        loaded.current = true
    }

    useEffect(() => {
        if (isAuthenticated) {
            handleRedirectInternal(history, '')
        }
    }, [isAuthenticated])

    const validationArray = [
        Yup.object({
            email: Yup.string().email('Invalid email format').required('Required!'),
            confirm_email: Yup.string()
                .email('Invalid email format')
                .oneOf([Yup.ref('email')], "Email's not match")
                .required('Required!'),
            facebook_id: Yup.string(),
            google_id: Yup.string(),
            password: Yup.string().when(['facebook_id', 'google_id'], {
                is: (facebookID, googleID) => !facebookID && !googleID,
                then: Yup.string().min(8, 'Minimum 8 characters').required('Required!'),
            }),
            password_checker: Yup.number().when(['facebook_id', 'google_id'], {
                is: (facebookID, googleID) => !facebookID && !googleID,
                then: Yup.number().moreThan(1, 'Password has to be Fair'),
            }),
            confirm_password: Yup.string().when(['facebook_id', 'google_id'], {
                is: (facebookID, googleID) => !facebookID && !googleID,
                then: Yup.string()
                    .oneOf([Yup.ref('password')], "Passwords don't match")
                    .required('Required!'),
            }),
        }),
        Yup.object({
            first_name: Yup.string()
                .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
                .min(2, 'Mininum 2 characters')
                .max(15, 'Maximum 15 characters')
                .required('Required!'),
            last_name: Yup.string()
                .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
                .min(2, 'Mininum 2 characters')
                .max(15, 'Maximum 15 characters')
                .required('Required!'),
            address1: Yup.string().required('Required!'),
            phone: Yup.string()
                .trim()
                .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
                .min(15, 'Please enter correct phone number')
                .required('Required!'),
            city: Yup.string().required('Required!'),
            state: Yup.string().required('Required!'),
            zip: Yup.string()
                .trim()
                .matches(/^[0-9]*$/g, 'The positive number only')
                .max(6, 'Maximum 6 characters')
                .required('Required'),
        }),
    ]

    useEffect(() => {
        getAllDynamicArray(
            {
                page: 1,
                limit: 20,
                order: 'asc',
                orderby: 'df.show_order',
                table: 'dynamic_fields',
                filters: {
                    action: {
                        value: 3,
                        type: 'in',
                        field: 'df.view_page',
                    },
                    forBuyer: {
                        value: 1,
                        type: 'in',
                        field: 'df.for_buyer',
                    },
                    active: {
                        value: 1,
                        type: 'in',
                        field: 'df.active',
                    },
                },
            },
            'forBuyer',
        )
    }, [])

    const additionalFieldUpdate = (data) => {
        setUpdatedValues(data)
    }

    useEffect(() => {
        let tempArray = []
        let addValues = {}
        if (dynamicValue.from === 'forBuyer') {
            if (dynamicValue.records) {
                tempArray = JSON.parse(JSON.stringify(dynamicValue.records))
            }
        }
        tempArray = tempArray.filter(
            (e) =>
                e.name == 'email' ||
                e.name == 'phone' ||
                e.name == 'password' ||
                e.name == 'confirm_password',
        )

        tempArray.map((ele) => {
            ele.label = t(ele.label)
            ele.placeholder = t(ele.placeholder)
        })

        setFieldValue(tempArray)
    }, [dynamicValue, localStorage.i18nextLng])

    useEffect(() => {
        let customValidationTemp = {}
        let additionalValidation = {}
        let customInitial = {
            phone_verified: 0,
            verified_phonenum: '',
            phone_verifysent: 0,
            verify_code: '',
            country: global.defaultCountry,
            status: 'active',
            admin: 0,
        }
        let customInfoInitial = {
            admin: 0,
        }
        customValidationTemp = {
            email: Yup.string()
                .email(t('Invalid email format'))
                .max(250, t('Maximum 250 Characters'))
                .required(t('Required')),
            password: Yup.string()
                .matches(
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]/,
                    t('Must contain One Uppercase, One Lowercase, One Number'),
                )
                .min(8, t('minimum_8_characters'))
                .required(t('Required')),
            confirm_password: Yup.string()
                .oneOf([Yup.ref('password')], t('passwords_not_match'))
                .required(t('Required')),

            first_name: Yup.string()
                .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
                .min(2, 'Mininum 2 characters')
                .max(15, 'Maximum 15 characters')
                .required('Required!'),
            last_name: Yup.string()
                .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
                .min(2, 'Mininum 2 characters')
                .max(15, 'Maximum 15 characters')
                .required('Required!'),
            phone: Yup.string()
                .trim()
                .matches(/^[0-9- +()]*$/g, t('Only numbers are allowed'))
                .max(17, t('maximum_10_charaters'))
                .min(11, t('Invalid Phone Number'))
                .required(t('Required!')),
            zip: Yup.string()
                .trim()
                .max(6, 'Maximum 6 characters')
                .min(4, 'Minimum 4 characters')
                .required('Required'),
            city: Yup.string()
                .trim()
                .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
                .required('Required'),
        }
        additionalValidation = {}
        setCustomInitialBuyer(customInitial)
        setCustomValidation(customValidationTemp)
        setCustomInitialVal(customInfoInitial)
        setCustomInfoValidation(additionalValidation)
    }, [localStorage.i18nextLng])
    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return Object.values(mapData(basicInfo))
            case 1:
                return Object.values(mapData(communicationInfo))
            default:
                return 'Unknown stepIndex'
        }
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            confirm_email: '',
            password: '',
            confirm_password: '',
            password_checker: 0,
            google_id: '',
            facebook_id: '',
            first_name: '',
            last_name: '',
            address1: '',
            phone: '',
            city: '',
            state: '',
            zip: '',
            phone_verified: 0,
            verified_phonenum: '',
            phone_verifysent: 0,
            verify_code: '',
        },
        onSubmit: (values) => {
            if (activeStep === 0) {
                checkValidation({
                    text: values.email,
                    type: 'email',
                })
            } else {
                if (
                    values.phone_verified === 1 &&
                    formik.values.verified_phonenum === formik.values.phone
                ) {
                    values.phone = values.phone.replace(/[^+0-9]/g, '')
                    register(values)
                } else {
                    setPhoneVerify(true)
                }
            }
        },
    })

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        window.scrollTo(0, 200)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
        window.scrollTo(0, 200)
    }

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'register') {
                // setAlert(responseStatus.message, responseStatus.status)
                clearResponse()
                if (responseStatus.status === 'success') {
                    handleRedirectInternal(history, 'registration/success')
                }
            }
            if (responseStatus.from === 'checkValidation') {
                if (responseStatus.status !== 'success') {
                    setAlert(responseStatus.message, responseStatus.status)
                } else {
                    formik.touched.last_name = false
                    formik.touched.address1 = false
                    formik.touched.phone = false
                    formik.touched.city = false
                    formik.touched.state = false
                    formik.touched.zip = false
                    setActiveStep(1)
                    window.scrollTo(0, 200)
                }
                clearResponse()
            }
        }
    }, [responseStatus])

    const responseFacebook = (response) => {
        formik.values.facebook_id = response.id
        formik.values.email = response.email
        formik.values.confirm_email = response.email
        let user_name = response.name.split(' ')
        if (user_name.length > 1) {
            formik.values.first_name = user_name[0]
            formik.values.last_name = user_name[1]
        } else {
            formik.values.first_name = response.name
        }
        formik.values.password = ''
        formik.values.confirm_password = ''

        checkValidation({
            text: response.email,
            type: 'email',
        })
    }

    const onSuccess = (data) => {
        // setSignupModal(false)
    }

    const responseGoogle = (response) => {
        formik.values.google_id = response.profileObj.googleId
        formik.values.email = response.profileObj.email
        formik.values.confirm_email = response.profileObj.email
        formik.values.first_name = response.profileObj.givenName
        formik.values.last_name = response.profileObj.familyName
            ? response.profileObj.familyName
            : ''
        formik.values.password = response.clientId
        formik.values.confirm_password = response.clientId
        checkValidation({
            text: response.profileObj.email,
            type: 'email',
        })
    }

    return (
        <div className="registration">
            <div className="regBox container">
                <h1>{t('welcome_to_naamm')}</h1>
                {/* <h2>The Fastest Growing Online Auction Site In North America.</h2> */}
                {activeStep === 0 ? (
                    <>
                        <div className="socialButtons d-flex justify-content-between align-items-center">
                            {/* <GoogleLogin
                                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={'single_host_origin'}
                                render={(renderProps) => (
                                    <Button
                                        variant="outlined"
                                        onClick={renderProps.onClick}
                                        disabled={renderProps.disabled}
                                        color="default"
                                    >
                                        <object
                                            aria-label="Google Signin"
                                            data="/assets/svg/google.svg"
                                            type="image/svg+xml"
                                        ></object>
                                        Sign up with Google
                                    </Button>
                                )}
                            /> */}
                            {/* 
                            <GoogleLogin
                                onSuccess={(credentialResponse) => {
                                    responseGoogle(credentialResponse)
                                }}
                                onError={() => {
                                    console.log('Login Failed')
                                }}
                            />

                            <FacebookLogin
                                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                fields="name,email"
                                callback={responseFacebook}
                                render={(renderProps) => (
                                    <Button
                                        variant="outlined"
                                        onClick={renderProps.onClick}
                                        color="default"
                                    >
                                        <object
                                            aria-label="Facebook Signin"
                                            data="/assets/svg/facebook.svg"
                                            type="image/svg+xml"
                                        ></object>
                                        Sign up with Facebook
                                    </Button>
                                )}
                            /> */}
                        </div>
                        <p className="registrationDivider"></p>
                    </>
                ) : null}

                <div className={classes.root}>
                    <div>
                        <RegisterComponent
                            fieldValue={fieldValue}
                            customInitial={customInitialBuyer}
                            customValidation={customValidation}
                            additionalFieldUpdate={additionalFieldUpdate}
                            SubmitBtnLabel={t('register')}
                            // phoneVerify={true}
                            autoRegisterAfterVerify={true}
                            onSuccess={onSuccess}
                            googleSignUp={true}
                            // facebookSignUp={true}
                            selectVerificationType={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Registration
