import React, { useState, useEffect, useContext } from 'react'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Link } from 'react-router-dom'
import { ListItem } from '@material-ui/core'
import CustomCommonContext from '@/custom/context/common/commonContext'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import i18next from 'i18next'

const LanguageSwitch = (props) => {
    const customCommonContext = useContext(CustomCommonContext)
    const { languages, currentLanguage, switchLanguage } = customCommonContext

    const [anchorEl, setAnchorEl] = useState(null)
    const [language, setLanguage] = useState({
        id: 0,
        label: (
            <>
                {props.text || 'Language'}
                {props.icon == false ? null : <span className="material-icons">translate</span>}
            </>
        ),
        // img: '/assets/svg/usFlag.svg',
        code: 'for_english',
    })
    const [languageArray, setLanguageArray] = useState([])
    const { i18n, t } = useTranslation()
    const theme = useTheme()

    const toCab = (data) => data.toString().charAt(0).toUpperCase() + data.slice(1)
    useEffect(() => {
        let temp = []
        if (languages)
            languages.forEach((element, index) => {
                let flagDetails = '/assets/svg/usFlag.svg'
                if (element === 'for_arabic') {
                    flagDetails = '/assets/images/saudi_flag.png'
                }
                temp.push({
                    id: index,
                    label: toCab(element.replace('for_', '')),
                    img: flagDetails,
                    code: element,
                })
            })

        setLanguageArray(temp)
    }, [languages])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (event) => {
        setAnchorEl(null)
    }

    useEffect(() => {
        if (currentLanguage == 'for_arabic') {
            document.body.classList.add('arabic')
        } else {
            document.body.classList.remove('arabic')
        }

        return () => {
            document.body.classList.remove('arabic')
        }
    }, [currentLanguage])

    const changeLanguage = (data) => {
        let val = {
            id: data.id,
            label: t(data.label),
            img: data.img,
            code: data.code,
        }
        setLanguage(val)
        localStorage.setItem('currentLanguage', JSON.stringify(val))
        i18n.changeLanguage(data.code)
        switchLanguage(data.code)
        if (data.code === 'for_arabic') {
            document.body.dir = 'rtl'
            theme.direction = 'rtl'
        } else {
            document.body.dir = 'ltr'
            theme.direction = 'ltr'
        }
        handleClose()
    }
    useEffect(() => {
        let storedValue = localStorage.getItem('currentLanguage')
        if (storedValue) {
            storedValue = JSON.parse(storedValue)
            changeLanguage(storedValue)
        } else {
            let data = {
                id: 1,
                code: 'for_arabic',
                img: '/assets/images/saudi_flag.png',
                label: 'Arabic',
            }
            changeLanguage(data)
        }
    }, [languageArray])

    return props.isMobile ? (
        <div className="languageSwitch mobile">
            {languageArray
                .filter((x) => x.id !== language.id)
                .map((data, index) => (
                    <ListItem
                        key={index}
                        className="languageMenu"
                        onClick={() => changeLanguage(data)}
                    >
                        <img src={data.img}></img>
                        Switch to {data.label}
                    </ListItem>
                ))}
        </div>
    ) : (
        <div className="languageSwitch">
            <Link to="#" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                {language.label}
                {language.img ? <img src={language.img} /> : ''}
            </Link>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                className={`langDropMenu ${document.body.dir === 'rtl' && 'rtl'}`}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {languageArray
                    .filter((x) => x.id !== language.id)
                    .map((data, index) => (
                        <ListItem
                            button
                            key={index}
                            className="languageMenu"
                            onClick={() => changeLanguage(data)}
                        >
                            <img src={data.img}></img>
                            {console.log('11111', data.label)}
                            {t(data.label)}
                        </ListItem>
                    ))}
            </Menu>
        </div>
    )
}
export default LanguageSwitch
