import React from 'react'
import Slider from 'react-slick'
import Skeletons from '../../custom/components/Skeletons/'

function HomeSkeleton() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 3000,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1366,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    }

    return (
        <div>
            <Slider {...settings}>
                {[...Array(4)].map(() => (
                    <div>
                        <Skeletons type="gridImage" />
                        <Skeletons type="title" />
                        <Skeletons type="text" />
                        <Skeletons type="text" />
                        <Skeletons type="actionButton" />
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default HomeSkeleton
