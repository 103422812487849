import React, { useState, useContext, useEffect, useRef } from 'react'

import CustomCommonContext from '../../custom/context/common/commonContext'
import DynamicContext from '@/product/context/dynamic/dynamicContext'
import { dateFormatFront, currencyFormat } from '@/custom/common/components'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import { useTranslation } from 'react-i18next'
function AuctionView(props) {
    const dynamicContext = useContext(DynamicContext)
    const customCommonContext = useContext(CustomCommonContext)
    const { alldata_all_dropdown } = customCommonContext
    const { getAllDynamicArray, dynamicValue } = dynamicContext

    const [dynamicArray, setDynamicArray] = useState([])
    const [auctionDetails, setAuctionDetails] = useState({})
    const [arrayValue, setArrayValue] = useState([])
    const { t } = useTranslation()
    useEffect(() => {
        setAuctionDetails(props.auctionDetails)
    }, [props.auctionDetails])

    useEffect(() => {
        getAllDynamicArray(
            {
                page: 1,
                limit: 20,
                order: '',
                orderby: '',
                table: 'dynamic_fields',
                filters: {
                    action: {
                        value: '0',
                        type: 'in',
                        field: 'df.is_default',
                    },
                    fields: {
                        value: '2',
                        type: 'in',
                        field: 'df.view_page',
                    },
                    active: {
                        value: '1',
                        type: 'in',
                        field: 'df.active',
                    },
                },
            },
            'AuctionDynamic',
        )
    }, [])
    useEffect(() => {
        if (dynamicValue && dynamicValue.from === 'AuctionDynamic') {
            if (dynamicValue.records.length) {
                setDynamicArray(dynamicValue.records)
                props.updateLoading(false)
            }
        }
    }, [dynamicValue])

    useEffect(() => {
        let ignoreArray = []
        if (props.ignoreArray) {
            ignoreArray = props.ignoreArray
        }
        if (dynamicArray.length) {
            let data = dynamicArray.filter(
                (data) =>
                    data.name != 'arabic_title' &&
                    data.name != 'bid_type' &&
                    data.type != 'imagewithurl' &&
                    data.type != 'misc' &&
                    data.name != 'date_added' &&
                    data.name != 'date_closed' &&
                    data.name != 'location' &&
                    data.name != 'location_id' &&
                    data.name != 'avatar' &&
                    data.name != 'title' &&
                    data.name != 'project_id' &&
                    data.name != 'keyword' &&
                    data.name != 'local_pickup_check' &&
                    data.name != 'local_pickup_dates' &&
                    data.name != 'local_pickup_start_hour' &&
                    data.name != 'local_pickup_end_hour' &&
                    data.name != 'local_pickup_slot_timeframe' &&
                    data.name != 'local_pickup_appt_per_slot' &&
                    data.name != 'ship_service' &&
                    data.name != 'station_warehouse' &&
                    data.name != 'station_package_code' &&
                    data.name != 'auction_address' &&
                    data.name != 'auction_city' &&
                    data.name != 'auction_state' &&
                    data.name != 'auction_country' &&
                    data.name != 'auction_zipcode' &&
                    data.name != 'auction_phone_no' &&
                    data.name != 'live_auction' &&
                    data.name != 'timed_auction' &&
                    data.name != 'bid_retract_check' &&
                    data.name != 'bid_retract_minutes' &&
                    data.name != 'bid_retract_minutes' &&
                    data.name != 'bid_retract_max_count' &&
                    data.name != 'desc_proc_arabic' &&
                    data.is_default === 0 &&
                    !ignoreArray.includes(data.name),
            )
            setArrayValue(data)
        }
    }, [dynamicArray, props.ignoreArray])
    useEffect(() => {
        if (arrayValue.length) {
            props.updateLoading(false)
        }
    }, [arrayValue])
    console.log(arrayValue, 'arrrr')

    return (
        <>
            {arrayValue.map((data, index) => {
                let temp
                let bidType
                if (data.type === 'select') {
                    temp = alldata_all_dropdown?.filter((item) => {
                        return item.id == auctionDetails[data.name]
                    })
                }

                return data.type === 'select' && data.name != 'bid_type' ? (
                    <div className="acMiscInfo">
                        <p>{t(data.label)}:</p>
                        <span>
                            {temp.length &&
                            localStorage.i18nextLng === 'for_arabic' &&
                            temp[0]?.[localStorage.i18nextLng]
                                ? temp[0]?.[localStorage.i18nextLng]
                                : t(temp[0]?.description)}
                        </span>
                    </div>
                ) : data.type === 'checkbox' ? (
                    <div className="acMiscInfo">
                        <p>{t(data.label)}:</p>
                        {auctionDetails[data.name] ? <span>{'Yes'}</span> : <span>{'No'}</span>}
                    </div>
                ) : data.type === 'radio' ? (
                    <div className="acMiscInfo">
                        <p>{t(data.label)}:</p>

                        <span>
                            {auctionDetails[data.name]?.split(',')?.map((data, index) => (
                                <>
                                    {
                                        alldata_all_dropdown[
                                            alldata_all_dropdown.findIndex((ele) => ele.id == data)
                                        ]?.description
                                    }
                                </>
                            ))}
                        </span>
                    </div>
                ) : data.type === 'checkboxarray' ? (
                    <div className="acMiscInfo">
                        <p>{t(data.label)}:</p>

                        {/* {console.log(alldata_all_dropdown, 'datass')} */}

                        <span>
                            {auctionDetails[data.name]?.split(',')?.map((data, index) => (
                                <>
                                    {
                                        alldata_all_dropdown[
                                            alldata_all_dropdown.findIndex((ele) => ele.id == data)
                                        ]?.description
                                    }
                                </>
                            ))}
                        </span>
                    </div>
                ) : data.type === 'ckeditor' ? (
                    <div className="acMiscInfo">
                        <p>{t(data.label)}:</p>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: auctionDetails[data.name],
                            }}
                        ></span>
                    </div>
                ) : data.type === 'uploadDropZone' ? (
                    <div className="acMiscInfo">
                        <p>{t(data.label)}:</p>

                        <img
                            src={
                                global.site_url +
                                `/uploads/${data.folder}/` +
                                auctionDetails[data.name]
                            }
                        />
                    </div>
                ) : data.type === 'uploadDropZone' ? (
                    <div className="pvDescChild">
                        <label>{t(data.label)}:</label>

                        <img
                            src={global.site_url + `/uploads/${data.folder}/` + product[data.name]}
                        />
                    </div>
                ) : data.type === 'datetime' ? (
                    <div className="acMiscInfo">
                        <p>{t(data.label)}:</p>
                        <span>{dateFormatFront(auctionDetails[data.name])}</span>
                    </div>
                ) : data.type === 'switch' ? (
                    <div className="acMiscInfo">
                        <p>{t(data.label)}:</p>
                        <span>{auctionDetails[data.name] === 1 ? <h6>Yes</h6> : <h6>No</h6>}</span>
                    </div>
                ) : (
                    <div className="acMiscInfo">
                        <p>{t(data.label)}:</p>
                        <span>
                            {auctionDetails[data.name] ? (
                                <span>
                                    {data.name == 'bid_type' && auctionDetails[data.name] == 'both'
                                        ? 'Hard/Proxy'
                                        : auctionDetails[data.name]}
                                </span>
                            ) : (
                                ''
                            )}
                        </span>
                    </div>
                )
            })}
        </>
    )
}

export default AuctionView
