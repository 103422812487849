import React, { useState, useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import openSocket from 'socket.io-client'
// import FilterPanel from '../../custom/components/organisms/FilterPanel'
import FilterPanel from '@/product/components/species/dynamic/common/FilterPanel/Filter'
import GridView from '../../custom/components/molecules/ProductCard/GridView'
import './Search.css'
import CustomInput from '../../custom/components/atoms/Inputs/CustomInput'
import CustomSelect from '../../custom/components/atoms/Inputs/CustomSelect'
import { Button, ButtonBase, TextField } from '@material-ui/core'
import ListView from '../../custom/components/molecules/ProductCard/ListView'
import Drawer from '@material-ui/core/Drawer'
import ProductView from '../../custom/components/organisms/ProductViewComponent'
import ProductContext from '../../product/context/product/productContext'
import AuthContext from '../../product/context/auth/authContext'
import AlertContext from '../../product/context/alert/alertContext'
import CommonContext from '../../custom/context/common/commonContext'

import { Pagination } from '@material-ui/lab'
import { useCookies } from 'react-cookie'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { useFormik } from 'formik'
import { messageHandler } from '@/product/common/socketHandler'
import SearchSkeleton from './SearchSkeleton'
import DynamicContext from '@/product/context/dynamic/dynamicContext'
import { initialSearchFunction } from '@/product/components/species/dynamic/common/FieldFunction'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const Search = () => {
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const dynamicContext = useContext(DynamicContext)
    const { t } = useTranslation()
    const { getAllDynamicArray, dynamicValue } = dynamicContext
    const { searchValue, setSearchValue, clearSearchValue, currentLocation, alldata_all_dropdown } =
        commonContext

    const classes = useStyles()

    const { search_allproducts, getAllSearchProducts, responseStatus, addSavedSearch } =
        productContext
    const { user } = authContext
    const { setAlert } = alertContext

    const [auctionView, setAuctionView] = useState('Grid')
    const [loading, setLoading] = useState(true)
    const [heading, setHeading] = useState('')
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })

    const isMobile = useMediaQuery({ maxWidth: 600 })

    const isTablet = useMediaQuery({ minWidth: 767, maxWidth: 1024 })

    let [viewProduct, setViewProduct] = useState([])
    const [dynamicArray, setDynamicArray] = useState([])
    const [arrayValue, setArrayValue] = useState([])
    const [initialValues, setInitialValues] = useState({})
    const [initialEmptyValues, setInitialEmptyValues] = useState({})
    const [update, setUpdate] = useState({})
    const [show, setShow] = useState(false)
    const [initialUpdate, setInitialUpdate] = useState(false)
    const [reload, setReload] = useState(false)

    const pageOptions = [
        {
            value: '12',
            show: t('results_12_per_page'),
        },
        {
            value: '24',
            show: t('results_24_per_page'),
        },
        {
            value: '36',
            show: t('results_36_per_page'),
        },
    ]

    const sortShow = [
        {
            value: 'p.id, desc',
            show: t('Relevant'),
        },
        {
            value: 'p.wprice,asc',
            show: t('Current Price (Low - High)'),
        },
        {
            value: 'p.wprice,desc',
            show: t('Current Price (High - Low)'),
        },
        {
            value: 'p.date_closed,asc',
            show: t('time_remaining'),
        },
        {
            value: 1,
            show: t('ending_today'),
        },
        {
            value: 'it.retail_price,asc',
            show: t('Retail Price (Low - High)'),
        },
        {
            value: 'it.retail_price,desc',
            show: t('Retail Price (High - Low)'),
        },
        {
            value: 'bid_count,asc',
            show: t('Bid Count (Low - High)'),
        },
        {
            value: 'bid_count,desc',
            show: t('Bid Count (High - Low)'),
        },
    ]

    const formik = useFormik({
        initialValues: {
            searchbar: '',
        },
    })

    const formikSearch = useFormik({
        initialValues: {
            limit: 12,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            auctionView: 'Grid',
            filters: initialValues,
        },
        enableReinitialize: true,
    })

    useEffect(() => {
        setInitialValues({})
        setInitialEmptyValues({})
        setInitialUpdate(false)

        if (arrayValue.length) {
            let mapValueArray = arrayValue

            let show = initialSearchFunction(mapValueArray)
            console.log('filter=====>', show)
            setInitialValues({
                ...show,
                min_price: {
                    value: 0,
                    type: 'greaterequal',
                    field: 'p.wprice',
                },
                max_price: {
                    value: 99999999,
                    type: 'smallerequal',
                    field: 'p.wprice',
                },
            })
            setInitialEmptyValues({
                ...show,
                min_price: {
                    value: 0,
                    type: 'greaterequal',
                    field: 'p.wprice',
                },
                max_price: {
                    value: 99999999,
                    type: 'smallerequal',
                    field: 'p.wprice',
                },
            })
            setTimeout(() => {
                if (Object.keys(show).length) {
                    setInitialUpdate(true)
                    setReload(!reload)
                }
            }, 1000)
        } else {
            let custom = {}
            setInitialValues(custom)
            setInitialEmptyValues(custom)
            setTimeout(() => {
                if (Object.keys(custom).length) {
                    setInitialUpdate(true)
                    setReload(!reload)
                }
            }, 1000)
        }
    }, [arrayValue])

    const initialSearchValue = {
        limit: 12,
        page: 1,
        orderby: 'p.id, desc',
        order: '',
        filters: {
            category: {
                value:
                    searchValue &&
                    searchValue.filters &&
                    typeof searchValue.filters.category !== 'undefined'
                        ? searchValue.filters.category.value
                        : [],
                type: 'array',
                field: 'it.category',
            },
            condition: {
                value: [],
                type: 'array',
                field: 'it.conditionTypeId',
            },
            location: {
                value: [],
                type: 'array',
                field: 'p.location_id',
            },
            price: {
                value:
                    searchValue != null &&
                    searchValue.filter &&
                    searchValue.filters.price.value !== ''
                        ? searchValue.filters.price.value
                        : 0,
                type: 'range',
                field: 'p.wprice',
            },
            searchbar: {
                value:
                    searchValue &&
                    searchValue.filters &&
                    typeof searchValue.filters.searchbar !== 'undefined'
                        ? searchValue.filters.searchbar.value
                        : '',
                type: 'like',
                field: 'p.title,p.desc_proc',
            },
            auctionid: {
                value: [],
                type: 'array',
                field: 'p.auctionid',
            },
            starRating: {
                value:
                    searchValue &&
                    searchValue.filters &&
                    typeof searchValue.filters.starRating !== 'undefined'
                        ? searchValue.filters.starRating.value
                        : [],
                type: 'array',
                field: 'it.starRating',
            },
            currentLocation: {
                // state: currentLocation.state,
                // city: currentLocation.city,
            },
        },
    }

    const [search, setSearch] = useState(initialSearchValue)
    const [auctionTitle, setAuctionTitle] = useState(null)
    const searchRef = useRef(search)

    const clearSearchFilter = () => {
        formikSearch.values = {
            ...formikSearch.values,
            limit: 12,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: initialEmptyValues,
        }

        formikSearch.handleReset()
        setUpdate(formikSearch)
        setSearch(initialSearchValue)
        console.log(formikSearch.values, 'filterrr')
        setShow(true)
    }

    useEffect(() => {
        getAllDynamicArray(
            {
                page: 1,
                limit: 20,
                order: '',
                orderby: '',
                table: 'dynamic_fields',
                filters: {
                    fields: {
                        value: '1',
                        type: 'in',
                        field: 'df.view_page',
                    },
                    search: {
                        value: '1',
                        type: 'in',
                        field: 'df.search',
                    },
                    active: {
                        value: '1',
                        type: 'in',
                        field: 'df.active',
                    },
                },
            },
            'searchDynamic',
        )
    }, [])

    useEffect(() => {
        if (dynamicValue.from === 'searchDynamic') {
            if (dynamicValue.records.length) {
                // console.log('dynamicval========>', dynamicValue.records)
                setDynamicArray(dynamicValue.records)
            }
        }
    }, [dynamicValue])

    useEffect(() => {
        if (dynamicArray.length) {
            let arrayAdded = JSON.parse(JSON.stringify(dynamicArray))
            let sortedArray = arrayAdded.sort((a, b) => (a.filter_order > b.filter_order ? 1 : -1))
            sortedArray.map((ele) => {
                if (ele.name === 'buynow') {
                    ele.label = t('buy_now')
                    ele.placeholder = t('buy_now')
                } else {
                    ele.label = t(ele.label)
                    ele.placeholder = t(ele.placeholder)
                }
            })
            console.log(sortedArray, 'arrayass=====>')
            setArrayValue([
                ...sortedArray,
                {
                    label: t('price'),
                    type: 'number',
                    is_default: 1,
                    filter_order: 5,
                    filter_type: 'price',
                },
            ])
        }
    }, [dynamicArray, localStorage.i18nextLng])

    useEffect(() => {
        // console.log('data===========>', formikSearch.values)
        // getAllSearchProducts(formikSearch.values)
        console.log(search, formikSearch.values.filters, 'formik filtertt')
        setSearch({
            ...search,
            page: 1,
            filters: {
                ...search.filters,
                ...formikSearch.values.filters,
            },
        })
    }, [formikSearch.values])

    // useEffect(() => {
    //     if (formikSearch.values?.filters?.category?.value) {
    //         console.log(
    //             formikSearch.values?.filters?.category?.value,
    //             search.filters?.category?.value,
    //             'search.values?.filters?.category?.value',
    //         )
    //         if (search.filters?.category?.value.length) {
    //             formikSearch.values.filters.category.value = search.filters.category.value
    //         }

    //         // if (
    //         //     formikSearch.values.filters.category.value &&
    //         //     formikSearch.values.filters.category.value !== search.filters.category.value
    //         // ) {
    //         //     formikSearch.values.filters.category.value = search.filters.category.value
    //         // }
    //     }
    // }, [formikSearch.values?.filters?.category?.value])

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setSearch({
                ...search,
                filters: {
                    ...search.filters,
                    searchbar: {
                        ...search.filters.searchbar,
                        value: formik.values.searchbar,
                    },
                },
            })
        }
    }

    useEffect(() => {
        if (searchValue && formikSearch.values?.filters?.category) {
            console.log(search, 'searchValuesd', searchValue)
            if (
                searchValue.filters.category &&
                searchValue.filters.category.value &&
                searchValue.filters.category.value.length > 0
            ) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        category: {
                            ...search.filters.category,
                            value: searchValue.filters.category.value,
                        },
                    },
                })
                formikSearch.setFieldValue(
                    `filters.category.value`,
                    searchValue.filters.category.value,
                )
                console.log(search, 'searchValuesd2333', searchValue)
            } else if (
                searchValue.filters.condition &&
                searchValue.filters.condition.value &&
                searchValue.filters.condition.value.length > 0
            ) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        condition: {
                            ...search.filters.condition,
                            value: searchValue.filters.condition.value,
                        },
                    },
                })
            } else if (
                searchValue.filters.location &&
                searchValue.filters.location.value &&
                searchValue.filters.location.value.length > 0
            ) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        location: {
                            ...search.filters.location,
                            value: searchValue.filters.location.value,
                        },
                    },
                })
            } else if (searchValue.filters.price && searchValue.filters.price.value) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        price: {
                            ...search.filters.price,
                            value: searchValue.filters.price.value,
                        },
                    },
                })
            } else if (
                searchValue.filters.auctionid &&
                searchValue.filters.auctionid.value &&
                searchValue.filters.auctionid.value.length > 0
            ) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        auctionid: {
                            ...search.filters.auctionid,
                            value: searchValue.filters.auctionid.value,
                        },
                    },
                })
            } else if (
                searchValue.filters.searchbar &&
                searchValue.filters.searchbar.value !== null
            ) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        searchbar: {
                            ...search.filters.searchbar,
                            value: searchValue.filters.searchbar.value,
                        },
                    },
                })
                formik.values.searchbar = searchValue.filters.searchbar.value
            } else if (
                searchValue.filters.starRating &&
                searchValue.filters.starRating.value &&
                searchValue.filters.starRating.value.length > 0
            ) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        starRating: {
                            ...search.filters.starRating,
                            value: searchValue.filters.starRating.value,
                        },
                    },
                })
                formik.values.starRating = searchValue.filters.starRating.value
            }

            if (
                searchValue.filters.auctionid &&
                typeof searchValue.filters.auctionid.title !== 'undefined'
            ) {
                setAuctionTitle(searchValue.filters.auctionid.title)
            }
            clearSearchValue()
            setReload(!reload)
        }
    }, [searchValue, formikSearch.values?.filters])

    useEffect(() => {
        setLoading(true)
        // if (searchValue) {
        //     clearSearchValue()
        // }
        console.log(search, 'serrrr')
        //     if (
        //         search.filters.category.value.length > 0 ||
        //         search.filters.condition.value.length > 0 ||
        //         search.filters.location.value.length > 0 ||
        //         search.filters.auctionid.value.length > 0 ||
        //         search.filters.searchbar.value !== '' ||
        //         search.filters.price.value !== ''
        //     ) {
        //         getAllSearchProducts(search)
        //     }
        // } else {
        const fsearch = search
        if (searchValue) {
            fsearch.page = 1
        }
        getAllSearchProducts(fsearch)
        // }
    }, [search])

    const locationInitialLoad = useRef(true)
    useEffect(() => {
        if (locationInitialLoad.current == true) {
            locationInitialLoad.current = false
        } else {
            let searchFilter = { ...search }
            searchFilter.filters.currentLocation = {
                // state: currentLocation.state,
                // city: currentLocation.city,
            }
            setSearch(searchFilter)
        }
    }, [currentLocation])

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }
    const initialLoad = useRef(true)

    useEffect(() => {
        if (!initialLoad.current) {
            setLoading(false)
            setViewProduct(search_allproducts.records.length ? search_allproducts.records : [])
        } else {
            initialLoad.current = false
        }
    }, [search_allproducts])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
        searchRef.current = search
    })

    const handler = (message, type) => {
        if (type === 'productPosted' || type === 'productClosed') {
            setLoading(true)
            getAllSearchProducts(searchRef.current)
        } else {
            messageHandler(
                message,
                viewProductRef.current,
                userRef.current,
                setAlert,
                setViewProduct,
                type,
            )
        }
    }

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'watchlist') {
                handler(
                    { id: responseStatus.data.project_id, status: responseStatus.data.status },
                    'watchlistAdded',
                )
            }
        }
    }, [responseStatus])

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, { transports: ['websocket'] })
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        socket.on('productPosted', (data) => {
            handler(data, 'productPosted')
        })
        socket.on('productClosed', (data) => {
            handler(data, 'productClosed')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.off('productPosted', (data) => {
                handler(data, 'productPosted')
            })
            socket.off('productClosed', (data) => {
                handler(data, 'productClosed')
            })
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        if (state.right) {
            const index = viewProduct.findIndex((s) => s.id == parseInt(state.data.id, 10))
            if (index !== -1) {
                setState({ ...state, data: viewProduct[index] })
            }
        }
    }, [viewProduct])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    // const clearSearchFilter = () => {
    //     setSearch({
    //         ...search,
    //         limit: 120,
    //         page: 1,
    //         orderby: '(p.score_proj + p.score_price) / 2, desc',
    //         order: '',
    //         filters: {
    //             category: {
    //                 ...search.filters.category,
    //                 value: [],
    //             },
    //             condition: {
    //                 ...search.filters.condition,
    //                 value: [],
    //             },
    //             location: {
    //                 ...search.filters.location,
    //                 value: [],
    //             },
    //             price: {
    //                 ...search.filters.price,
    //                 value: '',
    //             },
    //             searchbar: {
    //                 ...search.filters.searchbar,
    //                 value: '',
    //             },
    //             auctionid: {
    //                 ...search.filters.auctionid,
    //                 value: [],
    //             },
    //             starRating: {
    //                 ...search.filters.starRating,
    //                 value: [],
    //             },
    //             currentLocation: {
    //                 // city: currentLocation.city,
    //                 // state: currentLocation.state,
    //             },
    //         },
    //     })
    //     setSearchValue({
    //         filters: {
    //             category: {
    //                 value: [],
    //             },
    //             searchbar: {
    //                 value: '',
    //             },
    //         },
    //     })
    //     setAuctionTitle(null)
    //     formik.values.searchbar = ''
    // }

    const addSavedSearchData = (search_text) => {
        addSavedSearch({
            search_text: search_text,
            // city: currentLocation.city,
            // state: currentLocation.state,
        })
    }

    let searchBarValue = searchValue?.filters?.searchbar?.value
        ? searchValue.filters.searchbar.value
        : search.filters.searchbar.value

    const clearSearchText = () => {
        formik.values.searchbar = ''
        setSearch({
            ...search,
            filters: {
                ...search.filters,
                searchbar: {
                    ...search.filters.searchbar,
                    value: '',
                },
            },
        })
    }

    const changePageLimit = (value) => {
        setSearch({
            ...searchRef.current,
            limit: value,
        })
    }

    const changeOrder = (value) => {
        setSearch({
            ...searchRef.current,
            orderby: value,
        })
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [search.page])
    const priceFilter = {
        formik: formikSearch,
        data: [
            {
                label: t('from'),
                type: 'number',
                class: 'col-sm-6 col-12 pl-0 pl-sm-2',
                name: 'min_price',
                placeholder: t('From Price'),
                filter: true,
                size: 'small',
            },
            {
                label: t('to'),
                type: 'number',
                class: 'col-sm-6 col-12 pl-0 pr-0 pl-sm-2 pr-sm-2',
                name: 'max_price',
                placeholder: t('To Price'),
                filter: true,
                size: 'small',
            },
        ],
    }
    const handlePrice = (newValue) => {
        console.log(newValue[0], 'valooo')
        formikSearch.setFieldValue(`filters.min_price.value`, newValue[0])
        formikSearch.setFieldValue(`filters.max_price.value`, newValue[1])
    }
    useEffect(() => {
        if (search.filters.category.value) {
            let data = alldata_all_dropdown.find(
                (ele) => ele.id == search.filters.category.value && ele.variable == 'category',
            )
            console.log(search.filters.category.value, data)
            setHeading(
                localStorage.i18nextLng === 'for_arabic' ? data?.for_arabic : data?.description,
            )
        }
    }, [search.filters.category.value, localStorage.i18nextLng])

    return (
        <div className="search customContainer">
            <h1 className="homeBannerTitleCnt searchBannerTitleCnt homeTopBannerTitleCnt">
                <h5>{heading ? heading : t('Beauty products & perfumes')}</h5>
                <hr />
            </h1>
            <div className="searchCnt d-flex justify-content-start align-items-start">
                <div className="searchLt">
                    {/* <div className="input-group mb-3 searchInput">
                        <CustomInput
                            inputStyle={{
                                backgroundColor: 'white',
                                width: '263px',
                            }}
                            onKeyUp={handleKeyDown}
                            name="searchbar"
                            onChange={formik.handleChange}
                            className="form-control"
                            placeholder="Search"
                            value={formik.values.searchbar}
                            aria-label="Search"
                            aria-describedby="searchIcon"
                            startAdornment={
                                <span className="material-icons" style={{ marginRight: '3px' }}>
                                    search
                                </span>
                            }
                            endAdornment={
                                <a style={{ cursor: 'pointer' }} onClick={clearSearchText}>
                                    <span className="material-icons">close</span>
                                </a>
                            }
                        />
                    </div> */}
                    <p className="searchLtHeading">{t('Find Your Hidden Gems')}</p>
                    <div></div>
                    <CustomSelect
                        label={t('results_per_page')}
                        value={search.limit}
                        size="small"
                        name="limit"
                        shrink={search.limit !== '' ? true : false}
                        onChange={(event, editor) => {
                            setSearch({ ...search, limit: event.target.value, page: 1 })
                        }}
                    >
                        {pageOptions.map((opt, optindex) => (
                            <option value={opt.value}>{opt.show}</option>
                        ))}
                    </CustomSelect>

                    {/* <div className="mt-2 mb-3 gridListToggle">
                        <Button
                            className={auctionView === 'Grid' ? 'active' : ''}
                            onClick={() => setAuctionView('Grid')}
                        >
                            <i className="fas fa-th-large"></i> Grid
                        </Button>
                        <Button
                            className={auctionView === 'List' ? 'active' : ''}
                            onClick={() => setAuctionView('List')}
                        >
                            <i className="fas fa-th-list"></i>List
                        </Button>
                    </div> */}

                    <div className="mb-4">
                        <CustomSelect
                            label={t('sort_by')}
                            value={search.orderby}
                            size="small"
                            name="orderby"
                            shrink={search.orderby !== '' ? true : false}
                            onChange={(event, editor) => {
                                setSearch({ ...search, orderby: event.target.value, page: 1 })
                            }}
                        >
                            {sortShow.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                    </div>
                    {console.log(update, 'arrayvalue')}

                    {isTablet || isMobile ? (
                        <Drawer
                            anchor="bottom"
                            open={state['bottom']}
                            className="responsiveFilterDrawer"
                            onClose={toggleDrawer('bottom', false)}
                            onOpen={toggleDrawer('bottom', true)}
                        >
                            <div
                                className={clsx(classes.list, 'bottom')}
                                role="presentation"
                                // onClick={toggleDrawer('bottom', false)}
                                onKeyDown={toggleDrawer('bottom', false)}
                            >
                                <div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="sbTitle">{t('sort_by')}</h4>
                                        <Button
                                            className="filterClose"
                                            onClick={toggleDrawer('bottom', false)}
                                        >
                                            <span className="material-icons">close</span>
                                        </Button>
                                    </div>
                                    <div className="searchAuctions">
                                        <div className="d-flex justify-content-start align-items-center flex-wrap">
                                            {sortShow.map((opt, optindex) => (
                                                <Button
                                                    className={
                                                        search.orderby === opt.value
                                                            ? 'active'
                                                            : null
                                                    }
                                                    onClick={(event, editor) => {
                                                        changeOrder(opt.value)
                                                    }}
                                                >
                                                    {opt.show}
                                                </Button>
                                            ))}
                                        </div>
                                    </div>
                                    <h4 className="sbTitle">{t('results_per_page')}</h4>
                                    <div className="searchAuctions">
                                        <div className="d-flex justify-content-start align-items-center flex-wrap">
                                            {pageOptions.map((opt, optindex) => (
                                                <>
                                                    <Button
                                                        className={
                                                            search.limit == opt.value
                                                                ? 'active'
                                                                : null
                                                        }
                                                        onClick={(event, editor) => {
                                                            changePageLimit(opt.value)
                                                        }}
                                                    >
                                                        {opt.show}
                                                    </Button>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <FilterPanel
                                    formik={formikSearch}
                                    // activeSortCall={activeSortCall}
                                    clearSearch={clearSearchFilter}
                                    languageChange={true}
                                    from={1}
                                    max_price={99999999}
                                    show={show}
                                    handlePrice={handlePrice}
                                    arrayValue={arrayValue}
                                    update={update}
                                    auctionFilter={true}
                                    priceFilter={priceFilter}
                                    filterName={t('filters')}
                                    clearAll={t('clear_all')}
                                    slider={true}
                                />
                            </div>
                        </Drawer>
                    ) : (
                        <div className="deskFilter">
                            <FilterPanel
                                formik={formikSearch}
                                // activeSortCall={activeSortCall}
                                clearSearch={clearSearchFilter}
                                from={1}
                                slider={true}
                                handlePrice={handlePrice}
                                languageChange={true}
                                show={show}
                                arrayValue={arrayValue}
                                priceFilter={priceFilter}
                                update={update}
                                max_price={99999999}
                                auctionFilter={true}
                                filterName={t('filters')}
                                clearAll={t('clear_all')}
                            />
                        </div>
                    )}
                </div>

                <div className="searchRt">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="sectionTitle w-100">
                            <div className="w-100 d-flex justify-content-between align-items-center">
                                <div>
                                    {/* <h2>{t('search')}</h2> */}
                                    {viewProduct.length ? (
                                        <h6 style={{ fontWeight: '400' }}>
                                            {search_allproducts.totalRecords} {t('results_found')}
                                        </h6>
                                    ) : null}
                                </div>

                                <Button
                                    className="toggleRespDrawer"
                                    variant="outlined"
                                    onClick={toggleDrawer('bottom', true)}
                                >
                                    <span className="material-icons">tune</span>Filters
                                </Button>
                            </div>
                            {searchBarValue && (
                                <div className="savedSearchInfo">
                                    <p>
                                        {t('You have searched for')}{' '}
                                        <span>&quot;{searchBarValue}&quot;</span>
                                    </p>
                                    <Button onClick={() => addSavedSearchData(searchBarValue)}>
                                        <span className="material-icons">bookmark_add</span>
                                        {t('Save this search')}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                    {loading ? (
                        <SearchSkeleton />
                    ) : (
                        <>
                            {viewProduct.length ? (
                                <>
                                    <div className="searchResults">
                                        <Drawer
                                            className="rightDrawer"
                                            anchor={'right'}
                                            open={state['right']}
                                            onClose={toggleDrawer('right', false)}
                                        >
                                            <ProductView
                                                data={state.data}
                                                drawerHandler={toggleDrawer('right', false)}
                                            />
                                        </Drawer>

                                        {viewProduct.map((data, index) => (
                                            <GridView
                                                data={data}
                                                favId={`searchProd_${index}`}
                                                drawerHandler={toggleDrawer('right', true, data)}
                                            />
                                        ))}
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center flex-wrap w-100 mb-3 pagination-wrapper">
                                        <h6>
                                            {t('showing')}
                                            {search_allproducts.setDisp} {t('of')}{' '}
                                            {search_allproducts.totalRecords}
                                        </h6>
                                        <Pagination
                                            count={Math.ceil(
                                                search_allproducts.totalRecords / search.limit,
                                            )}
                                            page={search.page}
                                            onChange={onHandlePage}
                                            siblingCount={3}
                                            showFirstButton
                                            showLastButton
                                            boundaryCount={2}
                                        />
                                    </div>
                                </>
                            ) : (
                                <div className="np-pro-box">
                                    <img src="./assets/svg/noResults.svg" alt="No results image" />
                                    <h5>{t('No Records Found!')}</h5>
                                    <h6>
                                        {t('We couldn&apos;t find what you are looking for.')}
                                        <br />{' '}
                                        {t('You can try changing the filters or come back later.')}
                                    </h6>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>

            {/* PRODUCT VIEW DRAWER */}
        </div>
    )
}
export default Search
