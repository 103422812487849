import React, { useReducer } from 'react'
import MessageContext from './messageContext'
import MessageReducer from './messageReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_FETCH_MESSAGES,
    SEARCH_MESSAGES,
    SEARCH_ARCHIVE_MESSAGES,
    SEARCH_ACTUAL_MESSAGES,
} from './messageTypes'

const MessageState = (props) => {
    const initialState = {
        all_messages: {
            activemsgcount: 0,
            archivemsgcount: 0,
            messagesforusers: [],
        },
        all_project_messages: {
            projectmessages: [],
        },
        all_archive_messages: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        all_actual_messages: {
            actualmessages: [],
        },

        responseStatus: null,
    }

    const [state, dispatch] = useReducer(MessageReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllMessages = async (formData, fromVariable = 'GET_MESSAGES') => {
        try {
            const [res] = await Promise.all([apiCall('post', 'messages', formData, '', 'messages')])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_FETCH_MESSAGES,
                    payload: {
                        ...res.data.data.responseData,
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, fromVariable)
            } else {
                resp.commonErrorResponse(fromVariable)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const searchprojectmessages = async (formData, fromVariable) => {
        const from = fromVariable ? fromVariable : 'project_messages'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'searchprojectmessages', formData, '', 'messages'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SEARCH_MESSAGES,
                    payload: {
                        ...res.data.data.responseData,
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const searchactualmessages = async (formData, fromVariable) => {
        const from = fromVariable ? fromVariable : 'actual_messages'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'searchactualmessages', formData, '', 'messages'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SEARCH_ACTUAL_MESSAGES,
                    payload: {
                        ...res.data.data.responseData,
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const searcharchiveMessages = async (formData, fromVariable) => {
        try {
            const from = fromVariable ? fromVariable : 'archive_messages'
            const [res] = await Promise.all([
                apiCall('post', 'archiveMessages', formData, '', 'messages'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const deleteMessage = async (formData, fromVariable) => {
        try {
            const from = fromVariable ? fromVariable : 'deleteMessages'
            const [res] = await Promise.all([
                apiCall('post', 'deleteMessage', formData, '', 'messages'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const saveMessage = async (formData, fromVariable) => {
        try {
            const from = fromVariable ? fromVariable : 'saveMessage'
            const [res] = await Promise.all([
                apiCall('post', 'saveMessage', formData, '', 'messages'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const saveMessageFromView = async (formData, fromVariable) => {
        try {
            const from = fromVariable ? fromVariable : 'saveMessageFromView'
            const [res] = await Promise.all([
                apiCall('post', 'saveMessageFromView', formData, '', 'messages'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const changeCampaignStatus = async (formData, noAlert = false, from) => {
        try {
            from = from ? from : 'changeStatus'
            const [res] = await Promise.all([
                apiCall('post', 'changeCampaignStatus', formData, '', 'campaign'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <MessageContext.Provider
            value={{
                all_messages: state.all_messages,
                responseStatus: state.responseStatus,
                all_project_messages: state.all_project_messages,
                all_actual_messages: state.all_actual_messages,
                all_archive_messages: state.all_archive_messages,
                getAllMessages,
                searchprojectmessages,
                deleteMessage,
                searchactualmessages,
                saveMessageFromView,
                saveMessage,
                searcharchiveMessages,
                changeCampaignStatus,
                clearResponse,
            }}
        >
            {props.children}
        </MessageContext.Provider>
    )
}

export default MessageState
