import React, { useState, useContext, useEffect } from 'react'
import InvoiceContext from '@/product/context/invoice/invoiceContext'
import AuthContext from '@/product/context/auth/authContext'
import InvoiceCard from '@/custom/components/molecules/InvoiceCard'
import NoRecordsFound from '@/custom/components/atoms/NoRecordsFound'
import { Pagination } from '@material-ui/lab'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
const MyInvoiceBody = (props) => {
    let [viewProduct, setViewProduct] = useState([])
    const authContext = useContext(AuthContext)
    const invoiceContext = useContext(InvoiceContext)
    const { search_alluserinvoices, getAllUserInvoices } = invoiceContext
    const { isAuthenticated } = authContext
    let propsAction = props.action
    const { t } = useTranslation()
    const formikSearch = useFormik({
        initialValues: props.initialValues,
        action: propsAction,
    })

    useEffect(() => {
        formikSearch.setFieldValue(
            'action',
            propsAction === 'all'
                ? 'allinv'
                : propsAction === 'partial'
                ? (propsAction = 'unpaid')
                : propsAction,
        )
    }, [propsAction])

    // useEffect(() => {
    //     setSearch({
    //         ...search,
    //         action:
    //             props.match.params.action === 'all'
    //                 ? 'allinv'
    //                 : props.match.params.action === 'partial'
    //                 ? (props.match.params.action = 'unpaid')
    //                 : props.match.params.action,
    //     })
    // }, [props.match.params.action])

    useEffect(() => {
        if (isAuthenticated) {
            getAllUserInvoices(formikSearch.values)
        }
    }, [formikSearch.values, isAuthenticated])

    const onHandlePage = (event, value) => {
        formikSearch.setFieldValue('page', value)
    }

    useEffect(() => {
        setViewProduct(search_alluserinvoices.records.length ? search_alluserinvoices.records : [])
    }, [search_alluserinvoices])
    console.log(viewProduct, 'viewww')

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])
    return (
        <>
            {viewProduct.length ? (
                <>
                    <div className="searchResults myInvoicesCnt Grid">
                        {viewProduct.map((data, index) => (
                            <>
                                <InvoiceCard data={data} />
                            </>
                        ))}
                    </div>
                    <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper">
                        <h6>
                            {t('showing')} {search_alluserinvoices.setDisp} {t('of')}{' '}
                            {search_alluserinvoices.totalRecords}
                        </h6>
                        <Pagination
                            count={Math.ceil(
                                search_alluserinvoices.totalRecords / formikSearch.values.limit,
                            )}
                            page={formikSearch.values.page}
                            onChange={onHandlePage}
                            siblingCount={3}
                            showFirstButton
                            showLastButton
                            boundaryCount={2}
                        />
                    </div>
                </>
            ) : (
                <NoRecordsFound />
            )}
        </>
    )
}

export default MyInvoiceBody
