import React from 'react'

const addDefaultSrc = (e) => {
    e.target.src = '/assets/images/noimage.png'
}

const AvatarImage = ({ onClick, data, className, folder, avatar, avatar_type }) => {
    return (
        <>
            {parseInt(data[avatar_type || 'avatar_type'], 10) === 2 ? (
                <img
                    onClick={onClick || null}
                    src={data[avatar || 'avatar']}
                    alt={data.title}
                    onError={addDefaultSrc}
                    className={className || ''}
                />
            ) : (
                <img
                    onClick={onClick || null}
                    src={`${global.site_url}/uploads/${folder || 'product'}/${
                        data[avatar || 'avatar']
                    }`}
                    alt={data.title}
                    onError={addDefaultSrc}
                    className={className || ''}
                />
            )}
        </>
    )
}

export default AvatarImage
