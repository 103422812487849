import React from 'react'
import './Dashboard.css'
import SideNav from './SideNav'

const DashboardLayout = (props) => {
    return (
        <div className="dashboard customContainer">
            <div className="d-flex justify-content-start">
                <div className="dashboardLt">
                    <SideNav />
                </div>
                <div className="dashboardRt">{props.children}</div>
            </div>
        </div>
    )
}

export default DashboardLayout
