import React, { useContext, useEffect, useState } from 'react'
import { mapData } from '../../../../../common/components'
import PrimaryButton from '../../../../atoms/PrimaryButton'
import ShipengineContext from '../../../../../context/shipping/shipengine/shipengineContext'
import UserContext from '../../../../../context/user/userContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthContext from '../../../../../context/auth/authContext'
import Dialog from '../../../../../components/organisms/Dialog'
import AlertContext from '../../../../../context/alert/alertContext'
import ProductCommonContext from '../../../../../context/common/commonContext'
import Loaders from '../../../../molecules/Loaders'
import countryLabel from './countryLable.json'
import SecondaryButton from '../../../../atoms/SecondaryButton'
import { useTranslation } from 'react-i18next'
function AddressDetails(props) {
    const authContext = useContext(AuthContext)
    const userContext = useContext(UserContext)
    const { user, loadUser } = authContext
    const { setAlert } = useContext(AlertContext)
    const productCommonContext = useContext(ProductCommonContext)
    const { userAddress, responseStatus: responseStatusUser } = userContext
    const { allCountries, allStates } = productCommonContext
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [reload, setReload] = useState(false)
    const { t } = useTranslation()
    const {
        responseStatus: responseStatusShipEngine,
        validateAddress,
        clearResponse: clearShipEngineResponse,
    } = useContext(ShipengineContext)

    const validationAddress = Yup.object({
        first_name: Yup.string()
            .matches(/^[a-zA-Z ."'\-]*$/g, t('No Special Characters and Numbers Allowed'))
            .min(2, t('Mininum 2 characters'))
            .max(15, t('Maximum 15 characters'))
            .required(t('Required!')),
        last_name: Yup.string()
            .matches(/^[a-zA-Z ."'\-]*$/g, t('No Special Characters and Numbers Allowed'))
            .min(2, t('Mininum 2 characters'))
            .max(15, t('Maximum 15 characters'))
            .required(t('Required!')),
        address1: Yup.string().required(t('Required!')),
        country: Yup.string().required(t('Required!')),
        city: Yup.string().required(t('Required!')),
        state: Yup.string().required(t('Required!')),
        zip: Yup.string()
            .trim()
            .matches(/^[0-9]*$/g, t('Only numbers are allowed'))
            .min(5, t('Mininum 5 digits'))
            .max(6, t('Maximum 6 digits'))
            .required(t('Required!')),
    })
    const addressformik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            is_primary: '',
            city: '',
            state: '',
            zip: '',
            companyname: '',
            user_id: user ? user.id : '',
            is_primary: 1,
            address1: '',
            address2: '',
            phone: '',
            country: global.defaultCountry,
            id: '',
            isVerified: false,
        },
        validationSchema: validationAddress,
        onSubmit: (values) => {
            console.log(values, props.data, 'values and props123')
            setDisabled(true)
            if (!values.isVerified && props.validateAddress) {
                // delete values.isVerified
                validateAddress(values)
                return false
            } else {
                userAddress(values)
                addressformik.resetForm()
            }
            setLoading(true)
            // updateStripeCard(values)
        },
    })
    useEffect(() => {
        if (props.data.status === 'new') {
            addressformik.values.first_name = ''
            addressformik.values.last_name = ''
            addressformik.values.address1 = ''
            addressformik.values.address2 = ''
            addressformik.values.city = ''
            addressformik.values.state = ''
            addressformik.values.country = global.defaultCountry
            addressformik.values.zip = ''
            addressformik.values.id = ''
            addressformik.values.is_primary = '1'
        }
    }, [props.data])
    const defaultFormik = useFormik({
        initialValues: {
            is_primary: '',
            id: '',
            status: '',
        },
        onSubmit: (values) => {
            if (values.id) {
                setDisabled(true)
                userAddress(values)
            }
        },
    })

    useEffect(() => {
        if (props.data.data) {
            if (props.data.status === 'delete') {
                defaultFormik.values.id = props.data.data.id
                defaultFormik.values.status = 'inactive'
                defaultFormik.values.is_primary = ''
                defaultFormik.handleSubmit()
            } else if (props.data.status === 'default') {
                defaultFormik.values.id = props.data.data.id
                defaultFormik.values.is_primary = '1'
                defaultFormik.values.status = 'active'
                defaultFormik.handleSubmit()
            } else if (props.data.status === 'update') {
                addressformik.values.first_name = props.data.data.first_name
                addressformik.values.last_name = props.data.data.last_name
                addressformik.values.address1 = props.data.data.address1
                addressformik.values.address2 = props.data.data.address2
                addressformik.values.city = props.data.data.city
                addressformik.values.state = props.data.data.state
                addressformik.values.country = props.data.data.country
                addressformik.values.zip = props.data.data.zip
                addressformik.values.id = props.data.data.id
                addressformik.values.is_primary = props.data.data.is_primary
                setReload(!reload)
            }
            console.log(props.data.data, 'status')
        }
    }, [props.data.data])
    useEffect(() => {
        if (responseStatusUser) {
            setDisabled(false)
            addressformik.resetForm()
            setLoading(false)
        }
    }, [responseStatusUser])
    const addressDetails = {
        formik: addressformik,
        data: [
            {
                label: t('First name'),
                type: 'text',
                placeholder: t('Enter your first name'),
                class: 'col-12',
                name: 'first_name',
            },
            {
                label: t('Last name'),
                placeholder: t('Enter your last name'),
                class: 'col-12',
                type: 'text',
                name: 'last_name',
            },
            {
                label: t('Address1'),
                placeholder: t('Enter your address1'),
                class: 'col-12',
                type: 'text',
                name: 'address1',
            },
            {
                label: t('Address2'),
                placeholder: t('Enter your address2'),
                class: 'col-12',
                type: 'text',
                name: 'address2',
            },
            {
                label: countryLabel[addressformik.values.country]?.city.label || t('City'),
                placeholder:
                    countryLabel[addressformik.values.country]?.city.placeholder ||
                    t('Enter your city'),
                class: 'col-12',
                type: 'text',
                name: 'city',
            },
            {
                label: t('Country'),
                placeholder: t('Select your country'),
                class: 'col-12',
                type: 'select',
                options: allCountries
                    .filter((country) => country.isoCode == props.onlyCountry || 'US')
                    .filter((country) => global.ignoreCountry.indexOf(country.isoCode) === -1)
                    .map((country) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = country.name
                        busCategoryChanged.value = country.isoCode
                        return busCategoryChanged
                    }),
                name: 'country',
            },
            {
                label: countryLabel[addressformik.values.country]?.state.label || t('State'),
                placeholder:
                    countryLabel[addressformik.values.country]?.state.placeholder ||
                    t('Enter your state'),
                class: 'col-12',
                type: 'select',
                options: allStates
                    .filter(
                        (state) =>
                            (addressformik.values.country
                                ? addressformik.values.country.indexOf(state.countryCode) !== -1
                                : global.defaultCountry.indexOf(state.countryCode) !== -1) &&
                            global.ignoreStates.indexOf(state.isoCode) === -1,
                    )
                    .map((buscat) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = buscat.name
                        busCategoryChanged.value = buscat.isoCode
                        return busCategoryChanged
                    }),
                name: 'state',
            },

            {
                label: countryLabel[addressformik.values.country]?.zip.label || t('Zip'),
                placeholder:
                    countryLabel[addressformik.values.country]?.zip.placeholder ||
                    t('Enter your Zip Code'),
                class: 'col-12',
                type: 'text',
                name: 'zip',
            },
        ],
    }
    useEffect(() => {
        if (responseStatusShipEngine) {
            if (responseStatusShipEngine.from === 'validateAddress') {
                if (responseStatusShipEngine.status === 'success') {
                    addressformik.setFieldValue('isVerified', true)
                    props.function(false, 'new', null)
                    addressformik.handleSubmit()
                } else {
                    setAlert(t('Address is invalid please check'), 'error')
                    props.function(false, 'new', null)
                    addressformik.handleReset()
                    setDisabled(false)
                }
            }
        }
    }, [responseStatusShipEngine])

    useEffect(() => {
        if (user) {
            addressformik.values.user_id = user.id
        }
    }, [user])
    return (
        <>
            {loading ? (
                <Loaders name="home" isLoading={loading} />
            ) : (
                <Dialog
                    title={props.title ? props.title : t('Address Details')}
                    open={
                        props.data.popup &&
                        (props.data.status === 'new' || props.data.status === 'update')
                    }
                    size="md"
                    handleClose={() => props.function(false, 'new', null)}
                    modaltitle={t('Address Details')}
                >
                    <div className="addressInfo">
                        <form onSubmit={addressformik.handleSubmit} autoComplete="nofill">
                            <div className="row">
                                {mapData(addressDetails)}
                                <div className="col-12 adrrAct">
                                    <SecondaryButton
                                        id="address_close"
                                        label={t('close')}
                                        onClick={() => props.function(false, 'new', null)}
                                    />

                                    <PrimaryButton
                                        id="save_profile_address"
                                        // disabled={disabled}
                                        type="submit"
                                        label={t('save')}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </Dialog>
            )}
        </>
    )
}

export default AddressDetails
