import React, { useState, useEffect, useRef } from 'react'
import './ImageZoom.css'
import CloseIcon from '@material-ui/icons/Close'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, IconButton } from '@material-ui/core'

function ImageZoom({ imageZoomModal, toggleImage, images, imageIndex, setImageZoomModal }) {
    const { t } = useTranslation()
    return (
        <Dialog
            fullScreen
            open={imageZoomModal}
            className="fullScreenImageModal"
            function={() => setImageZoomModal(!imageZoomModal)}
            onEscapeKeyDown={() => setImageZoomModal(!imageZoomModal)}
        >
            <>
                <IconButton
                    edge="start"
                    color="inherit"
                    className="fsCloseIcon"
                    onClick={() => setImageZoomModal(false)}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                {images?.length ? (
                    <div className="imageCount">{`${t('Showing')} ${imageIndex + 1} ${t('of')} ${
                        images?.length
                    }`}</div>
                ) : null}
                <div className="fullScreenImageModalInner">
                    {images ? (
                        <TransformWrapper initialScale={1}>
                            {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => (
                                <React.Fragment>
                                    <div className="fullScreenImageTools">
                                        <Button
                                            disabled={rest?.state?.scale == 8}
                                            className="imageIcon"
                                            onClick={() => zoomIn()}
                                        >
                                            <span className="material-icons">zoom_in</span>
                                        </Button>
                                        <Button
                                            disabled={rest?.state?.scale == 1}
                                            className="imageIcon"
                                            onClick={() => zoomOut()}
                                        >
                                            <span className="material-icons">zoom_out</span>
                                        </Button>
                                        <Button
                                            disabled={rest?.state?.scale == 1}
                                            onClick={() => resetTransform()}
                                        >
                                            {t('Reset')}
                                        </Button>
                                        <Button
                                            disabled={
                                                rest?.state?.positionX == 0 &&
                                                rest?.state?.positionY == 0
                                            }
                                            onClick={() => centerView()}
                                        >
                                            {t('Center')}
                                        </Button>
                                    </div>

                                    <TransformComponent>
                                        <img
                                            src={images[imageIndex]?.main}
                                            className="fullScreenImage"
                                            alt="Additional images"
                                            onError={(e) =>
                                                (e.target.src = '/assets/images/noImage.png')
                                            }
                                        />
                                    </TransformComponent>
                                </React.Fragment>
                            )}
                        </TransformWrapper>
                    ) : null}

                    {images?.length > 1 ? (
                        <div className="fsAct">
                            <Button onClick={() => toggleImage('prev')} disabled={imageIndex == 0}>
                                <span className="material-icons">chevron_left</span>
                            </Button>
                            <Button
                                onClick={() => toggleImage('next')}
                                disabled={imageIndex + 1 == images?.length}
                            >
                                <span className="material-icons">chevron_right</span>
                            </Button>
                        </div>
                    ) : null}
                </div>
            </>
        </Dialog>
    )
}

export default ImageZoom
