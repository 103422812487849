import { Button } from '@material-ui/core'
import React, { useState, useContext, useEffect } from 'react'
import CustomInput from '@/product/components/atoms/Inputs/CustomInput'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import '@/custom/components/molecules/ProductCard/ProductCard.css'
import FavouriteCheckbox from '@/product/components/atoms/FavoriteCheckbox'
import SecondaryButton from '@/product/components/atoms/SecondaryButton'
import { useHistory } from 'react-router-dom'
// import Popup from '@/product/components/organisms/Popup'
// import Timer from '../../../common/timer'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import BuyerContext from '@/product/context/buyer/buyerContext'
import AuthContext from '@/product/context/auth/authContext'
import PayContext from '@/product/context/payment/payContext'
import CreditContext from '@/product/context/credit/creditContext'
import { useTranslation } from 'react-i18next'
import ProductContext from '@/product/context/product/productContext'
import CommonContext from '@/product/context/common/commonContext'
import CustomDialog from '@/custom/components/organisms/Dialog'
import { handleRedirectInternal } from '@/custom/common/components'
import HyperPayCardContext from '@/product/context/payment/hyperpay/card/cardContext'
import { currencyFormat } from '@/custom/common/components'
import { mapData } from '@/product/common/components'
let randomVariable = Math.random()
const Bidding = (props) => {
    const buyerContext = useContext(BuyerContext)
    const authContext = useContext(AuthContext)
    const payContext = useContext(PayContext)
    const creditContext = useContext(CreditContext)
    const commonContext = useContext(CommonContext)
    const productContext = useContext(ProductContext)

    const { t } = useTranslation()

    const searchValue = props.search ? props.search : {}
    const { getAllSearchProducts } = productContext
    const {
        get_all_card_details,
        responseStatus: responseStatusCard,
        clearResponse: clearResponseCard,
    } = useContext(HyperPayCardContext)

    const { payCartRosoom } = payContext
    const {
        addBidDeposit,
        responseStatus: responseStatusCredit,
        clearResponse: clearResponseCredit,
    } = creditContext

    let { bidConfirm } = buyerContext
    const { user, isAuthenticated, loadUser } = authContext
    const [cardCount, setCardCount] = useState(0)
    const [toggleDialogCard, setToggleDialogCard] = useState(false)
    const [product, setProduct] = useState()
    const history = useHistory()

    const [toggleDialog, setToggleDialog] = useState(false)
    const [toggleDialogDeposit, setToggleDialogDeposit] = useState(false)

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    const changeDialogStatusDeposit = () => {
        setToggleDialogDeposit(!toggleDialogDeposit)
    }

    const changeDialogStatusCard = () => {
        setToggleDialogCard(!toggleDialogCard)
    }

    useEffect(() => {
        if (responseStatusCredit) {
            // console.log('responseStatusCredit', responseStatusCredit)
            console.log(
                responseStatusCredit.from,
                randomVariable,
                'randomm1234',
                props.favId,
                responseStatusCredit.from === `product_${props.type}_${props.favId}`,
            )
            if (responseStatusCredit.from === `product_${props.type}_${props.favId}`) {
                if (responseStatusCredit.status === 'success') {
                    setToggleDialogDeposit(false)
                    console.log('how many times')
                    bidConfirm(formik.values)
                    formik.setTouched({})

                    // setToggleDialog(false)
                    getAllSearchProducts(searchValue)
                    formik.values.wsprice = ''
                } else {
                    handleRedirectInternal(history, `mypoints`)
                }
            }
            clearResponseCredit()
        }
    }, [responseStatusCredit])

    useEffect(() => {
        if (get_all_card_details && get_all_card_details.records) {
            setCardCount(get_all_card_details.records.length)
        }
    }, [get_all_card_details])

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    useEffect(() => {
        if (product) {
            formik.setFieldValue('id', product.id)
            formikPayment.setFieldValue('cart_id', product.id)
        }
    }, [product])

    const validationArray = Yup.object({
        wsprice: Yup.number()
            .min(
                product ? product.next_bid : 0,
                `${t('Min bid')} ${
                    product ? currencyFormat(product.next_bid, t) : currencyFormat(0, t)
                }`,
            )
            .test('is-decimal', 'Cannot be a decimal', (value) =>
                value ? typeof value === 'number' && value % 1 === 0 : true,
            )
            .required(t('Enter Bid Amount')),
    })
    const validationPayment = Yup.object({
        payment_method: Yup.string().required('Required'),
    })
    const removeAlpha = (evt) => {
        const re = /^[+-]?\d*(?:[.,]\d*)?$/
        if (re.test(evt.target.value)) {
            formik.handleChange(evt)
        }
        // evt.target.value === 'e' && evt.preventDefault()
    }
    const formik = useFormik({
        initialValues: {
            wsprice: '',
            id: 0,
            bidding_type: '',
            minimumReservePrice: false,
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (cardCount) {
                if (product.deposit && product.deposit_amount > 0 && !product.bid_deposit_id) {
                    formik.setSubmitting(false)
                    setToggleDialogDeposit(true)
                } else if (toggleDialog) {
                    bidConfirm(values)
                    formik.setTouched({})
                    setToggleDialog(false)
                    formik.values.wsprice = ''
                } else {
                    formik.setSubmitting(false)
                    setToggleDialog(true)
                }
            } else {
                setToggleDialogCard(true)
            }
        },
    })

    useEffect(() => {
        formik.values.bidding_type = props.type
    }, [props.type])

    const formikPayment = useFormik({
        initialValues: {
            cart_id: 0,
            pay_type: 'bid_deposit',
            payment_method: 'wallet',
        },
        validationSchema: validationPayment,
        onSubmit: (values) => {
            formikPayment.setSubmitting(true)
            if (values.payment_method === 'wallet') {
                let formData = {
                    amount: product.deposit_amount,
                    product_id: values.cart_id,
                    credit_notes: `Bid Deposit - ${product.title}`,
                    credit_type: 'user',
                    type: 'projects',
                    creditor_id: user.id,
                }
                randomVariable = Math.random()
                console.log(randomVariable, 'randomm')
                addBidDeposit(formData, `product_${props.type}_${props.favId}`)
            }
        },
    })

    const bidAmount = [
        {
            label:
                formik.values.bidding_type === 'hard' ? t('Enter Bid Amount') : t('Enter Max Bid'),
            name: 'wsprice',
            type: 'number',
            placeholder: t(
                `${t('Min bid')} ${
                    product && product.next_bid
                        ? currencyFormat(product.next_bid, t)
                        : currencyFormat(0, t)
                }`,
            ),
            // helperText:
            //     formik.values.bidding_type === 'hard'
            //         ? ''
            //         : `${t('enter')} ${
            //               product && product.next_bid
            //                   ? currencyFormat(product.next_bid, t)
            //                   : currencyFormat(0, t)
            //           } ${t('or more')}`,
            class: props.productView ? 'productBidInput' : '',
            size: 'small',
            autoFocus: false,
            formik: formik,
            onChange: removeAlpha,
            onWheel: (e) => {
                e.preventDefault()
            },
        },

        // {
        //     helperText:
        //         props.helpText &&
        //         (product && product.bidhistory.length > 0
        //             ? 'Enter ' + currencyFormat(product.next_bid) + ' or more'
        //             : product && 'Minimum_Bid' + ' ' + currencyFormat(product.next_bid)),
        //     label: 'Enter Bid Amt',
        //     name: 'wsprice',
        //     type: 'number',
        //     placeholder: `${'Min Bid'} ${
        //         product && product.next_bid ? currencyFormat(product.next_bid) : currencyFormat(0)
        //     }`,
        //     class: '',
        //     size: 'small',
        //     disabled: canBid ? false : true,
        //     autoFocus: false,
        //     formik: formik,
        //     onChange: removeAlpha,
        // },
    ]
    const modeOfPayment = [
        {
            label: t('Select mode of Points'),
            placeholder: t('Select mode of Points'),
            type: 'select',
            class: 'col-12',
            options: [
                // { value: 'online', show: t('online') },
                { value: 'wallet', show: t('wallet') },
            ],
            name: 'payment_method',
            formik: formikPayment,
        },
    ]

    const handleQuickBid = () => {
        bidConfirm({ ...formik.values, bidding_type: 'hard', wsprice: product.next_bid })
    }

    return (
        <>
            {product ? (
                <>
                    {product.market_status === 'open' ? (
                        <form onSubmit={formik.handleSubmit}>
                            {isAuthenticated ? (
                                <div className="biddingCnt d-flex justify-content-between align-items-start">
                                    {formik.values.bidding_type == 'quick' ? (
                                        <PrimaryButton
                                            label={`Quick Bid at ${currencyFormat(
                                                product.next_bid,
                                                t,
                                            )}`}
                                            onClick={handleQuickBid}
                                            className="primGoldBtn"
                                        />
                                    ) : (
                                        <>
                                            {Object.values(mapData(bidAmount))}
                                            {product.bidtopstatus === 'winner' ? (
                                                <PrimaryButton
                                                    id="increase_hard_bid"
                                                    label={
                                                        formik.values.bidding_type === 'hard'
                                                            ? t('Bid')
                                                            : t('Max bid')
                                                    }
                                                    type="submit"
                                                    btnSize="small"
                                                    className="primGoldBtn"
                                                />
                                            ) : (
                                                <PrimaryButton
                                                    id="place_hard_bid"
                                                    label={
                                                        formik.values.bidding_type === 'hard'
                                                            ? t('Bid')
                                                            : t('Max bid')
                                                    }
                                                    type="submit"
                                                    btnSize="small"
                                                    className={`${
                                                        props.productView ? 'primBlackBtn ' : ''
                                                    }primGoldBtn`}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            ) : (
                                <PrimaryButton
                                    id="login_to_bid"
                                    label={
                                        <>
                                            {t('login_to_bid')}
                                            <span
                                                style={{
                                                    width: 'fit-content',
                                                    fontSize: '22px',
                                                }}
                                                className="material-icons"
                                            >
                                                chevron_right
                                            </span>
                                        </>
                                    }
                                    btnSize="small"
                                    onClick={() => {
                                        if (product.id > 0) {
                                            sessionStorage.setItem('productID', product.id)
                                        }
                                        handleRedirectInternal(history, 'login')
                                    }}
                                />
                            )}
                        </form>
                    ) : null}

                    <CustomDialog
                        title={t('confirm_bid')}
                        open={toggleDialog}
                        function={changeDialogStatus}
                        className="confirmBidModal"
                    >
                        <h5>
                            {t('You are about to place a bid for')}{' '}
                            {formik.values.wsprice !== '' ? (
                                <span>{currencyFormat(formik.values.wsprice, t)}</span>
                            ) : null}
                        </h5>
                        <h5>{t('please_confirm_if_you_want_to_continue_with_this_action')}?</h5>
                        <div className="actionWrapper">
                            <Button id="cancel" onClick={() => setToggleDialog(false)}>
                                {t('cancel')}
                            </Button>
                            <PrimaryButton
                                onClick={formik.handleSubmit}
                                id="confirm_bid_submit"
                                type="button"
                                disabled={formik.isSubmitting}
                                label={t('submit')}
                            />
                        </div>
                    </CustomDialog>
                    <CustomDialog
                        title={t('Submit a deposit')}
                        open={toggleDialogDeposit}
                        function={changeDialogStatusDeposit}
                        className="confirmBidModal"
                    >
                        <h4>
                            {t(
                                'A deposit Points has to be placed before placing a bid on this product',
                            )}
                            .
                        </h4>
                        <h5>
                            {t('Points')}: <span>{product.deposit_amount}</span>
                        </h5>

                        <div className="actionWrapper">
                            <Button
                                id="bidding_cancel"
                                onClick={() => setToggleDialogDeposit(false)}
                            >
                                {t('cancel')}
                            </Button>
                            <PrimaryButton
                                id="bidding_submit"
                                onClick={formikPayment.handleSubmit}
                                type="button"
                                label={t('submit')}
                            />
                        </div>
                    </CustomDialog>
                    <CustomDialog
                        title={t('No Cards Found')}
                        open={toggleDialogCard}
                        function={changeDialogStatusCard}
                    >
                        <h5>{t("You don't have added any cards yet!")}</h5>
                        <h5>{t('Please add a card to continue Bidding')}</h5>
                        <div className="actionWrapper">
                            <Button id="cancel" onClick={() => setToggleDialogCard(false)}>
                                {t('cancel')}
                            </Button>
                            <PrimaryButton
                                id="add_card"
                                label={t('continue')}
                                btnSize="small"
                                onClick={() =>
                                    handleRedirectInternal(history, 'payments/saved_cards')
                                }
                            />
                        </div>
                    </CustomDialog>
                </>
            ) : null}
        </>
    )
}

export default Bidding
