import React, { useState, useContext, useEffect } from 'react'
import DashboardLayout from '../Dashboard/DashboardLayout'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import { mapData } from '@/product/common/components'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import './Dashboard.css'
import settingContext from '@/product/context/seller/setting/settingContext'
import userContext from '@/product/context/user/userContext'
import authContext from '@/product/context/auth/authContext'
import alertContext from '@/product/context/alert/alertContext'
import Loaders from '@/custom/components/molecules/Loaders'
import * as Yup from 'yup'
import UserContext from '@/product/context/user/userContext'

const MySkyflowCard = (props) => {
    const { searchTableAction, responseStatus, searchTable, table_data, clearResponse } =
        useContext(settingContext)
    const userContext = useContext(UserContext)
    const [loading, setLoading] = useState(false)
    const { user, isAuthenticated } = useContext(authContext)
    const { setAlert } = useContext(alertContext)
    const { updateProfile, updatePreference, responseStatus: responseStatusUser } = userContext
    const [reviewCount, setReviewCount] = useState(0)
    const { t } = useTranslation()
    const validationArray = Yup.object({
        rating: Yup.number()
            .min(1, t('Please select rating'))
            .max(5, t('Please select rating'))
            .required(t('Select rating')),
        description: Yup.string().required(t('Enter Description')),
    })
    const formik = useFormik({
        initialValues: {
            rating: 0,
            description: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            setLoading(true)
            if (!reviewCount) {
                if (isAuthenticated && user) {
                    values.user_id = user.id
                    values.table = 'user_review'
                    searchTableAction(values)
                }
            } else {
                setAlert('You already have reviewed', 'error')
                setLoading(false)
            }
        },
    })
    const additionalDetails = {
        formik: formik,
        data: [
            {
                label: t('Select Rating'),
                placeholder: t('Select Rating'),
                type: 'select',
                class: 'col-6',
                options: [
                    { value: 1, show: 1 },
                    { value: 2, show: 2 },
                    { value: 3, show: 3 },
                    { value: 4, show: 4 },
                    { value: 5, show: 5 },
                ],
                name: 'rating',
            },
            {
                label: t('Description'),
                placeholder: t('Enter the description'),
                class: 'col-12',
                type: 'textarea',
                name: 'description',
            },
        ],
    }

    useEffect(() => {
        if (responseStatus && responseStatus.from == 'CommunicationSearchTable') {
            setAlert(responseStatus.message, responseStatus.status)
            setReviewCount(1)
            clearResponse()
            setLoading(false)
            formik.resetForm()
        }
    }, [responseStatus])
    useEffect(() => {
        searchTable(
            {
                page: 1,
                limit: 5,
                order: '',
                orderby: 'ur.id,desc',
                table: 'user_review',
                // filters: {
                //     fields: {
                //         value: 0,
                //         type: 'in',
                //         field: 'ur.active',
                //     },
                // },
                tableJoins: [
                    {
                        baseTable: 'users',
                        // customTable: 'users',
                        joinType: 'inner',
                        baseJoinField: 'id',
                        connectJoinField: 'user_id',
                    },
                ],
            },
            'getReview',
        )
    }, [])
    useEffect(() => {
        if (table_data && table_data.records.length && user && user.id) {
            const isReviewed = table_data.records.filter((item) => item.user_id == user.id)
            console.log('rm table_data', isReviewed)
            setReviewCount(isReviewed.length)
        }
    }, [table_data, responseStatusUser, reviewCount])

    return (
        <DashboardLayout>
            {loading ? (
                <Loaders isLoading={loading}></Loaders>
            ) : (
                <div className="myAccount">
                    <div className="accountCnt">
                        <h2 className="dashTitle">{t('Leave a review')}</h2>

                        <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                            <div className="accChild mt-5">
                                <div className="row">
                                    {Object.values(mapData(additionalDetails))}
                                </div>
                            </div>
                            <PrimaryButton type="submit" label={t('submit')} />
                        </form>
                    </div>
                </div>
            )}
        </DashboardLayout>
    )
}

export default MySkyflowCard
