import { RESPONSE_STATUS, CLEAR_RESPONSE, GET_CARDS } from './hyperPayTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_CARDS: {
            return {
                ...state,
                all_cards: action.payload,
            }
        }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
