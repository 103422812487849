import React, { useContext, useState, useEffect, useRef } from 'react'
import { mapData, dateTimeFormatFunction, dateFormatFunction } from '../../../../common/components'
import { Button } from '@material-ui/core'
import { Divider } from '@material-ui/core'
import { useFormik, setNestedObjectValues } from 'formik'
import { initialFunction, validationFunction, editValue } from '../common/FieldFunction'
import DynamicContext from '../../../../context/dynamic/dynamicContext'
import * as Yup from 'yup'
import IconButton from '@material-ui/core/IconButton'
import AuthContext from '../../../../context/auth/authContext'

const ItemPropsArray = (props) => {
    const authContext = useContext(AuthContext)
    const { user } = authContext
    let [isLoaded, setLoad] = useState(false)
    const [valueLoaded, setValueLoaded] = useState(false)
    const [editUpdate, setEditUpdate] = useState(false)
    const [isDeleted, setIsDeleted] = useState(false)
    const [initialValues, setInitialValues] = useState({})
    const [fieldValue, setFieldValue] = useState([])
    const [initialEmptyValues, setInitialEmptyValues] = useState({})
    const [validationArray, setValidationArray] = useState({})
    const [mapDataValue, setMapDataValue] = useState([])
    const dynamicContext = useContext(DynamicContext)
    const { dynamicValue: project_dynamicfields } = dynamicContext
    let formikData = props.formikData
    const alldata_all_dropdown = props.alldata_all_dropdown
    const allCountries = props.allCountries
    const subStateOptions = props.subStateOptions
    const subCityOptions = props.subCityOptions
    const allLocations = props.allLocations
    const [reload, setReload] = useState(false)

    let d = new Date()

    const formik = useFormik({
        initialValues: initialValues,
        validateOnBlur: false,
        // validationSchema: Yup.object().shape({ ...validationArray }, ['auction', 'buynow']),
        enableReinitialize: true,
        onSubmit: (values) => {
            // formSubmitFunction(values)
        },
    })

    const onItemArrayHandle = (option) => {
        if (props.trigger) props.resetTrigger(false)
        if (option > -1) {
            console.log(option)
            /* formikData.values.itemPropsArray.splice(option, 1)
            formikData.values.itemPropStep -= 1
            formikData.setFieldValue('itemPropsArray', formikData.values.itemPropsArray) */
            console.log(formik.values, 'values insid delete')
            // formik.values.is_deleted = 1
            formik.setFieldValue('is_deleted', 1)
            setIsDeleted(true)
        } else {
            //formikData.values.itemPropStep += 1
        }
        //formikData.setFieldValue('itemPropStep', formikData.values.itemPropStep)
        // setLoad(false)
    }

    useEffect(() => {
        if (
            project_dynamicfields &&
            project_dynamicfields.records.length &&
            project_dynamicfields.from == 'forProfileOrganization'
        ) {
            let dynamicValue = [...project_dynamicfields.records]
            let dynamicValueAssign = [] // dynamicValue.records
            dynamicValue
                .filter((data) => data.active === 1 && data.multi_field === 1)
                .map((item) => {
                    dynamicValueAssign.push(item)
                })
            // dynamicValueAssign.map((e) => {
            //     if (e.name == 'attorney_lable') {
            //         const lable = 'APODERADO ' + (props.index + 1)
            //         e.content = `${lable}`
            //     }
            // })
            setFieldValue(dynamicValueAssign)
        }
    }, [project_dynamicfields])

    useEffect(() => {
        if (props.errors) {
            formik.setErrors(props.errors)
            /*formik.setTouched({ year: true })
            formik.setTouched({ quarter: true })
            formik.setTouched({ amount: true })*/

            formik.setTouched(setNestedObjectValues(props.errors, true))
        }
    }, [props.errors])

    const subLevelOptions = (data) => {
        let valueDrop = alldata_all_dropdown
            .filter((subcat) => {
                if (
                    formik.values[data.sub_field] &&
                    parseInt(subcat.level, 10) === parseInt(data.sub_level, 10)
                ) {
                    if (
                        parseInt(subcat.active, 10) === 1 ||
                        (formik.values[data.name] && formik.values[data.name] === subcat.id)
                    ) {
                        return (
                            parseInt(formik.values[data.sub_field], 10) ===
                            parseInt(subcat.level_id, 10)
                        )
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            })
            .map((subcat) => {
                let subCategoryChanged = {}
                subCategoryChanged.show = subcat.description
                subCategoryChanged.value = subcat.id
                return subCategoryChanged
            })
        return valueDrop
    }

    useEffect(() => {
        setValueLoaded(false)
        setEditUpdate(false)
        setInitialValues({})
        setIsDeleted(false)
        if (fieldValue && fieldValue.length) {
            let custom = {
                id: 0,
                is_deleted: 0,
            }
            let { tempMapData, show, calculateValues } = initialFunction(
                fieldValue,
                alldata_all_dropdown,
                subLevelOptions,
                allCountries,
                subStateOptions,
                subCityOptions,
                allLocations,
                props.index,
            )
            setInitialValues({ ...custom, ...show })
            console.log(show, tempMapData, 'temmp')
            setMapDataValue(tempMapData)
            setTimeout(() => {
                if (tempMapData.length) {
                    setValueLoaded(true)
                    setEditUpdate(true)
                    // setReload(!reload)
                }
            }, 1000)
        }
    }, [fieldValue, user])

    const mapDataValueRef = useRef(mapDataValue)

    useEffect(() => {
        mapDataValueRef.current = mapDataValue
    })

    useEffect(() => {
        if (formik.values && formikData.values.multiArrayObject) {
            if (mapDataValueRef.current.length) {
                let tempMapData = mapDataValueRef.current
                tempMapData.map((data) => {
                    if (data.type === 'select' && parseInt(data.sub_select, 10) === 1) {
                        if (parseInt(data.sub_level, 10) > 0) {
                            data.options = subLevelOptions(data)
                        }
                    }
                    data.class = `${data.class}${formik.values.is_deleted === 1 ? ' d-none' : ''}`
                })
                setReload(!reload)
            }
            if (formikData.values.multiArrayObject[props.index] != formik.values && isLoaded) {
                if (
                    formik.values[`attorney_dni_file_${props.index}`] &&
                    formik.values[`attorney_dni_file_${props.index}`] != ''
                ) {
                    formik.values[`attorney_dni_file`] =
                        formik.values[`attorney_dni_file_${props.index}`]
                }
                formikData.values.multiArrayObject[props.index] = formik.values
                formikData.setFieldValue('multiArrayObject', formikData.values.multiArrayObject)
            }
        }
    }, [formik.values])

    useEffect(() => {
        if (editUpdate) {
            if (
                props.trigger &&
                formikData.values.id &&
                formikData.values.multiArrayObject[props.index] &&
                formikData.values.multiArrayObject[props.index]?.id
            ) {
                // // let img = formikData.values.multiArrayObject[props.index].damage_images
                // //     ? formikData.values.multiArrayObject[props.index].damage_images
                // //     : null
                // formik.setValues({
                //     ...formikData.values.multiArrayObject[props.index],
                // })
                let auction = formikData.values.multiArrayObject[props.index]
                let objectPush = editValue(fieldValue, auction, props.index)
                objectPush.is_deleted = isDeleted ? 1 : 0
                setInitialValues(objectPush)
                setLoad(true)
            } else if (props.trigger) {
                setLoad(true)
                formikData.values.multiArrayObject[props.index] =
                    formikData.values.multiArrayObject[props.index] &&
                    formikData.values.multiArrayObject[props.index].id
                        ? formikData.values.multiArrayObject[props.index]
                        : formik.values
                formikData.setFieldValue('multiArrayObject', formikData.values.multiArrayObject)
            }
        }
    }, [props.trigger, editUpdate])

    const dynamicDelete = {
        formik: formik,
        data: [
            {
                // DO NOT SHOW THIS FOR INDEX 0
                type: 'misc',
                class: `col-12 ${isDeleted ? ' d-none' : ''}`,
                content: (
                    <IconButton onClick={() => onItemArrayHandle(props.index)}>
                        <span className="material-icons">delete</span>
                    </IconButton>
                ),
            },
        ],
    }

    return (
        <>
            {valueLoaded ? (
                <>
                    {mapData({ formik, data: mapDataValue })}
                    {Object.values(mapData(dynamicDelete))}
                </>
            ) : null}
        </>
    )
}

const ItemPropsList = (props) => {
    // console.log(props.formik.values, 'poopppp=====>')
    return (
        <>
            {Array.from(Array(props.formik.values.itemPropStep).keys()).map((_, index) => (
                <>
                    <ItemPropsArray
                        key={'itemProps_' + index}
                        index={index}
                        formikData={props.formik}
                        trigger={props.trigger}
                        resetTrigger={props.resetTrigger}
                        errors={props.errors[index]}
                        quarterValue={props.quarterValue}
                        dynamicData={props.dynamicData}
                        formikDynamic={props.formikDynamic}
                        alldata_all_dropdown={props.alldata_all_dropdown}
                        allCountries={props.allCountries}
                        subStateOptions={props.subStateOptions}
                        subCityOptions={props.subCityOptions}
                        allLocations={props.allLocations}
                    />
                </>
            ))}
        </>
    )
}

export default ItemPropsList
