import React, { useContext, useEffect, useState, useRef } from 'react'
import { mapData, converDate } from '../../../common/components'
import PrimaryButton from '../../atoms/PrimaryButton'
import { Button } from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomDialog from '../../../../custom/components/organisms/Dialog/index'

import Skyflow from 'skyflow-js'
import skyflowClient from '../../../../product/common/skyflowvault'
// import skyflowClient from "../../../common/skyflowvault";
import StripeCardContext from '../../../context/stripe/card/cardContext'
import UserContext from '../../../context/user/userContext'
import AuthContext from '../../../context/auth/authContext'
import ProductCommonContext from '../../../context/common/commonContext'
import StripeCustomerContext from '../../../context/stripe/customer/customerContext'
import AlertContext from '../../../context/alert/alertContext'
import Popup from '../../../../custom/components/organisms/Popup'
import Loaders from '../../../../custom/components/molecules/Loaders'

const container = skyflowClient.container(Skyflow.ContainerType.COLLECT)

const options = {
    required: true,
}

const cardNumberElement = container.create(
    {
        table: process.env.REACT_APP_SKYFLOW_TABLE,
        column: 'card_number',
        inputStyles: {
            base: {
                border: '1px solid #eae8ee',
                padding: '10px 16px',
                borderRadius: '4px',
                color: '#1d1d1d',
                height: '50px',
            },
            complete: {
                color: '#4caf50',
            },
            empty: {},
            focus: {},
            invalid: {
                color: '#f44336',
                padding: '5px 16px',
                fontFamily: 'Arial',
                fontSize: '12px',
            },
        },
        labelStyles: {
            base: {
                fontSize: '12px',
                fontWeight: 'normal',
                fontFamily: 'Arial',
                marginBottom: '10px',
                color: '#7b7b7b',
            },
        },

        errorTextStyles: {
            base: {
                color: '#f44336',
                padding: '5px 16px',
                fontFamily: 'Arial',
                fontSize: '12px',
            },
        },
        placeholder: 'Card Number',
        label: 'Card Number',
        type: Skyflow.ElementType.CARD_NUMBER,
    },
    options,
)

const cardHolderElement = container.create(
    {
        table: process.env.REACT_APP_SKYFLOW_TABLE,
        column: 'cardholder_name',
        inputStyles: {
            base: {
                border: '1px solid #eae8ee',
                padding: '10px 16px',
                borderRadius: '4px',
                color: '#1d1d1d',
                height: '50px',
            },
            complete: {
                color: '#4caf50',
            },
            empty: {},
            focus: {},
            invalid: {
                color: '#f44336',
                padding: '5px 16px',
                fontFamily: 'Arial',
                fontSize: '12px',
            },
        },
        labelStyles: {
            base: {
                fontSize: '12px',
                fontWeight: 'normal',
                fontFamily: 'Arial',
                marginBottom: '10px',
                color: '#7b7b7b',
            },
        },
        errorTextStyles: {
            base: {
                color: '#f44336',
                padding: '5px 16px',
                fontFamily: 'Arial',
                fontSize: '12px',
            },
        },
        placeholder: 'Card Holder name',
        label: 'Card Holder Name',
        type: Skyflow.ElementType.CARDHOLDER_NAME,
    },
    options,
)

const expiryMonElement = container.create(
    {
        table: process.env.REACT_APP_SKYFLOW_TABLE,
        column: 'exp_month',
        inputStyles: {
            base: {
                border: '1px solid #eae8ee',
                padding: '10px 16px',
                borderRadius: '4px',
                color: '#1d1d1d',
                height: '50px',
            },
            complete: {
                color: '#4caf50',
            },
            empty: {},
            focus: {},
            invalid: {
                color: '#f44336',
                padding: '5px 16px',
                fontFamily: 'Arial',
                fontSize: '12px',
            },
        },
        labelStyles: {
            base: {
                fontSize: '12px',
                fontWeight: 'normal',
                fontFamily: 'Arial',
                marginBottom: '10px',
                color: '#7b7b7b',
            },
        },
        errorTextStyles: {
            base: {
                color: '#f44336',
                padding: '5px 16px',
                fontFamily: 'Arial',
                fontSize: '12px',
            },
        },
        placeholder: 'Expiry Month',
        label: 'Expiry Month',
        type: Skyflow.ElementType.EXPIRATION_MONTH,
    },
    options,
)

const expiryYearElement = container.create(
    {
        table: process.env.REACT_APP_SKYFLOW_TABLE,
        column: 'exp_year',
        inputStyles: {
            base: {
                border: '1px solid #eae8ee',
                padding: '10px 16px',
                borderRadius: '4px',
                color: '#1d1d1d',
                height: '50px',
            },
            complete: {
                color: '#4caf50',
            },
            empty: {},
            focus: {},
            invalid: {
                color: '#f44336',
                padding: '5px 16px',
                fontFamily: 'Arial',
                fontSize: '12px',
            },
        },
        labelStyles: {
            base: {
                fontSize: '12px',
                fontWeight: 'normal',
                fontFamily: 'Arial',
                marginBottom: '10px',
                color: '#7b7b7b',
            },
        },
        errorTextStyles: {
            base: {
                color: '#f44336',
                padding: '5px 16px',
                fontFamily: 'Arial',
                fontSize: '12px',
            },
        },
        placeholder: 'Expiry Year',
        label: 'Expiry Year',
        type: Skyflow.ElementType.EXPIRATION_YEAR,
    },
    options,
)

const cardCvvElement = container.create(
    {
        table: process.env.REACT_APP_SKYFLOW_TABLE,
        column: 'cvv',
        inputStyles: {
            base: {
                border: '1px solid #eae8ee',
                padding: '10px 16px',
                borderRadius: '4px',
                color: '#1d1d1d',
                height: '50px',
            },
            complete: {
                color: '#4caf50',
            },
            empty: {},
            focus: {},
            invalid: {
                color: '#f44336',
                padding: '5px 16px',
                fontFamily: 'Arial',
                fontSize: '12px',
            },
        },
        labelStyles: {
            base: {
                fontSize: '12px',
                fontWeight: 'normal',
                fontFamily: 'Arial',
                marginBottom: '10px',
                color: '#7b7b7b',
            },
        },
        errorTextStyles: {
            base: {
                color: '#f44336',
                padding: '5px 16px',
                fontFamily: 'Arial',
                fontSize: '12px',
            },
        },
        placeholder: 'Card CVV',
        label: 'Card CVV',
        type: Skyflow.ElementType.CVV,
    },
    options,
)

function CardDetails(props) {
    const authContext = useContext(AuthContext)
    const productCommonContext = useContext(ProductCommonContext)
    const alertContext = useContext(AlertContext)
    const [loading, setLoading] = useState(false)
    const { setAlert } = alertContext
    const { user, loadUser } = authContext

    const { getPreviousData, clearPreviousData, previous_data, allCountries, allStates } =
        productCommonContext

    const cardNumberRef = useRef(null)
    const cardHolderRef = useRef(null)
    const expiryMonRef = useRef(null)
    const expiryYearRef = useRef(null)
    const cardCvvRef = useRef(null)

    const {
        responseStatus,
        clearResponse,
        addSkyflowCredit,
        getSkyflowCredit,
        deleteSkyflowCredit,
        updateSkyflowCredit,
        defaultSkyflowCard,
        skyflow_cards,
    } = useContext(UserContext)
    const {
        updateStripeCustomer,
        getStripeCustomer,
        customer_details,
        responseStatus: responseStatusStripeCustomer,
    } = useContext(StripeCustomerContext)

    const [disabled, setDisabled] = useState(false)
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const [reload, setReload] = useState(false)
    const [changeStatus, setChangeStatus] = useState(false)
    const [cHolderReady, setCHolderReady] = useState(false)
    const [cNumberReady, setCNumberReady] = useState(false)
    const [cExpMonReady, setCExpMonReady] = useState(false)
    const [cExpYearReady, setCExpYearReady] = useState(false)
    const [cCvvReady, setCvvReady] = useState(false)
    const [expYear, setExpYear] = useState('')
    const [expMonth, setExpMonth] = useState('')
    const creditCardYearOptions = []
    let date = new Date()
    let currentYear = date.getFullYear()
    let upToYear = parseInt(currentYear) + 25
    for (let year = parseInt(currentYear); year < parseInt(upToYear); year++) {
        creditCardYearOptions.push({
            value: year,
            show: year,
        })
    }
    const creditCardMonthOptions = []
    for (let month = parseInt(1); month <= parseInt(12); month++) {
        let monthValue = ('0' + month).slice(-2)
        creditCardMonthOptions.push({
            value: monthValue,
            show: monthValue,
        })
    }

    const submitToSkyflow = async (e) => {
        // e.preventDefault()
        if (!cHolderReady || !cNumberReady || !cExpMonReady || !cExpYearReady || !cCvvReady) {
            setAlert(
                'Invalid Card Details. Please make sure you enter correct card details',
                'error',
            )
            return
        }
        const currYear = new Date().getFullYear().toString().slice(-2)
        const currMon = new Date().getMonth() + 1
        if (expYear === currYear && expMonth < currMon) {
            setAlert(
                'Invalid Expiry Details. Please make sure you enter correct card details',
                'error',
            )
            return
        }
        setDisabled(true)
        try {
            if (cardFormik.values.customer_id !== '') {
                const options = {
                    tokens: true,
                    additionalFields: {
                        records: [
                            {
                                table: process.env.REACT_APP_SKYFLOW_TABLE,
                                fields: {
                                    address_line1: cardFormik.values.card_address_line1,
                                    address_line2: cardFormik.values.card_address_line2,
                                    address_city: cardFormik.values.card_address_city,
                                    address_state: cardFormik.values.card_address_state,
                                    address_country: cardFormik.values.card_address_country,
                                    address_zip: cardFormik.values.card_address_zip,
                                    account_id: cardFormik.values.account_id,
                                    customer_id: cardFormik.values.customer_id,
                                    project: process.env.REACT_APP_SKYFLOW_PROJECT || '',
                                    project_user_id: user.id.toString(),
                                    is_default: false,
                                },
                            },
                        ],
                    },
                }

                const res = await container.collect(options)
                addSkyflowCredit({
                    skyflow_id: res.records[0].fields.skyflow_id,
                    payment_token: true,
                })
            }
        } catch (e) {
            setAlert('something went wrong', 'error')
            console.log('error msg', e)
            throw e
        }
    }

    const validationCard = Yup.object({
        card_address_line1: Yup.string().required('Required!'),
        card_address_line2: Yup.string(),
        card_address_city: Yup.string().required('Required!'),
        card_address_state: Yup.string().required('Required!'),
        card_address_country: Yup.string().required('Required!'),
        card_address_zip: Yup.string().when('validate_zip', {
            is: true,
            then: Yup.string()
                .trim()
                .matches(/^[0-9A-Za-z-]*$/g, 'Only numbers, hyphen and alphabets allowed')
                .max(10, 'Maximum 10 characters')
                .min(4, 'Minimum 4 characters')
                .required('Required!'),
            otherwise: Yup.string().required('Required!'),
        }),

        // phone: Yup.string().matches(phonenumber, 'Phone number is not valid'),
    })

    cardHolderElement.on(Skyflow.EventName.CHANGE, (state) => {
        state.isValid ? setCHolderReady(true) : setCHolderReady(false)
    })

    cardNumberElement.on(Skyflow.EventName.CHANGE, (state) => {
        state.isValid ? setCNumberReady(true) : setCNumberReady(false)
    })

    expiryYearElement.on(Skyflow.EventName.CHANGE, (state) => {
        state.isValid ? setCExpYearReady(true) : setCExpYearReady(false)
        setExpYear(state.value)
    })

    expiryMonElement.on(Skyflow.EventName.CHANGE, (state) => {
        state.isValid ? setCExpMonReady(true) : setCExpMonReady(false)
        setExpMonth(state.value)
    })

    cardCvvElement.on(Skyflow.EventName.CHANGE, (state) => {
        state.isValid ? setCvvReady(true) : setCvvReady(false)
    })

    const cardFormik = useFormik({
        initialValues: {
            account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
            customer_id: user ? user.stripe_id : '',
            card_address_line1: '',
            card_address_line2: '',
            card_address_city: '',
            card_address_state: '',
            card_address_zip: '',
            card_address_country: global.defaultCountry,
            is_primary: '',
            user_id: '',
            id: '',
            card_token: true,
            validate_zip: props.validate_zip ? props.validate_zip : false,
        },
        validationSchema: validationCard,
        onSubmit: (values) => {
            submitToSkyflow()
        },
    })

    const validationupdateCard = Yup.object({
        exp_month: Yup.string().required('Required!'),
        exp_year: Yup.string().required('Required!'),
    })

    const updateCardformik = useFormik({
        initialValues: {
            account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
            skyflow_id: '',
            exp_month: '',
            exp_year: '',
        },
        validationSchema: validationupdateCard,
        onSubmit: async (values) => {
            const { skyflow_id, exp_year, exp_month } = values
            const currentDate = new Date()
            const currentYear = currentDate.getFullYear()
            let currentMonth = currentDate.getMonth() + 1
            let int_exp_month = parseInt(exp_month)
            let int_exp_year = parseInt(exp_year)
            if (
                int_exp_year > currentYear ||
                (int_exp_year === currentYear && int_exp_month >= currentMonth)
            ) {
                setDisabled(true)
                let expiry_year = exp_year.slice(-2)
                updateSkyflowCredit({
                    skyflow_id,
                    expiry_month: exp_month,
                    expiry_year,
                    payment_token: true,
                })
            } else {
                setAlert('Invalid expiry details', 'error')
            }
        },
    })

    const formikSelection = useFormik({
        initialValues: {
            data: '',
            status: '',
        },
        onSubmit: async (values) => {
            const dataValue = values
            if (dataValue.status === 'delete') {
                // deleteStripeCard({
                //     account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                //     customer_id: user.stripe_id,
                //     source_id: props.data.data.id,
                // })
                await deleteSkyflowCredit({ skyflow_id: dataValue.data.id, payment_token: true })
                setChangeStatus(false)
                props.function(false, 'new', null)
            } else if (dataValue.status === 'default') {
                if (props.onVault) {
                    await updateSkyflowCredit({
                        changeDefault: true,
                        skyflow_id: dataValue.data.id,
                    })
                } else {
                    await defaultSkyflowCard({ skyflow_id: dataValue.data.id })
                }
                setChangeStatus(false)
                props.function(false, 'new', null)
            }
        },
    })

    // useEffect(() => {
    //     console.log('here')
    //     if (previous_data) {
    //         if (previous_data.records.length) {
    //             // setAlert(
    //             //     'Removal of Card/ACH is permitted only after 6 weeks from the last bid',
    //             //     'error',
    //             // )
    //             // formikSelection.values.data = ''
    //             // formikSelection.values.status = ''
    //             // clearPreviousData()
    //             // props.function(false, 'new', null)
    //         } else if (formikSelection.values.status) {
    //             setChangeStatus(true)
    //         }
    //     }
    // }, [previous_data])

    useEffect(() => {
        if (props.data.popup && props.data.status === 'new') {
            if (cardNumberRef.current) {
                cardNumberElement.mount('#' + cardNumberRef.current.id)
            }
            if (cardHolderRef.current) {
                cardHolderElement.mount('#' + cardHolderRef.current.id)
            }
            if (expiryMonRef.current) {
                expiryMonElement.mount('#' + expiryMonRef.current.id)
            }
            if (expiryYearRef.current) {
                expiryYearElement.mount('#' + expiryYearRef.current.id)
            }
            if (cardCvvRef.current) {
                cardCvvElement.mount('#' + cardCvvRef.current.id)
            }
        }

        return () => {
            cardNumberElement.clearValue()
            cardHolderElement.clearValue()
            expiryMonElement.clearValue()
            expiryYearElement.clearValue()
            cardCvvElement.clearValue()

            cardNumberElement.unmount()
            cardHolderElement.unmount()
            expiryMonElement.unmount()
            expiryYearElement.unmount()
            cardCvvElement.unmount()
        }
    }, [props.data, reload])

    useEffect(() => {
        if (props.data.data) {
            if (props.data.status === 'delete') {
                formikSelection.values.data = props.data.data
                formikSelection.values.status = props.data.status
                if (skyflow_cards.length > 1) {
                    setChangeStatus(true)
                } else {
                    setAlert('Default card is required', 'error')
                    setChangeStatus(false)
                    props.function(false, 'new', null)
                }
            } else if (props.data.status === 'default') {
                formikSelection.values.data = props.data.data
                formikSelection.values.status = props.data.status
                setChangeStatus(true)
            } else if (props.data.status === 'update') {
                updateCardformik.values.skyflow_id = props.data.data.id
                updateCardformik.values.customer_id = props.data.data.customer
                updateCardformik.values.exp_month = props.data.data.expiry_month
                updateCardformik.values.exp_year = '20' + props.data.data.expiry_year
                setReload(!reload)
            }
        }
    }, [props.data.status])
    useEffect(() => {
        if (user) {
            if (user.stripe_id) {
                cardFormik.setFieldValue('customer_id', user.stripe_id)
            }
        }
    }, [user])

    useEffect(() => {
        if (responseStatus) {
            if (
                responseStatus.from === 'skyflow_add' ||
                responseStatus.from === 'card_source_create_customer_card'
            ) {
                if (responseStatus.status === 'success') {
                    getSkyflowCredit()
                    cardFormik.resetForm()
                    clearResponse()
                    props.function(false, 'new', null)
                }
                setDisabled(false)
            } else if (responseStatus.from === 'skyflow_update') {
                if (responseStatus.status === 'success') {
                    getSkyflowCredit()
                    props.function(false, 'new', null)
                }
            } else if (responseStatus.from === 'skyflow_delete') {
                if (responseStatus.status === 'success') {
                    getSkyflowCredit()
                    props.function(false, 'new', null)
                }
            }
            setLoading(false)
            setDisabled(false)
        }
    }, [responseStatus])

    const getStripeCustomerDetails = () => {
        if (user && user.stripe_id) {
            getStripeCustomer({
                account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                customer_id: user.stripe_id,
            })
        }
    }

    useEffect(() => {
        if (responseStatusStripeCustomer) {
            if (responseStatusStripeCustomer.from === 'update') {
                if (responseStatusStripeCustomer.status === 'success') {
                    getStripeCustomerDetails()
                }
            }
        }
    }, [responseStatusStripeCustomer])

    const cardUpdate = {
        formik: updateCardformik,
        data: [
            {
                label: 'Expiry Month',
                placeholder: 'MM',
                class: 'col-6',
                type: 'select',
                name: 'exp_month',
                options: creditCardMonthOptions,
            },
            {
                label: 'Expiry Year',
                placeholder: 'YYYY',
                class: 'col-6',
                type: 'select',
                name: 'exp_year',
                options: creditCardYearOptions,
            },
        ],
    }

    const cardDetails = {
        formik: cardFormik,
        data: [
            {
                label: 'Card address line 1',
                type: 'text',
                placeholder: 'Enter card address line1',
                class: 'col-12',
                name: 'card_address_line1',
            },
            {
                label: 'Card address line 2',
                type: 'text',
                placeholder: 'Enter card address line2',
                class: 'col-12',
                name: 'card_address_line2',
            },
            {
                label: 'Card address city',
                type: 'text',
                placeholder: 'Enter card address city',
                class: 'col-12',
                name: 'card_address_city',
            },
            // {
            //     label: 'Card address state',
            //     type: 'text',
            //     placeholder: 'Enter card address state',
            //     class: 'col-12',
            //     name: 'card_address_state',
            // },
            // {
            //     label: 'Card address country',
            //     type: 'text',
            //     placeholder: 'Enter card address country',
            //     class: 'col-12',
            //     name: 'card_address_country',
            // },
            {
                label: 'Card address zip',
                type: 'text',
                placeholder: 'Enter card address zip',
                class: 'col-12',
                name: 'card_address_zip',
            },
            {
                label: 'Card address country',
                placeholder: 'Select card address country',
                class: 'col-12',
                type: 'select',
                options: allCountries
                    ? allCountries
                          .filter((country) => global.ignoreCountry.indexOf(country.isoCode) === -1)
                          .map((country) => {
                              let busCategoryChanged = {}
                              busCategoryChanged.show = country.name
                              busCategoryChanged.value = country.isoCode
                              return busCategoryChanged
                          })
                    : [],
                name: 'card_address_country',
            },
            {
                label: 'Card address state',
                placeholder: 'Select card address state',
                class: 'col-12',
                type: 'select',
                options: allStates
                    .filter(
                        (state) =>
                            (cardFormik.values.card_address_country
                                ? cardFormik.values.card_address_country.indexOf(
                                      state.countryCode,
                                  ) !== -1
                                : global.defaultCountry.indexOf(state.countryCode) !== -1) &&
                            global.ignoreStates.indexOf(state.isoCode) === -1,
                    )
                    .map((buscat) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = buscat.name
                        busCategoryChanged.value = buscat.isoCode
                        return busCategoryChanged
                    }),
                name: 'card_address_state',
            },
        ],
    }

    return (
        <>
            <Loaders className="home" isLoading={loading} />
            <Popup
                open={props.data.popup && props.data.status === 'new'}
                size="md"
                handleClose={() => props.function(false, 'new', null)}
                modaltitle="Add Card Details"
                className="skyFlowCardPopup asdasd"
            >
                <div className="cardDetails">
                    <form onSubmit={cardFormik.handleSubmit} autoComplete="nofill">
                        <div className="row">
                            <div className="col-sm-12 col-12 sfInput sfInpBox">
                                <div id="cardholder" ref={cardHolderRef}></div>
                            </div>
                            <div className="col-sm-12 col-12 sfInput sfInpBox">
                                <div id="cardnumber" ref={cardNumberRef} />
                            </div>
                            <div className="col-sm-12 col-12 sfInput sfInpBox">
                                <div id="expmon" ref={expiryMonRef}></div>
                            </div>
                            <div className="col-sm-12 col-12 sfInput sfInpBox">
                                <div id="expyear" ref={expiryYearRef}></div>
                            </div>
                            <div className="col-sm-12 col-12 sfInput sfInpBox">
                                <div id="cardcvv" ref={cardCvvRef}></div>
                            </div>
                            {mapData(cardDetails)}
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <PrimaryButton
                                    disabled={disabled}
                                    id="skyflow_add_card"
                                    btnSize="large"
                                    type="submit"
                                    label={'Save'}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </Popup>

            <CustomDialog
                title={'Confirmation'}
                open={changeStatus}
                function={changeChangeStatus}
                disableBackdropClick
            >
                <h5>
                    {formikSelection.values.status === 'delete'
                        ? 'Are you sure you want to delete this card from your account?'
                        : formikSelection.values.status === 'default'
                        ? 'Are you sure you want to make this default?'
                        : 'Are you sure you want to change the status?'}
                </h5>
                <div className="actionWrapper">
                    <Button onClick={() => setChangeStatus(false)}>Cancel</Button>
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton type="submit" label="Confirm" id="skyflow_card_submit" />
                    </form>
                </div>
            </CustomDialog>

            <Popup
                open={props.data.popup && props.data.status === 'update'}
                handleClose={() => props.function(false, 'update', null)}
                size="md"
                modaltitle="Update Card Details"
            >
                <form onSubmit={updateCardformik.handleSubmit} autoComplete="nofill">
                    <div className="row">
                        {mapData(cardUpdate)}
                        <div className="col-12">
                            <PrimaryButton
                                disabled={disabled}
                                btnSize="large"
                                type="submit"
                                id="update_skyflow_card"
                                label={'Save'}
                            />
                        </div>
                    </div>
                </form>
            </Popup>
        </>
    )
}

export default CardDetails
