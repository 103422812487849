import React, { useState, useContext, useEffect, useRef } from 'react'
import openSocket from 'socket.io-client'
import './Dashboard.css'
import SideNav from './SideNav'
import FilterPanel from '../../custom/components/organisms/FilterPanel'
import GridView from '../../custom/components/molecules/ProductCard/GridView'
import CustomSelect from '../../custom/components/atoms/Inputs/CustomSelect'
import { Button, ListItem } from '@material-ui/core'
import ListView from '../../custom/components/molecules/ProductCard/ListView'
import Drawer from '@material-ui/core/Drawer'
import ProductView from '../../custom/components/organisms/ProductViewComponent'
import ProductContext from '../../product/context/product/productContext'
import AuthContext from '../../product/context/auth/authContext'
import AlertContext from '../../product/context/alert/alertContext'
import { Pagination } from '@material-ui/lab'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'
import { messageHandler } from '@/product/common/socketHandler'
import { useCustomMediaQuery } from '../../custom/common/components'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const MyAuctions = (props) => {
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)

    const classes = useStyles()
    const { t } = useTranslation()
    const toggleBottomDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setState({ ...state, [anchor]: open })
    }
    const {
        search_alldashboardproducts,
        getAllDashboardProducts,
        responseStatus: responseStatusProduct,
    } = productContext
    const { user, isAuthenticated, responseStatus, clearResponse, saveSearch } = authContext

    const isMobile = useMediaQuery({ maxWidth: 600 })
    const isTablet = useMediaQuery({ minWidth: 767, maxWidth: 1024 })
    const smallerDevices = useMediaQuery({ maxWidth: 767 })

    const { setAlert } = alertContext
    const [loading, setLoading] = useState(true)
    const [auctionView, setAuctionView] = useState('List')
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    let [viewProduct, setViewProduct] = useState([])

    const pageOptions = [
        {
            value: '12',
            show: '12',
        },
        {
            value: '24',
            show: '24',
        },
        {
            value: '36',
            show: '36',
        },
    ]

    const sortShow = [
        {
            value: 'p.date_closed,desc',
            show: t('Closing Date (New - Old)'),
        },
        {
            value: 'p.date_closed,asc',
            show: t('Closing Date (Old - New)'),
        },
        {
            value: 'p.wprice,asc',
            show: t('Price (Low - High)'),
        },
        {
            value: 'p.wprice,desc',
            show: t('Price (High - Low)'),
        },
        {
            value: 'it.retail_price,asc',
            show: t('Retail Price (Low - High)'),
        },
        {
            value: 'it.retail_price,desc',
            show: t('Retail Price (High - Low)'),
        },
    ]

    const formik = useFormik({
        initialValues: {
            searchbar: '',
        },
    })

    const [search, setSearch] = useState({
        limit: 120,
        page: 1,
        orderby: 'p.date_closed,desc',
        order: '',
        filters: {
            category: {
                value: [],
                type: 'array',
                field: 'it.categoryTypeId',
            },
            condition: {
                value: [],
                type: 'array',
                field: 'it.conditionTypeId',
            },
            location: {
                value: [],
                type: 'array',
                field: 'p.location_id',
            },
            price: {
                value: '',
                type: 'greaterequal',
                field: 'p.wprice',
            },
            searchbar: {
                value: '',
                type: 'like',
                field: 'it.leadDescription',
            },
            auctionid: {
                value: [],
                type: 'array',
                field: 'p.auctionid',
            },
            starRating: {
                value: [],
                type: 'array',
                field: 'it.starRating',
            },
        },
        action: props.match.params.type,
    })

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setSearch({
                ...search,
                filters: {
                    ...search.filters,
                    searchbar: {
                        ...search.filters.searchbar,
                        value: formik.values.searchbar,
                    },
                },
            })
        }
    }

    useEffect(() => {
        setLoading(true)
        getAllDashboardProducts(search)
    }, [search])

    useEffect(() => {
        if (responseStatusProduct) {
            if (
                responseStatusProduct.from === 'addWatchlist' ||
                responseStatusProduct.from === 'removeWatchlist'
            ) {
                if (props.match.params.type === 'watchlist') {
                    if (responseStatusProduct.status === 'success') {
                        getAllDashboardProducts(search)
                    }
                }
            }
        }
    }, [responseStatusProduct])

    useEffect(() => {
        setSearch({ ...search, action: props.match.params.type })
    }, [props.match.params.type])

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    const initialLoad = useRef(true)
    useEffect(() => {
        if (initialLoad.current) {
            initialLoad.current = false
        } else {
            setLoading(false)
            setViewProduct(
                search_alldashboardproducts.records.length
                    ? search_alldashboardproducts.records
                    : [],
            )
        }
    }, [search_alldashboardproducts])

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, { transports: ['websocket'] })
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        if (state.right) {
            const index = viewProduct.findIndex((s) => s.id == parseInt(state.data.id, 10))
            if (index !== -1) {
                setState({ ...state, data: viewProduct[index] })
            }
        }
    }, [viewProduct])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    const clearSearchFilter = () => {
        setSearch({
            ...search,
            limit: 120,
            page: 1,
            orderby: 'p.date_closed,desc',
            order: '',
            filters: {
                category: {
                    ...search.filters.category,
                    value: [],
                },
                condition: {
                    ...search.filters.condition,
                    value: [],
                },
                location: {
                    ...search.filters.location,
                    value: [],
                },
                price: {
                    ...search.filters.price,
                    value: '',
                },
                searchbar: {
                    ...search.filters.searchbar,
                    value: '',
                },
                auctionid: {
                    ...search.filters.auctionid,
                    value: [],
                },
                starRating: {
                    ...search.filters.starRating,
                    value: [],
                },
            },
        })
        formik.values.searchbar = ''
    }

    const renderHeading = () => {
        switch (props.match.params.type) {
            case 'auction':
                return t('All Auctions')
            case 'watchlist':
                return t('Watchlist')
            case 'winning':
                return t('winning_auctions')
            case 'outbid':
                return t('outbid_auctions')
            case 'won':
                return t('won_auctions')

            default:
                return t('my_auctions')
        }
    }
    return (
        <div className="dashboard customContainer">
            <div className="d-flex justify-content-start">
                <div className="dashboardLt">
                    <div className="respSort">
                        <SideNav />
                    </div>

                    {
                        smallerDevices || isTablet ? (
                            <Drawer
                                anchor="bottom"
                                open={state['bottom']}
                                className="responsiveFilterDrawer"
                                onClose={toggleBottomDrawer('bottom', false)}
                                onOpen={toggleBottomDrawer('bottom', true)}
                            >
                                <div
                                    className={clsx(classes.list, 'bottom')}
                                    role="presentation"
                                    // onClick={toggleBottomDrawer('bottom', false)}
                                    onKeyDown={toggleBottomDrawer('bottom', false)}
                                >
                                    <div>
                                        {props.location.pathname !== '/dashboard/watchlist' && (
                                            <div className="auctionStatus">
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/dashboard/auction"
                                                    >
                                                        {t('all')}
                                                    </NavLink>
                                                </ListItem>

                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/dashboard/winning"
                                                    >
                                                        {t('winning')}
                                                    </NavLink>
                                                </ListItem>

                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/dashboard/outbid"
                                                    >
                                                        {t('currently_outbid')}
                                                    </NavLink>
                                                </ListItem>

                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/dashboard/won"
                                                    >
                                                        {t('won')}
                                                    </NavLink>
                                                </ListItem>
                                            </div>
                                        )}
                                        <h4 className="sbTitle">Sort By</h4>
                                        <div className="searchAuctions">
                                            <div className="d-flex justify-content-start align-items-center flex-wrap">
                                                {sortShow.map((opt, optindex) => (
                                                    <Button
                                                        className={
                                                            search.orderby === opt.value
                                                                ? 'active'
                                                                : null
                                                        }
                                                        onClick={(event, editor) => {
                                                            setSearch({
                                                                ...search,
                                                                orderby: opt.value,
                                                            })
                                                        }}
                                                    >
                                                        {opt.show}
                                                    </Button>
                                                ))}
                                            </div>
                                        </div>
                                        <h4 className="sbTitle">Results Per Page</h4>
                                        <div className="searchAuctions">
                                            <div className="d-flex justify-content-start align-items-center flex-wrap">
                                                {pageOptions.map((opt, optindex) => (
                                                    <>
                                                        <Button
                                                            className={
                                                                search.limit == opt.value
                                                                    ? 'active'
                                                                    : null
                                                            }
                                                            onClick={(event, editor) => {
                                                                setSearch({
                                                                    ...search,
                                                                    limit: opt.value,
                                                                })
                                                            }}
                                                        >
                                                            {opt.show}
                                                        </Button>
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <FilterPanel
                                    setSearch={setSearch}
                                    clearSearch={clearSearchFilter}
                                    sorts={search_alldashboardproducts.sorts}
                                    search={search}
                                    from={1}
                                /> */}
                                </div>
                            </Drawer>
                        ) : null
                        // <div className="deskFilter">
                        //     <FilterPanel
                        //         setSearch={setSearch}
                        //         clearSearch={clearSearchFilter}
                        //         sorts={search_alldashboardproducts.sorts}
                        //         search={search}
                        //         from={1}
                        //     />
                        // </div>
                    }
                </div>
                <div className="dashboardRt">
                    <div className="myAuctions">
                        <div className="d-flex justify-content-between align-items-start w-100 flex-wrap">
                            <div>
                                <h2 className="dashTitle">
                                    {renderHeading()}
                                    <span>
                                        {viewProduct.length ? (
                                            <h6>
                                                {search_alldashboardproducts.totalRecords}{' '}
                                                {props.match.params.type === 'watchlist'
                                                    ? t('items')
                                                    : t('auctions')}
                                            </h6>
                                        ) : null}
                                    </span>
                                </h2>
                            </div>
                            <Button
                                className="toggleRespDrawer"
                                variant="outlined"
                                onClick={toggleBottomDrawer('bottom', true)}
                            >
                                <span className="material-icons">tune</span>Filters
                            </Button>
                            <div className="dashSortActions">
                                <CustomSelect
                                    label={t('results_per_page')}
                                    value={search.limit}
                                    size="small"
                                    name="limit"
                                    shrink={search.limit !== '' ? true : false}
                                    onChange={(event, editor) => {
                                        setSearch({ ...search, limit: event.target.value })
                                    }}
                                >
                                    {pageOptions.map((opt, optindex) => (
                                        <option value={opt.value}>{opt.show}</option>
                                    ))}
                                </CustomSelect>

                                <CustomSelect
                                    label={t('sort_by')}
                                    value={search.orderby}
                                    size="small"
                                    name="orderby"
                                    shrink={search.orderby !== '' ? true : false}
                                    onChange={(event, editor) => {
                                        setSearch({ ...search, orderby: event.target.value })
                                    }}
                                >
                                    {sortShow.map((opt, optindex) => (
                                        <option value={opt.value}>{opt.show}</option>
                                    ))}
                                </CustomSelect>
                            </div>
                        </div>

                        {/* {props.location.pathname !== '/dashboard/watchlist' && (
                            <div className="auctionStatus">
                                <ListItem button>
                                    <NavLink activeClassName="active" to="/dashboard/auction">
                                        {t('all')}
                                    </NavLink>
                                </ListItem>

                                <ListItem button>
                                    <NavLink activeClassName="active" to="/dashboard/winning">
                                        {t('winning')}
                                    </NavLink>
                                </ListItem>

                                <ListItem button>
                                    <NavLink activeClassName="active" to="/dashboard/outbid">
                                        {t('currently_outbid')}
                                    </NavLink>
                                </ListItem>

                                <ListItem button>
                                    <NavLink activeClassName="active" to="/dashboard/won">
                                        {t('won')}
                                    </NavLink>
                                </ListItem>
                            </div>
                        )} */}

                        {!loading ? (
                            viewProduct.length ? (
                                <div>
                                    <div className="dashboardResults">
                                        {viewProduct.map((data, index) => (
                                            <GridView
                                                key={index}
                                                data={data}
                                                favId={`searchProd_${index}`}
                                                drawerHandler={toggleDrawer('right', true, data)}
                                            />
                                        ))}
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center flex-wrap w-100 mb-3 pagination-wrapper">
                                        <h6>
                                            {t('showing')} {search_alldashboardproducts.setDisp}{' '}
                                            {t('of')} {search_alldashboardproducts.totalRecords}
                                        </h6>
                                        <Pagination
                                            count={Math.ceil(
                                                search_alldashboardproducts.totalRecords /
                                                    search.limit,
                                            )}
                                            page={search.page}
                                            onChange={onHandlePage}
                                            siblingCount={3}
                                            showFirstButton
                                            showLastButton
                                            boundaryCount={2}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="np-pro-box">
                                    <img src="/assets/svg/noResults.svg" alt="No results image" />
                                    <h5>{t('No Records Found!')}</h5>
                                </div>
                            )
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyAuctions
