import React, { useState, useContext, useEffect, useRef } from 'react'
import './invoice.css'
import CartItem from '@/custom/components/molecules/ProductCard/CartItem'
import AuthContext from '@/product/context/auth/authContext'
import InvoiceContext from '@/product/context/invoice/invoiceContext'
import CustomCommonContext from '@/custom/context/common/commonContext'

import {
    dateFormatFront,
    dateTimeFormatFunction,
    currencyFormat,
    capitalize,
} from '@/custom/common/components'
import { Button } from '@material-ui/core'
import { LOGO, SITE_NAME } from '../../Utils/UI/index'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CommonContext from '@/product/context/common/commonContext'
import bwipjs from 'bwip-js'
import Loaders from '@/custom/components/molecules/Loaders'
import Barcode from './barcode'
import QRCode from 'react-qr-code'

const Invoice = (props) => {
    const authContext = useContext(AuthContext)
    const invoiceContext = useContext(InvoiceContext)
    const customCommonContext = useContext(CustomCommonContext)
    const commonContext = useContext(CommonContext)
    const { static_page, getStaticPage } = commonContext
    const { t } = useTranslation()
    const { allConfigurationVariables, currentLanguage } = customCommonContext

    const { search_allinvoiceproducts, getAllInvoiceProducts } = invoiceContext
    const { isAuthenticated, user } = authContext
    const history = useHistory()
    const [printId, setPrintId] = useState({ id: '0' })
    const [isLoading, setIsLoading] = useState(true)

    const [search, setSearch] = useState({
        invoice_id: props.match.params.id,
        filters: {
            active: {
                type: 'notin',
                field: 'b.active',
                value: 2,
            },
        },
    })

    let [invoiceDetails, setInvoiceDetails] = useState({
        invoiceProducts: [],
        cartValues: {},
        appointmentDetail: {},
        locationDetail: {},
        invoiceTransactions: [],
        invoiceUser: [],
        invoiceAddress: [],
    })

    useEffect(() => {
        if (isAuthenticated) {
            getAllInvoiceProducts(search)
            setIsLoading(true)
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (props.match.params.id) {
            setPrintId({ id: props.match.params.id })
        }
    }, [props.match.params.id])

    useEffect(() => {
        setInvoiceDetails({
            invoiceProducts: search_allinvoiceproducts.invoicerecords.length
                ? search_allinvoiceproducts.invoicerecords
                : [],
            cartValues: search_allinvoiceproducts.cartvalues,
            appointmentDetail: search_allinvoiceproducts.appointmentrecord,
            locationDetail: search_allinvoiceproducts.locationrecord,
            invoiceTransactions: search_allinvoiceproducts.transactionrecords,
            invoiceUser: search_allinvoiceproducts.invoiceuserrecords,
            invoiceAddress: search_allinvoiceproducts.invoiceaddressrecord,
        })
        // setIsLoading(false)
    }, [search_allinvoiceproducts])

    useEffect(() => {
        if (invoiceDetails?.invoiceProducts?.length) {
            setIsLoading(false)
        }
    }, [invoiceDetails?.invoiceProducts])

    useEffect(() => {
        setInvoiceDetails({
            invoiceProducts: [],
            cartValues: {},
            appointmentDetail: {},
            locationDetail: {},
            invoiceTransactions: [],
            invoiceUser: [],
            invoiceAddress: [],
        })
        return () => {
            setInvoiceDetails({
                invoiceProducts: [],
                cartValues: {},
                appointmentDetail: {},
                locationDetail: {},
                invoiceTransactions: [],
                invoiceUser: [],
                invoiceAddress: [],
            })
        }
    }, [])

    return (
        <>
            {isLoading ? (
                <Loaders name={'home'} isLoading={isLoading} />
            ) : (
                <div className="container-lg productInvoice">
                    <h3 className="invoiceTitle">{t('invoice')}</h3>
                    <div className="d-flex justify-content-between align-items-center mb-2 noPrint">
                        {/* <Breadcrumbs aria-label="breadcrumb" className="naBreadcrumbs">
                        <Link to="/dashboard/won">Dashboard</Link>
                        <Typography color="textPrimary">Invoice</Typography>
                    </Breadcrumbs> */}
                        <Button
                            id="invoice_arrow_back"
                            onClick={() => {
                                history.goBack()
                                window.scrollTo(0, 0)
                            }}
                            className="moveBack"
                        >
                            <span className="material-icons">arrow_back</span>
                            {t('back')}
                        </Button>
                        <Button id="invoice_print_new" onClick={() => window.print()}>
                            <span className="material-icons">print</span>
                            {t('print')}
                        </Button>
                    </div>
                    <div className={`invoiceView`} style={{ height: '100%', width: '100%' }}>
                        <div className="card-header">
                            <div className="invTop">
                                <img
                                    src={LOGO}
                                    className="invLogo"
                                    alt={SITE_NAME}
                                    style={{ width: '60px' }}
                                />
                                {/* <p className="text-dark  mt-2 mb-0">{SITE_NAME}</p> */}

                                <div className="d-block myCanvas">
                                    {/* <Barcode data={invoiceDetails.cartValues.common_invoice} /> */}
                                    <QRCode
                                        size={126}
                                        // style={{
                                        //     height: 'auto',
                                        //     maxWidth: '100%',
                                        //     width: '100%',
                                        // }}
                                        value={`${process.env.REACT_APP_FRONTEND_URL}invoice/${
                                            props.match.params.id
                                        }/${user ? user.id : 0}`}
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>
                                {/* <h2 className="text-right taxInvoiceHeader">TAX INVOICE</h2> */}
                                {/* <div>
                                {'Address of jafza zone'} <br />
                                {'Address of jafza zone line 2'}
                            </div>
                            <div>
                                {'Phone'} ({'Call within uae'}): 04 889 7585
                            </div>
                            <div>
                                {'Phone'} ({'International'}): +971 4 889 7585
                            </div> */}
                            </div>
                        </div>

                        {/* <div className="float-right invDate">
                    <h5 className="mb-0">
                        {t('invoice')} #{invoiceDetails.cartValues.common_invoice}{' '}
                    </h5>
                    <h5 className="mb-0">
                        {t('date')}: {dateFormatFront(invoiceDetails.cartValues.cart_paiddate)}
                    </h5>
                    <h5 className="mb-0">
                        {t('status')}:{' '}
                        {invoiceDetails.cartValues.cart_paid ? t('paid') : t('unpaid')}
                    </h5>
                </div> */}
                        <div className="card-body">
                            <div className="row mb-4">
                                {invoiceDetails.invoiceUser?.length ? (
                                    <div
                                        className={`col-md-6 invAddr ${
                                            currentLanguage === 'for_arabic'
                                                ? 'text-right'
                                                : 'text-left'
                                        }`}
                                    >
                                        <p className="textBold">{t('Customer Details')}</p>

                                        <>
                                            {invoiceDetails.invoiceUser?.length &&
                                            invoiceDetails.invoiceAddress?.length ? (
                                                <>
                                                    <p className="cstmrNameTxt">
                                                        {
                                                            invoiceDetails.invoiceAddress[0]
                                                                .first_name
                                                        }{' '}
                                                        {invoiceDetails.invoiceAddress[0].last_name}
                                                    </p>
                                                    {invoiceDetails.invoiceAddress[0].city &&
                                                        invoiceDetails.invoiceAddress[0].state &&
                                                        invoiceDetails.invoiceAddress[0]
                                                            .country && (
                                                            <>
                                                                <p>
                                                                    {
                                                                        invoiceDetails
                                                                            ?.invoiceAddress[0]
                                                                            ?.address1
                                                                    }
                                                                </p>
                                                                <p>
                                                                    {
                                                                        invoiceDetails
                                                                            ?.invoiceAddress[0]
                                                                            ?.address2
                                                                    }
                                                                </p>
                                                                <p>
                                                                    {
                                                                        invoiceDetails
                                                                            .invoiceAddress[0].city
                                                                    }
                                                                    ,{' '}
                                                                    {
                                                                        invoiceDetails
                                                                            .invoiceAddress[0].state
                                                                    }
                                                                    ,{' '}
                                                                    {
                                                                        invoiceDetails
                                                                            .invoiceAddress[0]
                                                                            .country
                                                                    }{' '}
                                                                    -{' '}
                                                                    {
                                                                        invoiceDetails
                                                                            .invoiceAddress[0].zip
                                                                    }
                                                                </p>
                                                            </>
                                                        )}
                                                </>
                                            ) : (
                                                <>
                                                    <p className="cstmrNameTxt">
                                                        {invoiceDetails.invoiceUser[0].first_name}{' '}
                                                        {invoiceDetails.invoiceUser[0].last_name}
                                                    </p>
                                                    {invoiceDetails.invoiceUser[0].city &&
                                                        invoiceDetails.invoiceUser[0].state &&
                                                        invoiceDetails.invoiceUser[0].country && (
                                                            <p>
                                                                {invoiceDetails.invoiceUser[0].city}
                                                                ,{' '}
                                                                {/* {invoiceDetails.invoiceUser[0].state},{' '} */}
                                                                <br />
                                                                {
                                                                    invoiceDetails.invoiceUser[0]
                                                                        .country
                                                                }{' '}
                                                                -{' '}
                                                                {invoiceDetails.invoiceUser[0].zip}
                                                            </p>
                                                        )}
                                                </>
                                            )}
                                            {invoiceDetails.invoiceUser?.length ? (
                                                <p>
                                                    <span className="textBold">{t('email')} :</span>
                                                    <span>
                                                        {invoiceDetails.invoiceUser[0].email}
                                                    </span>
                                                </p>
                                            ) : null}
                                        </>
                                    </div>
                                ) : null}
                                <div className="col-md-6 invoice-right">
                                    <div className="row">
                                        <div className="col-5">
                                            <p className="m-0 textBold">{t('invoice_number')}</p>
                                        </div>
                                        <div className="col-1 textBold">:</div>
                                        <div className="col-6">
                                            <p className="m-0">
                                                {invoiceDetails.cartValues.common_invoice}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-5">
                                            <p className="m-0 textBold">{t('date')}</p>
                                        </div>
                                        <div className="col-1 textBold">:</div>
                                        <div className="col-6">
                                            <p className="m-0">
                                                {dateFormatFront(
                                                    invoiceDetails.cartValues.cart_paiddate,
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-5">
                                            <p className="m-0 textBold">{t('status')}</p>
                                        </div>
                                        <div className="col-1 textBold">:</div>
                                        <div className="col-6">
                                            <p className="m-0">
                                                {invoiceDetails.cartValues.cart_paid
                                                    ? t('paid')
                                                    : t('unpaid')}
                                            </p>
                                        </div>
                                    </div>
                                    {invoiceDetails.invoiceUser?.length ? (
                                        <div className="row">
                                            <div className="col-5">
                                                <p className="m-0 textBold">{t('phone')}</p>
                                            </div>
                                            <div className="col-1 textBold">:</div>
                                            <div className="col-6">
                                                <p className="m-0">
                                                    {invoiceDetails.invoiceUser[0].phone}
                                                </p>
                                            </div>

                                            {/* <div className="col-md-5">
                                                    <p className="m-0 textBold">Email</p>
                                                </div>
                                                <div className="col-md-1 textBold">:</div>
                                                <div className="col-md-6">
                                                    <p className="m-0">
                                                        {invoiceDetails.invoiceUser[0].email}
                                                    </p>
                                                </div> */}
                                        </div>
                                    ) : null}
                                </div>
                                {/* {invoiceDetails.invoiceUser?.length ? (
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <p className="textBold d-flex justify-content-between">
                                                    Phone
                                                </p>
                                            </div>
                                            <div className="col-md-1 textBold">:</div>
                                            <div className="col-md-6">
                                                <p>{invoiceDetails.invoiceUser[0].phone}</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : null} */}
                                {/* {invoiceDetails.appointmentDetail ? (
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-5">
                                        <p className="m-0 textBold">Appointment</p>
                                    </div>
                                    <div className="col-md-1 textBold">:</div>
                                    <div className="col-md-6">
                                        <p className="m-0">
                                            {dateTimeFormatFunction(
                                                invoiceDetails.appointmentDetail.appointmentTime,
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : null} */}
                            </div>
                            {/* {invoiceDetails.appointmentDetail ? (
                        <>
                            <h4 className="invoiceSubTitle">
                                {t('appointment_scheduled_at')}:{' '}
                                {dateTimeFormatFunction(
                                    invoiceDetails.appointmentDetail.appointmentTime,
                                )}
                            </h4>
                        </>
                    ) : null} */}
                            {invoiceDetails.invoiceTransactions.length ? (
                                <>
                                    <h4 className="invoiceSubTitle">{t('Transaction Details')}</h4>
                                    <div className="transDet d-flex justify-content-between flex-wrap">
                                        <>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        {/* <th scope="col">S.No</th> */}
                                                        <th scope="col">{t('transactionid')}</th>
                                                        <th scope="col">{t('paidon')}</th>
                                                        <th scope="col">{t('methodd')}</th>
                                                        <th scope="col">{t('amount')}</th>
                                                        <th scope="col">{t('status')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {invoiceDetails.invoiceTransactions.map(
                                                        (data, index) => (
                                                            <tr key={index}>
                                                                <td data-title={'Transaction id'}>
                                                                    {data.trans_id
                                                                        ? `# ${data.trans_id}`
                                                                        : '-'}
                                                                </td>
                                                                <td data-title={'Paid on'}>
                                                                    {dateTimeFormatFunction(
                                                                        data.created_at,
                                                                    )}
                                                                </td>
                                                                <td data-title={'Card'}>
                                                                    {data.payment_method ===
                                                                    'cash' ? (
                                                                        t('cash')
                                                                    ) : (
                                                                        <>
                                                                            {/* {data.mk_card_number} (
                                                                    {data.card_type}) */}
                                                                            {t(
                                                                                capitalize(
                                                                                    data.payment_method,
                                                                                ),
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </td>
                                                                <td data-title={'Amount'}>
                                                                    {currencyFormat(data.amount, t)}
                                                                </td>
                                                                <td
                                                                    data-title={'Payment status'}
                                                                    style={{
                                                                        color:
                                                                            data.status ===
                                                                            'success'
                                                                                ? 'green'
                                                                                : 'red',
                                                                    }}
                                                                >
                                                                    {t(capitalize(data.status))}
                                                                </td>
                                                            </tr>
                                                        ),
                                                    )}
                                                </tbody>
                                            </table>
                                        </>
                                    </div>
                                </>
                            ) : null}
                            <h4 className="invoiceSubTitle mt-5">{t('product_details')}</h4>
                            {/* invoiceDetails.invoiceProducts.length >= 3 ? (pageBreakAfter:"always"):null */}
                            <div
                                className={`table-responsive ${
                                    invoiceDetails.invoiceProducts.length > 2 && 'invoicePageBreak'
                                }`}
                            >
                                {invoiceDetails.invoiceProducts.length ? (
                                    <div className="cartProducts">
                                        <div>
                                            {invoiceDetails.invoiceProducts.map((data, index) => (
                                                <CartItem
                                                    data={data}
                                                    from="invoice"
                                                    showLocation={true}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="row mt-5">
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table className="table table-clear nellisInvTot">
                                            <tbody>
                                                <tr>
                                                    <td className="left" align="right">
                                                        <span className="text-dark">
                                                            {t('items')}
                                                        </span>
                                                    </td>
                                                    <td className="right">
                                                        <span className="text-dark">
                                                            {invoiceDetails.invoiceProducts.length}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="left" align="right">
                                                        <span className="text-dark">
                                                            {t('subtotal')}
                                                        </span>
                                                    </td>
                                                    <td className="right">
                                                        <span className="text-dark">
                                                            {currencyFormat(
                                                                invoiceDetails.cartValues.per_total,
                                                                t,
                                                            )}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="left" align="right">
                                                        <span className="text-dark">
                                                            {t('tax')}
                                                            {invoiceDetails.invoiceProducts
                                                                .length ? (
                                                                <>
                                                                    {' '}
                                                                    (
                                                                    {
                                                                        invoiceDetails
                                                                            .invoiceProducts[0]
                                                                            .tax_percent
                                                                    }
                                                                    %)
                                                                </>
                                                            ) : null}
                                                        </span>
                                                    </td>
                                                    <td className="right">
                                                        <span className="text-dark">
                                                            {currencyFormat(
                                                                invoiceDetails.cartValues.total_tax,
                                                                t,
                                                            )}
                                                        </span>
                                                    </td>
                                                </tr>
                                                {invoiceDetails.cartValues
                                                    .total_invoice_shipping ? (
                                                    <tr>
                                                        <td className="left" align="right">
                                                            <span className="text-dark">
                                                                {t('Shipping')}
                                                            </span>
                                                        </td>
                                                        <td className="right">
                                                            <span className="text-dark">
                                                                {currencyFormat(
                                                                    invoiceDetails.cartValues
                                                                        .total_invoice_shipping,
                                                                    t,
                                                                )}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ) : null}

                                                {invoiceDetails?.cartValues?.transfer_fee ? (
                                                    <tr>
                                                        <td className="left" align="right">
                                                            <span className="text-dark">
                                                                {t('Transfer Fee')}
                                                            </span>
                                                        </td>
                                                        <td className="right">
                                                            <span className="text-dark">
                                                                {currencyFormat(
                                                                    invoiceDetails.cartValues
                                                                        .transfer_fee,
                                                                    t,
                                                                )}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ) : null}
                                                <tr>
                                                    <td className="left" align="right">
                                                        <span className="text-dark">
                                                            {t('platform_commission')}
                                                            {invoiceDetails.invoiceProducts
                                                                .length ? (
                                                                <>
                                                                    {' '}
                                                                    (
                                                                    {
                                                                        invoiceDetails
                                                                            .invoiceProducts[0]
                                                                            .buyer_premium_percent
                                                                    }
                                                                    % )
                                                                </>
                                                            ) : null}
                                                        </span>
                                                    </td>
                                                    <td className="right">
                                                        <span className="text-dark">
                                                            {currencyFormat(
                                                                invoiceDetails.cartValues
                                                                    .total_premium,
                                                                t,
                                                            )}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="left" align="right">
                                                        <span className="text-dark">
                                                            {t('total_amount')}
                                                        </span>
                                                    </td>
                                                    <td className="right">
                                                        <span className="text-dark">
                                                            {currencyFormat(
                                                                invoiceDetails.cartValues
                                                                    .total_amount,
                                                                t,
                                                            )}
                                                        </span>
                                                    </td>
                                                </tr>
                                                {invoiceDetails.cartValues.total_deposit ? (
                                                    <tr>
                                                        <td className="left" align="right">
                                                            <span className="text-dark">
                                                                {t('Bid deposit')}
                                                            </span>
                                                        </td>
                                                        <td className="right">
                                                            <span className="text-dark">
                                                                -{' '}
                                                                {currencyFormat(
                                                                    invoiceDetails.cartValues
                                                                        .total_deposit,
                                                                    t,
                                                                )}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ) : null}

                                                {invoiceDetails.cartValues.total_paid ? (
                                                    <tr>
                                                        <td className="left" align="right">
                                                            <span className="text-dark">
                                                                {t('total_paid')}
                                                            </span>
                                                        </td>
                                                        <td className="right">
                                                            <span className="text-dark">
                                                                {currencyFormat(
                                                                    invoiceDetails.cartValues
                                                                        .total_paid,
                                                                    t,
                                                                )}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ) : null}

                                                {invoiceDetails.cartValues.total_due_amount ? (
                                                    <tr>
                                                        <td className="left" align="right">
                                                            <span className="text-dark">
                                                                {t('Pending amount')}
                                                            </span>
                                                        </td>
                                                        <td className="right">
                                                            <span className="text-dark">
                                                                {currencyFormat(
                                                                    invoiceDetails.cartValues
                                                                        .total_due_amount,
                                                                    t,
                                                                )}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ) : null}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4 className="thankText">{t('thank_you_for_shopping_with_us')}</h4>
                </div>
            )}
        </>
    )
}

export default Invoice
