import {
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_FETCH_MESSAGES,
    SEARCH_MESSAGES,
    SEARCH_ACTUAL_MESSAGES,
    SEARCH_ARCHIVE_MESSAGES,
} from './messageTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case SEARCH_MESSAGES: {
            return {
                ...state,
                all_project_messages: action.payload,
            }
        }
        case SEARCH_ARCHIVE_MESSAGES: {
            return {
                ...state,
                all_archive_messages: action.payload,
            }
        }
        case SEARCH_ACTUAL_MESSAGES: {
            return {
                ...state,
                all_actual_messages: action.payload,
            }
        }
        case GET_FETCH_MESSAGES:
            return {
                ...state,
                all_messages: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
