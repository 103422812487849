import React, { useContext, useState, useEffect } from 'react'
import CustomInput from '../../product/components/atoms/Inputs/CustomInput'
import CustomSelect from '../../product/components/atoms/Inputs/CustomSelect'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import './Dashboard.css'
import DashboardLayout from './DashboardLayout'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { mapData } from '../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthContext from '../../product/context/auth/authContext'
import UserContext from '../../product/context/user/userContext'
import DynamicContext from '@/product/context/dynamic/dynamicContext'
import CommonContext from '../../custom/context/common/commonContext'
import PhoneValidation from '../Registration/phoneValidation'
import ProfileComponent from '@/product/components/species/dynamic/Profile/ProfileComponent'
import { useTranslation } from 'react-i18next'
import { Divider } from '@material-ui/core'
const MyAccount = () => {
    const authContext = useContext(AuthContext)

    const userContext = useContext(UserContext)
    const commonContext = useContext(CommonContext)
    const { USStates, allNotifications } = commonContext
    const { user, loadUser } = authContext

    const { updateProfile, updatePreference, responseStatus: responseStatusUser } = userContext

    const [phoneVerify, setPhoneVerify] = React.useState(false)

    const changePhoneVerify = () => {
        setPhoneVerify(!phoneVerify)
    }
    const { t } = useTranslation()
    const dynamicContext = useContext(DynamicContext)

    const { getAllDynamicArray, dynamicValue } = dynamicContext

    const [formikChangeValues, setFormikChangeValues] = useState({
        country: global.defaultCountry,
    })
    const [customValidation, setCustomValidation] = useState([])
    const [customValidationSeller, setCustomValidationSeller] = useState([])
    const [notificationIntialValue, setNotificationInitialValue] = useState({
        email_prefer: [],
        sms_prefer: [],
    })

    const [customInitialSeller, setCustomInitialSeller] = useState([])
    const [customInitialBuyer, setCustomInitialBuyer] = useState([])
    const [fieldValue, setFieldValue] = useState([])
    const [sellerValue, setSellerValue] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [emailNotify, setEmailNotify] = useState([])
    const [smslNotify, setSmsNotify] = useState([])

    const validationArray = Yup.object({
        first_name: Yup.string()
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .required('Required!'),
        last_name: Yup.string()
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .required('Required!'),
        address1: Yup.string().required('Required!'),
        phone: Yup.string()
            .test('isValid', 'Invalid Phone Number', (value) => {
                return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(value)
            })
            .required('Required!'),
        city: Yup.string().required('Required!'),
        state: Yup.string().required('Required!'),
        zip: Yup.string()
            .min(4, 'Invalid Zip Code')
            .max(8, 'Invalid Zip Code')
            .required('Required!'),
    })

    const validationPassword = Yup.object({
        change_password: Yup.boolean(),
        current_password: Yup.string().min(8, t('Minimum 8 characters')).required(t('Required!')),
        new_password: Yup.string()
            .min(8, t('Minimum 8 characters'))
            .required(t('Required!'))
            .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]/,
                t('Must contain One Uppercase and One Lowercase and One Number'),
            )
            .notOneOf(
                [Yup.ref('current_password')],
                t('New password must not be the same as the current password'),
            ),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('new_password')], t('Password not match'))
            .required(t('Required!')),
    })

    const formikChange = useFormik({
        initialValues: {
            change_password: 1,
            current_password: '',
            new_password: '',
            confirm_password: '',
        },
        validationSchema: validationPassword,
        onSubmit: (values) => {
            updateProfile(values)
        },
    })

    const formikPreference = useFormik({
        initialValues: notificationIntialValue,
        onSubmit: (values) => {
            updatePreference(values)
        },
    })
    const emailPreference = [
        {
            name: 'email_prefer',
            type: 'checkboxarray',
            class: 'col-12 notifElements',
            item: emailNotify,
            formik: formikPreference,
        },
    ]
    useEffect(() => {
        if (allNotifications) {
            let arr = JSON.parse(JSON.stringify(allNotifications))
            let eNotify = []
            let sNotify = []
            arr.map((d) => {
                if (d.sms === 1) {
                    d.description = t(d.description)
                    sNotify.push(d)
                }

                if (d.email === 1) {
                    d.description = t(d.description)
                    eNotify.push(d)
                }
            })

            setSmsNotify(sNotify)
            setEmailNotify(eNotify)
        }
    }, [allNotifications, localStorage.currentLanguage])
    const smsPreference = [
        {
            name: 'sms_prefer',
            type: 'checkboxarray',
            class: 'col-12 notifElements',
            item: smslNotify,
            formik: formikPreference,
        },
    ]

    const changePassword = [
        {
            label: t('current_password'),
            name: 'current_password',
            type: 'password',
            placeholder: t('enter_your_current_password'),
            class: 'col-md-6 col-sm-12',
            formik: formikChange,
        },
        {
            label: t('new_password'),
            name: 'new_password',
            type: 'password',
            placeholder: t('Enter your new password'),
            class: 'col-md-6 col-sm-12',
            formik: formikChange,
        },
        {
            label: t('confirm_password'),
            name: 'confirm_password',
            type: 'password',
            placeholder: t('re-enter_your_password'),
            class: 'col-md-6 col-sm-12',
            formik: formikChange,
        },
    ]

    useEffect(() => {
        if (responseStatusUser) {
            if (
                responseStatusUser.from === 'updateProfile' ||
                responseStatusUser.from === 'updatePreference'
            ) {
                if (responseStatusUser.status === 'success') {
                    loadUser()
                    formikChange.handleReset()
                }
            }
        }
    }, [responseStatusUser])

    useEffect(() => {
        const val = notificationIntialValue
        let flag = false
        if (user && user.email_notification) {
            val.email_prefer = user.email_notification.split(',')
            flag = true
        }
        if (user && user.sms_notification) {
            val.sms_prefer = user.sms_notification.split(',')
        }
        if (flag) {
            setNotificationInitialValue(val)
        }
    }, [user])

    const additionalFieldUpdate = (payload) => {
        setFormikChangeValues(payload)
    }

    useEffect(() => {
        setIsLoading(true)
        getAllDynamicArray(
            {
                page: 1,
                limit: 20,
                order: 'asc',
                orderby: 'df.show_order',
                table: 'dynamic_fields',
                filters: {
                    action: {
                        value: 3,
                        type: 'in',
                        field: 'df.view_page',
                    },
                    forOrganization: {
                        value: 1,
                        type: 'in',
                        field: 'df.for_organization',
                    },
                },
            },
            'forOrganizationProfile',
        )
        getAllDynamicArray(
            {
                page: 1,
                limit: 20,
                order: 'asc',
                orderby: 'df.show_order',
                table: 'dynamic_fields',
                filters: {
                    action: {
                        value: 3,
                        type: 'in',
                        field: 'df.view_page',
                    },
                    forBuyer: {
                        value: 1,
                        type: 'in',
                        field: 'df.for_buyer',
                    },
                },
            },
            'forBuyerProfile',
        )
    }, [])

    useEffect(() => {
        console.log(dynamicValue, 'dynamicValueAssign123')

        if (dynamicValue.from === 'forBuyerProfile') {
            if (dynamicValue.records) {
                let dynamicValueAssign = []
                let modifyArr = JSON.parse(JSON.stringify(dynamicValue.records))
                modifyArr
                    .filter(
                        (item) =>
                            item.name !== 'password' &&
                            item.name !== 'confirm_password' &&
                            item.name !== 'add_country',
                    )
                    .map((item) => {
                        item.label = t(item.label)
                        item.placeholder = t(item.placeholder)
                        if (item.name == 'email') {
                            item.disabled = true
                        }
                        if (item.name == 'country') {
                            item.disabled = true
                        }
                        if (item.name == 'profile_image') {
                            item.avatarMode = true
                        }
                        // if (item.name === 'phone') {
                        //     item.disabled = true
                        // }
                        dynamicValueAssign.push(item)
                    })

                setFieldValue(dynamicValueAssign)
                console.log(dynamicValueAssign, 'dynamicValu ram')
                setIsLoading(false)
            }
        }
    }, [dynamicValue, localStorage?.i18nextLng])

    useEffect(() => {
        let customValidationTemp = {}
        let customInitial = {
            admin: 0,
            country: 'SA',
            zip: '',
        }
        let customOrgInitial = {
            admin: 1,
            organization: 1,
        }
        customValidationTemp = {
            email: Yup.string()
                .email(t('Invalid Email Format'))
                .max(250, t('Maximum 250 Characters'))
                .required(t('Required')),
            password: Yup.string().min(8, t('Minimum 8 Characters')).required(t('Required')),
            confirm_password: Yup.string()
                .oneOf([Yup.ref('password')], t('Password does not match'))
                .required('Required'),
            first_name: Yup.string()

                .min(2, t('Mininum 2 characters'))
                .max(30, t('Maximum 30 characters'))
                .required(t('Required!')),
            last_name: Yup.string()

                .min(1, t('Mininum 1 characters'))
                .max(30, t('Maximum 30 characters'))
                .required(t('Required!')),
            phone: Yup.string()
                .trim()
                .nullable()
                .matches(/^[0-9- +()]*$/g, t('Only numbers are allowed'))
                .required(t('Required!')),
            zip: Yup.string()
                .trim()
                .matches(/^[0-9]*$/g, t('Positive Numbers only'))
                .max(6, t('Maximum 6 characters'))
                .required(t('Required')),
            city: Yup.string()
                .trim()

                .required(t('Required')),
        }
        setCustomInitialSeller(customOrgInitial)
        setCustomInitialBuyer(customInitial)
        setCustomValidation(customValidationTemp)
    }, [])

    return (
        <DashboardLayout>
            <div className="myAccount">
                <div className="accountCnt">
                    <>
                        <h2 className="dashTitle">{t('my_account')}</h2>
                        <div className="accChild">
                            <h4>{t('profile_details')}</h4>
                            <ProfileComponent
                                fieldValue={fieldValue}
                                customInitial={customInitialBuyer}
                                customValidation={customValidation}
                                additionalfieldUpdate={additionalFieldUpdate}
                                phoneVerify={true}
                                SubmitBtnLabel={t('save')}
                                initialDefaultEmptyValues={['zip']}
                            />
                        </div>
                    </>
                    <Divider className="maDivider" />

                    <form onSubmit={formikChange.handleSubmit} autoComplete="nofill">
                        <div className="accChild">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4>{t('change_password')}</h4>
                            </div>
                            <div className="row">{Object.values(mapData(changePassword))}</div>
                        </div>
                        <PrimaryButton type="submit" label={t('change')} />
                    </form>
                    <Divider className="maDivider" />
                    <form onSubmit={formikPreference.handleSubmit} autoComplete="nofill">
                        <div className="notifTable">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4>{t('Notification Preference')}</h4>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h6>{t('email')}</h6>
                                    <div className="row">
                                        {Object.values(mapData(emailPreference))}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h6>{t('SMS ')}</h6>
                                    <div className="row">
                                        {Object.values(mapData(smsPreference))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <PrimaryButton type="submit" label={t('save')} />
                    </form>
                </div>
                {/* <PhoneValidation
                    phoneVerify={phoneVerify}
                    formik={formik}
                    setPhoneVerify={setPhoneVerify}
                    changePhoneVerify={changePhoneVerify}
                /> */}
            </div>
        </DashboardLayout>
    )
}

export default MyAccount
