import React, { useState, useContext, useEffect, useRef } from 'react'
import './invoice.css'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import CartItem from '../../custom/components/molecules/ProductCard/CartItem'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import AuthContext from '../../custom/context/auth/authContext'
import AlertContext from '../../custom/context/alert/alertContext'
import ProductContext from '../../custom/context/product/productContext'
import DashboardLayout from '../Dashboard/DashboardLayout'

import {
    dateFormatFront,
    dateTimeFormatFunction,
    currencyFormat,
    capitalize,
} from '../../product/common/components'
import { Button } from '@material-ui/core'

const Invoice = (props) => {
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const productContext = useContext(ProductContext)

    const history = useHistory()

    const { search_allreturninvoiceproducts, getAllReturnInvoiceProducts } = productContext
    const { user, isAuthenticated } = authContext
    const { setAlert } = alertContext

    const [print, setPrint] = useState(false)
    const [search, setSearch] = useState({
        invoice_id: props.match.params.id,
    })

    let [invoiceDetails, setInvoiceDetails] = useState({
        invoiceProducts: [],
        cartValues: {},
        appointmentDetail: {},
        locationDetail: {},
        invoiceTransactions: [],
    })

    useEffect(() => {
        getAllReturnInvoiceProducts(search)
    }, [])

    useEffect(() => {
        console.log(
            'search_allreturninvoiceproducts',
            search_allreturninvoiceproducts.transactionrecords,
        )
        setInvoiceDetails({
            invoiceProducts: search_allreturninvoiceproducts.invoicerecords.length
                ? search_allreturninvoiceproducts.invoicerecords
                : [],
            cartValues: search_allreturninvoiceproducts.cartvalues,
            appointmentDetail: search_allreturninvoiceproducts.appointmentrecord,
            locationDetail: search_allreturninvoiceproducts.locationrecord,
            invoiceTransactions: search_allreturninvoiceproducts.transactionrecords,
        })
    }, [search_allreturninvoiceproducts])

    useEffect(() => {
        setInvoiceDetails({
            invoiceProducts: [],
            cartValues: {},
            appointmentDetail: {},
            locationDetail: {},
            invoiceTransactions: [],
        })
        return () => {
            setInvoiceDetails({
                invoiceProducts: [],
                cartValues: {},
                appointmentDetail: {},
                locationDetail: {},
                invoiceTransactions: [],
            })
        }
    }, [])

    const ref = React.createRef()
    const options = {}

    const generatePDF = (toPdf) => {
        setPrint(true)
        toPdf()
    }
    const completedPDF = () => {
        setPrint(false)
    }
    return (
        <DashboardLayout>
            <div className="container-lg nellisInvoice">
                <h3 className="invoiceTitle">Invoice</h3>
                <div className="d-flex justify-content-between align-items-center">
                    {/* <Breadcrumbs aria-label="breadcrumb" className="naBreadcrumbs">
                        <Link to="/dashboard/won">Dashboard</Link>
                        <Typography color="textPrimary">Invoice</Typography>
                    </Breadcrumbs> */}
                </div>
                <div
                    className={`invoiceView ${print ? 'invoiceViewPrint' : ''}`}
                    ref={ref}
                    style={{ height: '100%', width: '100%' }}
                >
                    <div className="card-header d-flex justify-content-between align-items-center p-4">
                        <a className="pt-2 d-inline-block" data-abc="true">
                            <img
                                src="/assets/svg/logo.svg"
                                className="invLogo"
                                alt="Nellis Logo"
                                style={{ width: '60px' }}
                            />
                        </a>
                        <div className="float-right invDate">
                            <h5 className="mb-0">
                                Invoice #{invoiceDetails.cartValues.return_invoice}{' '}
                            </h5>
                            <h5 className="mb-0">
                                Date: {dateFormatFront(invoiceDetails.cartValues.paiddate)}
                            </h5>
                            <h5 className="mb-0">
                                Status: {invoiceDetails.cartValues.paid ? 'REFUNDED' : 'PENDING'}
                            </h5>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row mb-4">
                            <div className="col-sm-6 invAddr">
                                <h5 className="mb-3">From:</h5>
                                <h3 className="text-dark mb-1">Naam</h3>
                                <div>{invoiceDetails.locationDetail.address}</div>
                                <div>
                                    {invoiceDetails.locationDetail.city},{' '}
                                    {invoiceDetails.locationDetail.state}{' '}
                                    {invoiceDetails.locationDetail.zipCode}, United States
                                </div>
                                <div>Phone: +1 702-531-1300</div>
                            </div>
                            <div className="col-sm-6 invAddr text-right">
                                <h5 className="mb-3">To:</h5>
                                <h3 className="text-dark mb-1">
                                    {user.first_name} {user.last_name}
                                </h3>
                                <div>{user.address1}</div>
                                <div>
                                    {user.city}, {user.state} - {user.zip}
                                </div>
                            </div>
                        </div>
                        {invoiceDetails.cartValues.payment ? (
                            <>
                                <h4 className="invoiceSubTitle">
                                    Payment Type:{' '}
                                    {invoiceDetails.cartValues.payment === 'curbside' ? (
                                        <>CurbSide Payment</>
                                    ) : invoiceDetails.cartValues.payment === 'card' ? (
                                        <>Card Payment</>
                                    ) : null}
                                </h4>
                            </>
                        ) : null}
                        {invoiceDetails.appointmentDetail ? (
                            <>
                                <h4 className="invoiceSubTitle">
                                    Appointment scheduled at:{' '}
                                    {dateTimeFormatFunction(
                                        invoiceDetails.appointmentDetail.appointmentTime,
                                    )}
                                </h4>
                            </>
                        ) : null}
                        {invoiceDetails.invoiceTransactions.length ? (
                            <>
                                <h4 className="invoiceSubTitle">Transaction Details</h4>
                                <div className="transDet d-flex justify-content-between flex-wrap">
                                    <>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    {/* <th scope="col">S.No</th> */}
                                                    <th scope="col">Transaction ID</th>
                                                    <th scope="col">Paid On</th>
                                                    <th scope="col">Method</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoiceDetails.invoiceTransactions.map(
                                                    (data, index) => (
                                                        <tr>
                                                            {/* <th scope="row">{index + 1}</th> */}
                                                            <td data-title="Transaction ID">
                                                                {data.trans_id
                                                                    ? `# ${data.trans_id}`
                                                                    : '-'}
                                                            </td>
                                                            <td data-title="Paid On">
                                                                {dateFormatFront(data.created_at)}
                                                            </td>
                                                            <td data-title="Card">
                                                                {data.payment_method === 'cash' ? (
                                                                    <>Cash</>
                                                                ) : (
                                                                    <>
                                                                        {data.mk_card_number} (
                                                                        {data.card_type})
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td data-title="Amount">
                                                                {currencyFormat(data.amount)}
                                                            </td>
                                                            <td
                                                                data-title="Payment Status"
                                                                style={{
                                                                    color:
                                                                        data.status === 'success'
                                                                            ? 'green'
                                                                            : 'red',
                                                                }}
                                                            >
                                                                {capitalize(data.status)}
                                                            </td>
                                                        </tr>
                                                    ),
                                                )}
                                            </tbody>
                                        </table>
                                    </>
                                </div>
                            </>
                        ) : null}
                        <h4 className="invoiceSubTitle mt-5">Product Details</h4>
                        <div className="table-responsive">
                            {invoiceDetails.invoiceProducts.length ? (
                                <div className="cartProducts">
                                    <div>
                                        {invoiceDetails.invoiceProducts.map((data, index) => (
                                            <CartItem data={data} from="invoice" />
                                        ))}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="row mt-5">
                            <div className="col-12">
                                <div className="table-responsive">
                                    <table className="table table-clear nellisInvTot">
                                        <tbody>
                                            <tr>
                                                <td className="left" align="right">
                                                    <span className="text-dark">Total</span>
                                                </td>
                                                <td className="right">
                                                    <span className="text-dark">
                                                        {currencyFormat(
                                                            invoiceDetails.cartValues.per_total,
                                                        )}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="left" align="right">
                                                    <span className="text-dark">Tax</span>
                                                </td>
                                                <td className="right">
                                                    <span className="text-dark">
                                                        {currencyFormat(
                                                            invoiceDetails.cartValues.total_tax,
                                                        )}{' '}
                                                        ({invoiceDetails.cartValues.tax_percent}%)
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="left" align="right">
                                                    <span className="text-dark">Buyer Premium</span>
                                                </td>
                                                <td className="right">
                                                    <span className="text-dark">
                                                        {currencyFormat(
                                                            invoiceDetails.cartValues.total_premium,
                                                        )}{' '}
                                                        ({invoiceDetails.cartValues.buyer_premium}%)
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr style={{ background: '#f3f3f3' }}>
                                                <td className="left" align="right">
                                                    <strong className="text-dark">
                                                        Grand total
                                                    </strong>
                                                </td>
                                                <td className="right">
                                                    <strong className="text-dark">
                                                        {currencyFormat(
                                                            invoiceDetails.cartValues.total_amount,
                                                        )}
                                                    </strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h4 className="thankText">Thank you for shopping with us !</h4>
            </div>
        </DashboardLayout>
    )
}

export default Invoice
