import React, { useContext, useEffect } from 'react'
import MainHeader from './MainHeader'
import TopHeader from './TopHeader'
import { useHistory } from 'react-router-dom'

import { handleRedirectInternal } from '../../../common/components'
import ProductContext from '../../../../product/context/product/productContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import HyperPayCardContext from '@/product/context/payment/hyperpay/card/cardContext'
import CommonContext from '../../../context/common/commonContext'
import UserContext from '../../../../product/context/user/userContext'
import AutopltContext from '../../../../product/context/autopilot/autopltContext'
import BidHistory from '../../organisms/BidHistory'
import { Button } from '@material-ui/core'
import AuthContext from '../../../../product/context/auth/authContext'

const Header = () => {
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const userContext = useContext(UserContext)
    const autopltContext = useContext(AutopltContext)
    const authContext = useContext(AuthContext)
    const { user } = authContext

    const history = useHistory()

    const { setAlert } = alertContext

    const { responseStatus: responseStatusProduct, clearResponse: clearResponseProduct } =
        productContext
    const { responseStatus: responseStatusUser, clearResponse: clearResponseUser } = userContext
    const { responseStatus: responseStatusAutoPlt, clearResponse: clearResponseAutoPlt } =
        autopltContext
    const { getAllHyperPayCards } = useContext(HyperPayCardContext)

    const { searchValue } = commonContext

    useEffect(() => {
        if (user && user.id) {
            getAllHyperPayCards()
        }
    }, [user?.id])

    useEffect(() => {
        if (searchValue) {
            handleRedirectInternal(history, `search`)
        }
    }, [searchValue])

    useEffect(() => {
        if (responseStatusUser) {
            setAlert(responseStatusUser.message, responseStatusUser.status)
            clearResponseUser()
        }
        if (responseStatusProduct) {
            setAlert(responseStatusProduct.message, responseStatusProduct.status)
            clearResponseProduct()
        }
        if (responseStatusAutoPlt) {
            setAlert(responseStatusAutoPlt.message, responseStatusAutoPlt.status)
            clearResponseAutoPlt()
        }
    }, [responseStatusUser, responseStatusProduct, responseStatusAutoPlt])

    return (
        <>
            <header className="noPrint">
                <TopHeader />
                <MainHeader />
                {/* <BottomHeader /> */}
                <Button
                    className="scrollTop"
                    onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                >
                    <span className="material-icons">keyboard_arrow_up</span>
                </Button>
            </header>
            <BidHistory />
        </>
    )
}

export default Header
