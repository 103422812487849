import React, { useState, useRef, useEffect, useContext } from 'react'
import PrimaryButton from '../../../components/atoms/PrimaryButton'
import SecondaryButton from '../../../components/atoms/SecondaryButton'
import AppointmentContext from '../../../context/appointment/appointmentContext'
import AlertContext from '@/product/context/alert/alertContext'
import { useHistory } from 'react-router-dom'
import {
    handleRedirectInternal,
    dateTimeFormatFunction,
    currencyFormat,
} from '../../../common/components'
import Popup from '../../../components/organisms/Popup'
import './AppointmentCard.css'

const AppointmentCard = (props) => {
    const [product, setProduct] = useState()
    const history = useHistory()
    const { cancelAppointment, responseStatus, clearResponse } = useContext(AppointmentContext)
    const { setAlert } = useContext(AlertContext)
    const [modalOpen, setModalOpen] = useState(false)
    const type = props.type
    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    const cancelAppointmentHandler = () => {
        cancelAppointment({ id: product.id })
        setModalOpen(false)
    }

    return (
        <>
            <div className="invoiceCard">
                {product ? (
                    <>
                        <h5>{dateTimeFormatFunction(product.appointmentTime)}</h5>
                        {/* <h5>{product.authorizedPickup !== '' ? product.authorizedPickup : null}</h5> */}
                        <div className="icPaid d-flex justify-content-between align-items-center">
                            <span>{product.appointmentStatusName}</span>
                        </div>

                        <div className="icItems d-flex justify-content-between align-items-center">
                            <span>Total Items:</span>{' '}
                            <span>
                                {parseInt(product.itemCount) + parseInt(product.returnItemCount)}
                            </span>
                        </div>
                        {product.appointmentStatusTypeId == '1' &&
                        product.isPassed == '0' &&
                        parseInt(product.returnItemCount) < 1 ? (
                            <div className="mt-2">
                                <SecondaryButton
                                    label="Cancel Appointment"
                                    btnSize="small"
                                    onClick={() => setModalOpen(true)}
                                />
                            </div>
                        ) : (
                            <div className="mt-2" style={{ height: '40px' }}></div>
                        )}

                        <PrimaryButton
                            label="View Details"
                            onClick={() =>
                                handleRedirectInternal(history, `appointments/${product.id}`)
                            }
                            btnSize="small"
                        />
                    </>
                ) : null}
                <Popup
                    modaltitle="Cancel Confirmation"
                    open={modalOpen}
                    handleClose={() => setModalOpen(!modalOpen)}
                >
                    <div>
                        <div className="mb-3">
                            <h4>
                                <b>Cancel this appointment?</b>
                            </h4>
                            <p>
                                There is no need to cancel your appointment if you plan on coming at
                                a later time in the same day of the existing appointment.
                                Cancelation is only necessary if you need to reschedule for a
                                different day.
                            </p>
                        </div>
                        <div className="w-25 m-auto">
                            <PrimaryButton
                                className="input-group-prepend"
                                label="Yes, cancel"
                                onClick={() => cancelAppointmentHandler(product)}
                            />
                        </div>
                    </div>
                </Popup>
            </div>
        </>
    )
}

export default AppointmentCard
