import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PayContext from '../../product/context/payment/payContext'
import AuthContext from '../../product/context/auth/authContext'
import Lottie from 'react-lottie'
import animationData from '../../assets/lottie/paymentAnimation.json'
import successAnimation from '../../assets/lottie/successAnimation.json'
import failureAnimation from '../../assets/lottie/failureAnimation.json'
import queryString from 'query-string'
import { handleRedirectInternal } from '../../product/common/components'
import CustomDialog from '../../custom/components/organisms/Dialog'
import { Button } from '@material-ui/core'
import { currencyFormat } from '../../custom/common/components'
import PayGccContext from '../../product/context/payment/paygcc/payGccContext'
import BuyerContext from '../../product/context/buyer/buyerContext'
import HyperPayContext from '../../product/context/payment/hyperpay/payment/hyperPayContext'
import AlertContext from '@/product/context/alert/alertContext'
import { useTranslation } from 'react-i18next'
const SuccessPay = (props) => {
    const hyperPayContext = useContext(HyperPayContext)
    const buyerContext = useContext(BuyerContext)
    const authContext = useContext(AuthContext)
    let { bidConfirm } = buyerContext
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const payGccContext = useContext(PayGccContext)
    const [paymentResponse, setPaymentResponse] = useState(null)
    const {
        responseStatus: responseStatusHyperPay,
        payResponse,
        createCardTokenResponse,
    } = hyperPayContext
    const [rosoomResponse, setRosoomResponse] = useState(null)
    const [toggleDialogDeposit, setToggleDialogDeposit] = useState(false)

    const changeDialogStatusDeposit = () => {
        setToggleDialogDeposit(!toggleDialogDeposit)
    }
    const { t } = useTranslation()
    const { isAuthenticated, loadUser } = authContext
    const history = useHistory()
    // const {
    //     responseCartPayTm,
    //     responseStatus: responseStatusPayment,
    //     clearResponse: clearResponsePayment,
    // } = payContext

    useEffect(() => {
        if (props.location.search) {
            const response = queryString.parse(props.location.search)
            setPaymentResponse(null)
            console.log(response, 'what response')

            payResponse({
                checkout_id: response.id,
            })
            setToggleDialogDeposit(true)
            // responseCartPayTm({ response })
            history.replace({
                search: '',
            })
        }
    }, [props.location.search])

    // useEffect(() => {
    //     if (responseStatusPayment) {
    //         if (responseStatusPayment.from === 'paytmResponse') {
    //             setPaymentResponse(responseStatusPayment)
    //             clearResponsePayment()
    //             setToggleDialogDeposit(true)
    //             loadUser()
    //         }
    //     }
    // }, [responseStatusPayment])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        speed: '0.25',
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    const paymentSuccessOptions = {
        loop: true,
        autoplay: true,
        speed: '0.25',
        animationData: successAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    const paymentFailureOptions = {
        loop: true,
        autoplay: true,
        speed: '0.25',
        animationData: failureAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    // useEffect(() => {
    //     if (responseStatusHyperPay) {
    //         console.log('responseStatusHyperPay', responseStatusHyperPay)
    //         if (responseStatusHyperPay.from === 'hyperpay_response') {
    //             if (responseStatusHyperPay.status === 'success') {
    //                 if (responseStatusHyperPay.data.pay_type === 'deposit') {
    //                     handleRedirectInternal(history, 'mypoints')
    //                 } else {
    //                     handleRedirectInternal(history, 'invoices/all')
    //                 }
    //             } else {
    //                 setAlert(responseStatusHyperPay.message, responseStatusHyperPay.status)
    //                 history.goBack()
    //             }
    //         }
    //     }
    // }, [responseStatusHyperPay])

    const redirectFunction = () => {
        if (responseStatusHyperPay.from === 'hyperpay_response') {
            if (responseStatusHyperPay.status === 'success') {
                if (responseStatusHyperPay?.data?.pay_type === 'deposit') {
                    handleRedirectInternal(history, 'mypoints')
                } else {
                    handleRedirectInternal(history, 'invoices/all')
                }
            } else {
                handleRedirectInternal(history, '')
            }
        }
    }
    return (
        <div className="container">
            <div className="paymentResponse">
                <Lottie options={defaultOptions} speed="0.25" height={300} width={400} />
                <h2>{t('Hang in there your payment is being processed')}</h2>
                <h4>{t('Please do not press the back button or refresh the page')}</h4>
            </div>
            {responseStatusHyperPay && responseStatusHyperPay.status ? (
                <CustomDialog
                    title=""
                    disableBackdropClick
                    open={toggleDialogDeposit}
                    className="paymentNotifCnt"
                    function={changeDialogStatusDeposit}
                >
                    {responseStatusHyperPay.status === 'success' ? (
                        <div className="paymentNotif">
                            <Lottie
                                options={paymentSuccessOptions}
                                speed="0.25"
                                height="auto"
                                width={150}
                            />
                            <div>
                                <h3>{t('Payment Done')}</h3>

                                {/* <h5>
                                    You have successfully made a purchase of{' '}
                                    {currencyFormat(rosoomResponse.serviceCost)}.
                                </h5> */}
                                <Button onClick={() => redirectFunction()}>{t('ok')}</Button>
                            </div>
                        </div>
                    ) : (
                        <div className="paymentNotif failure">
                            <Lottie
                                options={paymentFailureOptions}
                                speed="0.25"
                                height="auto"
                                width={150}
                            />
                            <div>
                                <h3>{t('Payment Failed')}</h3>
                                <h5>
                                    {responseStatusHyperPay.message
                                        ? t(responseStatusHyperPay.message)
                                        : ''}
                                </h5>
                                <Button onClick={() => redirectFunction()}>{t('ok')}</Button>
                            </div>
                        </div>
                    )}
                </CustomDialog>
            ) : null}
        </div>
    )
}

export default SuccessPay
