import React, { useContext, useState, useEffect } from 'react'
import CustomInput from '../../product/components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import '../Login/Login.css'
import { mapData, handleRedirectInternal } from '../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import AlertContext from '../../product/context/alert/alertContext'
import UserContext from '../../product/context/user/userContext'
import { useTranslation } from 'react-i18next'
const ForgotPassword = (props) => {
    const userContext = useContext(UserContext)
    const alertContext = useContext(AlertContext)
    const { t } = useTranslation()
    const { resetPassword, responseStatus, clearResponse } = userContext

    const history = useHistory()
    const validationArray = Yup.object({
        user_token: Yup.string().required(t('Required')),
        password: Yup.string()
            .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]/,
                t('Must contain One Uppercase, One Lowercase, One Number'),
            )
            .min(8, t('minimum_8_characters'))
            .required(t('Required')),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password')], t('passwords_not_match'))
            .required(t('Required')),
    })

    const formik = useFormik({
        initialValues: {
            user_token: props.match.params.token,
            password: '',
            password_checker: 0,
            confirm_password: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            resetPassword(values)
        },
    })

    const resetPasswordData = [
        {
            label: t('New Password'),
            name: 'password',
            type: 'password',
            placeholder: t('Enter your new password'),
            class: 'col-md-12',
            formik: formik,
        },
        // {
        //     name: 'password_checker',
        //     type: 'password_checker',
        //     formik: formik,
        //     class: 'col-md-12',
        //     hideMessage: true,
        // },
        {
            label: t('Confirm Password'),
            name: 'confirm_password',
            type: 'password',
            placeholder: t('Re-enter your password'),
            class: 'col-md-12',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'resetPassword') {
                if (responseStatus.status === 'success') {
                    handleRedirectInternal(history, '')
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (formik.errors['password_checker']) {
            if (formik.values.password && formik.values.password.length < 8) {
                formik.setFieldError('password', formik.errors['password'])
            } else {
                formik.setFieldError('password', formik.errors['password_checker'])
            }
        }
    }, [formik.errors['password'], formik.errors['password_checker']])

    return (
        <div className="login">
            <div className="container">
                <div className="d-flex justify-content-center align-items-start">
                    <div className="loginLt">
                        <h1>{t('reset_password')}</h1>
                        <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                            <div className="row">{Object.values(mapData(resetPasswordData))}</div>
                            <PrimaryButton type="submit" label={t('Change')} />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ForgotPassword
