import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../../../../context/auth/authContext'
import * as Yup from 'yup'
import AddressComponent from '../../../../species/components/user/profile/AddressComponent'
import './addressComponent.css'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'

const AddressViewComponent = () => {
    const authContext = useContext(AuthContext)
    const { user, isAuthenticated } = authContext
    // const [selectedCountry, SetCountry] = useState([])

    // useEffect(() => {
    //     if (user.country) setCountry(user.country)
    // })

    const [manage, setManage] = useState({
        popup: false,
        status: 'new',
        data: null,
    })
    const [address, setAddress] = useState([])

    const toggleFullScreenPopup = (popup, status, data) => {
        setManage({ popup, status, data })
    }

    const setUserAddress = () => {
        setAddress(user.address_details.filter((inner) => inner.status === 'active'))
        toggleFullScreenPopup(false, 'new', null)
    }

    useEffect(() => {
        if (isAuthenticated) {
            setUserAddress()
        }
    }, [isAuthenticated, user && user.address_details])

    return (
        <>
            {user && !user.admin ? (
                <div className="savedAddressContainer">
                    <h2 className="dashTitle">Saved Addresses</h2>

                    <div className="savedAddress">
                        <Button
                            id="add_addresss"
                            className="savedAddressCard addNew"
                            onClick={() => toggleFullScreenPopup(true, 'update', null)}
                        >
                            <span className="material-icons mr-1">add</span> Add new address
                        </Button>
                        {address &&
                            address
                                .sort((a, b) => (a.is_primary < b.is_primary ? 1 : -1))
                                .map((data, index) => (
                                    <div
                                        className={`savedAddressCard ${
                                            data.is_primary == 1 ? 'default' : ''
                                        }`}
                                        key={index}
                                    >
                                        <address>
                                            {data.first_name + ' ' + data.last_name} <br />
                                            {data.address1}, {data.address2} <br />
                                            {data.city}, {data.state}, {data.zip}
                                        </address>

                                        <div className="d-flex justify-content-end align-items-center ssActBtn">
                                            {data.is_primary == 1 && (
                                                <div className="dfltCard">
                                                    DEFAULT{' '}
                                                    <span className="material-icons ml-1">
                                                        check_circle
                                                    </span>
                                                </div>
                                            )}
                                            {data.is_primary == 0 && (
                                                <>
                                                    <Button
                                                        id="make_default"
                                                        onClick={() =>
                                                            toggleFullScreenPopup(
                                                                true,
                                                                'default',
                                                                data,
                                                            )
                                                        }
                                                    >
                                                        Make Default
                                                    </Button>
                                                </>
                                            )}
                                            <Button
                                                id="edit_addresss"
                                                onClick={() =>
                                                    toggleFullScreenPopup(true, 'update', data)
                                                }
                                            >
                                                Edit
                                            </Button>

                                            {data.is_primary == 0 && (
                                                <>
                                                    <Button
                                                        id="delete_addresss"
                                                        onClick={() =>
                                                            toggleFullScreenPopup(
                                                                true,
                                                                'delete',
                                                                data,
                                                            )
                                                        }
                                                    >
                                                        Delete
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                ))}
                    </div>
                    {manage.popup && (
                        <AddressComponent
                            data={manage}
                            function={toggleFullScreenPopup}
                            title="Shipping address"
                        />
                    )}
                </div>
            ) : null}
        </>
    )
}
export default AddressViewComponent
